import React, { Component } from "react";

import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

export default class PrintingLabelsPage extends Component {
  render() {
    const { objects, groupName } = this.props;

    const styles = StyleSheet.create({
      page: {
        flexDirection: "row",
        backgroundColor: "white",
        margin: "0px",
        padding: "0.5in 0px",
      },
      rows: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
      },
      title: {
        position: "absolute",
        top: "10px",
        left: "10px",
        fontSize: "10px",
      },
      block: {
        height: "0.5in",
        width: "1.75in",
        border: "1px solid white",
        marginLeft: "0.296in",
        padding: "5px",
      },
      alias: {
        fontSize: "8px",
        fontWeight: "bold",
      },
      data: {
        fontSize: "6px",
      },
    });

    return (
      <Document>
        <Page style={styles.page} size="LETTER">
          <View style={styles.title}>
            <Text>{`${groupName} Printing Label`}</Text>
          </View>
          <View style={styles.rows}>
            {objects.map((obj, i) => {
              return (
                <div style={styles.block} key={i}>
                  <Text style={styles.alias}>{obj.Alias}</Text>
                  <Text style={styles.data}>
                    {obj.Fragile ? "Fragile" : "Not Fragile"}
                  </Text>
                  <Text style={styles.data}>
                    {obj.Inside ? "Inside" : "Normal Placement"}
                  </Text>
                </div>
              );
            })}
          </View>
        </Page>
      </Document>
    );
  }
}
