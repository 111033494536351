import React, { Component } from "react";
import { FaSmile } from "react-icons/fa";
import "./Footer.css";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <p>Copyright &copy; 2021. All Rights Reserved. Creative Nation.</p>
        <p className="footer-made">
          Made in San Diego, CA {`  `}
          <FaSmile />
        </p>
      </div>
    );
  }
}
