import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { users_Collection, firebaseLooper } from "../firebase";
import { useHistory } from "react-router-dom";
import {
  storeDoMatchAction,
  storeRegMessageAction,
  storeUserCredsAction,
  toggleUserLoggedInAction,
} from "../redux";

import Footer from "./Footer";
import RandomString from "./RandomString";

export default function Register() {
  const dispatch = useDispatch();
  const history = useHistory();

  const doMatch = useSelector((state) => state.storeDoMatchReducer);
  const message = useSelector((state) => state.storeRegMessageReducer);

  let complimentaryItems = [
    {
      Name: "Books Box 1",
      Width: "1",
      Length: "1",
      Height: "1",
      Material: "Cardboard",
      Weight: "10",
      Fragile: false,
      Contents: "Books, Notebooks, Journals",
    },
    {
      Name: "Books Box 2",
      Width: "1",
      Length: "1",
      Height: "1",
      Material: "Cardboard",
      Weight: "10",
      Fragile: false,
      Contents: "Books, Notebooks, Journals",
    },
    {
      Name: "Books Box 3",
      Width: "1",
      Length: "1",
      Height: "1",
      Material: "Cardboard",
      Weight: "10",
      Fragile: false,
      Contents: "Books, Notebooks, Journals",
    },
    {
      Name: "Electronics",
      Width: "1.5",
      Length: "1.5",
      Height: "3.8",
      Material: "Cardboard",
      Weight: "15",
      Fragile: false,
      Contents: "Keyboard, Monitor, Stereo System, Records",
      
    },
    {
      Name: "Plates",
      Width: "2.2",
      Length: "1",
      Height: "1",
      Material: "Cardboard",
      Weight: "15",
      Fragile: false,
      Contents: "Keyboard, Monitor, Stereo System, Records",
      
    },
    {
      Name: "Camping Gear",
      Width: "2",
      Length: "2",
      Height: "4",
      Material: "Cardboard",
      Weight: "25",
      Fragile: false,
      Contents: "Tents, Sleeping Bags, Blankets, Tarp",
     
    },
    {
      Name: "Camping Cookery",
      Width: "2",
      Length: "2",
      Height: "2",
      Material: "Cardboard",
      Weight: "20",
      Fragile: false,
      Contents: "Stove, Propane, Pans, Plastic Cutlery",
      
    },
    {
      Name: "Twin Mattress",
      Width: "5.5",
      Length: "1",
      Height: "3.2",
      Material: "Cardboard",
      Weight: "20",
      Fragile: false,
      Contents: "",
    },
    {
      Name: "Queen Mattress",
      Width: "6.7",
      Length: "1",
      Height: "5",
      Material: "Cardboard",
      Weight: "26",
      Fragile: false,
      Contents: "",
    },
    {
      Name: "Lamp",
      Width: "1.4",
      Length: "1.4",
      Height: "4",
      Material: "Cardboard",
      Weight: "8",
      Fragile: false,
      Contents: "",
    },
  ];

  const createAccount = () => {
    if (doMatch && document.querySelector("#tbPassConfirm").value !== "") {
      const firstName = document.querySelector("#tbFirstName").value;
      const lastName = document.querySelector("#tbLastName").value;
      const email = document.querySelector("#tbEmail").value;
      const username = document.querySelector("#tbUsername").value;
      const password = document.querySelector("#tbPassConfirm").value;

      let empty = false;
      if (
        firstName === "" ||
        lastName === "" ||
        email === "" ||
        username === "" ||
        password === ""
      ) {
        empty = true;
      }

      if (!empty) {
        //   Check if username
        users_Collection
          .where("Username", "==", username)
          .get()
          .then((snapshot) => {
            const userData = firebaseLooper(snapshot);
            if (userData.length === 0) {
              // New User
              let rand1 = RandomString();
              let rand2 = RandomString();
              let userID = `User${rand1}${rand2}`;
              let shareKey = userID.substr(4, 8);
              let user = {
                id: userID,
                ShareKey: shareKey,
                FirstName: firstName,
                LastName: lastName,
                Email: email,
                Username: username,
                Password: password,
              };
              users_Collection
                .doc(userID)
                .set({
                  ShareKey: shareKey,
                  FirstName: firstName,
                  LastName: lastName,
                  Email: email,
                  Username: username,
                  Password: password,
                })
                .catch((err) => console.log(err));

              let rand3 = RandomString();
              let rand4 = RandomString();
              let loadID = `Load${rand3}${rand4}`;

              users_Collection
                .doc(userID)
                .collection("Loads")
                .doc(loadID)
                .set({
                  Name: "1225 Tenth St. House",
                })
                .catch((err) => console.log(err));

              complimentaryItems.forEach((item) => {
                let rand5 = RandomString();
                let rand6 = RandomString();
                let itemID = `Item${rand5}${rand6}`;

                users_Collection
                  .doc(userID)
                  .collection("Loads")
                  .doc(loadID)
                  .collection("Items")
                  .doc(itemID)
                  .set({
                    Name: item.Name,
                    Width: item.Width,
                    Length: item.Length,
                    Height: item.Height,
                    Material: item.Material,
                    Weight: item.Weight,
                    Fragile: item.Fragile,
                    Contents: item.Contents,
                  })
                  .catch((err) => console.log(err));
              });

              dispatch(storeUserCredsAction(user));
              dispatch(toggleUserLoggedInAction(true));
              history.push("/dashboard");
            } else {
              // Existing User
              dispatch(storeRegMessageAction("Username already exists"));
            }
          })
          .catch((err) => console.log(err));
      } else {
        dispatch(storeRegMessageAction("Fields left empty"));
      }
    }
  };
  const checkPassConfirm = () => {
    const pass = document.querySelector("#tbPass").value;
    const passC = document.querySelector("#tbPassConfirm").value;

    if (pass !== passC) {
      dispatch(storeDoMatchAction(false));
    } else {
      dispatch(storeDoMatchAction(true));
    }
    if (pass === "" && passC === "") {
      dispatch(storeDoMatchAction(true));
    }
  };

  return (
    <div className="login-main">
      <div className="login-main-head-block">
        <h1>
          Register<span className="text-red">.</span>
        </h1>
        <p className="message-reg">{message}</p>
      </div>
      <br />
      <div className="login-main-form reg-main-form">
        <div className="reg-split">
          <div>
            <label className="reg-label login-form-label">First Name</label>
            <input
              className="reg-label login-form-tb"
              id="tbFirstName"
              type="text"
              placeholder="John"
              defaultValue=""
            />
          </div>
          <div>
            <label className="reg-label login-form-label">Last Name</label>
            <input
              className="login-form-tb"
              id="tbLastName"
              type="text"
              placeholder="Doe"
              defaultValue=""
            />
          </div>
        </div>
        <div className="reg-split">
          <div>
            <label className="reg-label login-form-label">Email</label>
            <input
              className="login-form-tb"
              id="tbEmail"
              type="text"
              placeholder="jdoe@gmail.com"
              defaultValue=""
            />
          </div>
          <div>
            <label className="reg-label login-form-label">Username</label>
            <input
              className="login-form-tb"
              id="tbUsername"
              type="text"
              placeholder="jdoe2021"
              defaultValue=""
            />
          </div>
        </div>
        <div className="reg-split">
          <div>
            <label className="reg-label login-form-label">Password</label>
            <input
              className="login-form-tb"
              id="tbPass"
              type="password"
              placeholder="******"
              defaultValue=""
            />
          </div>
          <div>
            <label className="reg-label login-form-label">
              Confirm Password
            </label>
            <input
              className="login-form-tb"
              id="tbPassConfirm"
              type="password"
              placeholder="******"
              defaultValue=""
              onChange={checkPassConfirm}
            />
            {doMatch ? null : (
              <p className="no-match">Passwords do not match.</p>
            )}
          </div>
        </div>
        <br />
        <button className="login-form-btn" onClick={createAccount}>
          Get Started!
        </button>
        <br />
        <button
          onClick={() => history.push("/login")}
          className="btn-create-acc"
        >
          Already have an account?
        </button>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
