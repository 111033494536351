import React, { useEffect } from "react";
import "./Main.css";
import Footer from "./Footer";
import buttonsImg from "../Images/buttonsImg.png";
import autoOrderingImg from "../Images/autoOrdering.png";
import loadingImg from "../Images/loading.png";
import protipsImg from "../Images/protips.png";
import simExample from "../Images/simulation-example.png";
import objGroup from "../Images/object-group.png";

export default function Home() {
  useEffect(() => {
    document
      .querySelector(".home-panel-1")
      .setAttribute("style", `height: ${window.innerHeight - 100}px`);
  }, []);
  return (
    <div className="content">
      {/* HOME PANEL 1 */}
      <div className="home-panel-1">
        <div className="panel1">
          <h1 className="squish">
            A new way to move and store<span className="text-red">.</span>
          </h1>
          <p>
            Organize and simulate your items with a click of a{" "}
            <span className="text-btn">button</span>. Save time and money every
            time you move, ship, and/or store.
          </p>
        </div>

        <div className="panel1-img"></div>
      </div>

      {/* HOME PANEL 2 */}
      <div className="home-panel-2">
        <h1 className="squish">
          Easy as 1, 2, 3<span className="text-red">.</span>
        </h1>
        <br />
        <div className="panel2-block">
          <div className="panel2-grid">
            <div className="panel2-tile">
              <h4>
                Create and load your items<span className="text-red">.</span>
              </h4>
              <p>
                Enter the data of your belongings and watch as the program
                prepares them for simulation.
              </p>
            </div>
            <div className="panel2-tile">
              <h4>
                Pick a Moving Truck or Storage Space
                <span className="text-red">.</span>
              </h4>
              <p>
                Choose from one of our preset truck and storage options, or
                create your own by using our custom truck / storage form.
              </p>
            </div>
            <div className="panel2-tile">
              <h4>
                Enter the simulation and begin packing
                <span className="text-red">!</span>
              </h4>
              <p>
                We've provided the space and controls to pack your space with
                easy to use controls and actions. Stop spending time physically
                packing and reorganizing. Do it in our simulation and watch as
                you save yourself time and money!
              </p>
            </div>
          </div>
          <div>
            <img
              alt="moving truck simulation example"
              src={simExample}
              className="panel2-img"
            />
          </div>
        </div>
      </div>
      <br />
      {/* HOME PANEL 3 */}
      <div className="home-panel-3">
        <h1 className="squish">
          Features<span className="text-red">.</span>
        </h1>
        <br />
        <div className="panel3-grid">
          <div>
            <div>
              <img
                alt="load moving truck with belongings"
                src={objGroup}
                className="panel3-img"
              />
            </div>
            <div className="panel3-tile">
              <h4>Load your belongings.</h4>
              <p>
                Create any load and fill it with as many odd shaped boxes as
                you'd like. View what each item will look like in the small
                simulator, before you even enter the simulation!
              </p>
            </div>
          </div>
          <hr className="hr" />
          <br />
          <div>
            <img
              alt="load moving truck with belongings"
              src={buttonsImg}
              className="panel3-img"
            />
            <div className="panel3-tile">
              <h4>Buttons, Keys, and Mouse Clicks</h4>
              <p>
                For a faster process, controls of all types were provided to
                place, take out, move, stand, and rotate items to fit your
                computer habits. You can use the mouse to choose an item and use
                the buttons and keyboard shortcuts to interact with them.
              </p>
            </div>
          </div>
          <hr className="hr" />
          <br />
          <div>
            <img
              alt="load moving truck with belongings"
              src={autoOrderingImg}
              className="panel3-img"
            />
            <div className="panel3-tile">
              <h4>Auto-ordering</h4>
              <p>
                As you place boxes inside, the order is automatically adjusted
                so when you are ready to load your truck in real life, you just
                need to follow the order using the toggle buttons.
              </p>
            </div>
          </div>
          <hr className="hr" />
          <br />
          <div>
            <img
              alt="load moving truck with belongings"
              src={loadingImg}
              className="panel3-img"
            />
            <div className="panel3-tile">
              <h4>
                Placing and Taking out boxes has never taken so little time.
              </h4>
              <p>
                Loading a truck is a game of Tetris on Hard Mode. Save money and
                time by using our simulation to easily place or take out items
                without the hard work.
              </p>
            </div>
          </div>
          <hr className="hr" />
          <br />
          <div>
            <img
              alt="load moving truck with belongings"
              src={protipsImg}
              className="panel3-img"
            />
            <div className="panel3-tile">
              <h4>Pro tips provided.</h4>
              <p>
                To provide a safer experience for both you and your items, a pro
                tips window was added to help you better load your truck or
                storage. Search using tags or let the program choose the best
                tips according to the names of the items.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      {/* PANEL 4 */}
      <div className="home-panel-4">
        <h2>This is the future of moving and storing.</h2>
        <p>Stay tuned for updates to our program or simulations.</p>
      </div>

      <div className="foot">
        <Footer />
      </div>
    </div>
  );
}
