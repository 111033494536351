import React from "react";

import Footer from "./Footer";

export default function Contact() {
  return (
    <div>
      <h1>Contact</h1>

      {/* Footer */}
      <div>
        <Footer />
      </div>
    </div>
  );
}
