// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC7MhdJDywz1Gnuntc30R2yCGYG2j58fls",
  authDomain: "storage-wizard-firebase.firebaseapp.com",
  projectId: "storage-wizard-firebase",
  storageBucket: "storage-wizard-firebase.appspot.com",
  messagingSenderId: "487961262093",
  appId: "1:487961262093:web:f8cf2229615e44e714fd44",
  measurementId: "G-CKNEXBBPM1",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

// Timestamp
export const firebaseTimestamp = firebase.firestore.FieldValue.serverTimestamp();

// Collections
export const users_Collection = db.collection("Users");

////////////////////////////////
export const firebaseLooper = (snapshot) => {
  let data = [];
  snapshot.forEach((doc) => {
    data.push({
      ...doc.data(),
      id: doc.id,
    });
  });
  return data;
};
////////////////////////////////
export default firebase;
