import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import publicImg from "../Images/partnershippublic.png";
import Footer from "./Footer";

export default function Uhaul() {
  return (
    <div className="partn-wrapper">
      <h1 className="partn-h1">
        Take the nest step forward<span className="text-red">!</span>
      </h1>
      <br />
      <img className="partn-img" src={publicImg} alt="" />
      <br />
      <h2 className="partn-h2">
        The future is here<span className="text-red">.</span>
      </h2>

      <div id="pdf-viewer"></div>
      <p className="partn-p">
        Day after day, we worked hard to develop an algorithm that would do what
        has never been done before; making Storage Wizard special and one of a
        kind. Input any number of boxes with any dimensions and it will find the
        most <b className="text-red">optimized</b>,{" "}
        <b className="text-red">efficient</b>, and{" "}
        <b className="text-red">safe</b> formation.
        <br />
        <br />
        As each of your customers begin their process to rent a moving truck,
        they can store their object data in this website and find which moving
        truck will work best for them. Next, they can have their belongings
        organized even before pick up! What a time and money saver! Once that is
        settled, <b className="text-red">they will feel more inclined to rent the truck without
        hesitation</b>. Win-win!
        <br />
        <br />
        <b>August 31, 2021</b> marked the day we would be launching this
        wonderful program out into the world. However, it was amidst
        brainstorming for marketing campaigns that we wanted to reach out to
        you; as we thought you would be a great new owner of Storage Wizard. Our
        interests lie on improving the lives of as many people as possible.
        Thus, we believe <b>Uhaul</b> would make great use of the wonderful new
        world of 3D Storage Simulations. The futrure is here!
      </p>
      <br />
      <p className="partn-p">
        Please reach out to us at:
        <br />
        <h3 className="partn-h3">
          storagewizard<span className="text-red">.</span>official
          <span className="text-red">@</span>gmail.com
        </h3>
      </p>
      <p className="partn-p">
        Thank you!
        <br />
        <br />
      </p>

      <div className="feat-block">
        <h4 className="feat-sub">
          Features made for you<span className="text-red">.</span>
        </h4>

        <div className="feat-grid">
        <div className="feat-card">
            <h4 className="feat-head">Moving Truck Simulations</h4>
            <p className="feat-desc">
              We understand that many trucks include other storage space, specifically the Mom's Attic. Luckily, our super intelligent algorithm now calculates and places any belongings that fit into that space. Ensuring the optimization of your moving experience.
            </p>
          </div>
          <div className="feat-card">
            <h4 className="feat-head">Share Your Stuff</h4>
            <p className="feat-desc">
              Each customer is bound to have their own set of items. Thus, we
              set up a way for your customers to share their stuff with
              another account. Our goal was for customer items to be run
              using the "best match" algorithm, which finds the space which best
              fits their belongings.
            </p>
          </div>
          <div className="feat-card">
            <h4 className="feat-head">Find Best Match</h4>
            <p className="feat-desc">
              Each object group is set up to be run using the find best match
              algorithm. Doing this will return the most fitting space. Spaces
              that cannot fit all items will not be listed, ensuring that you
              get the best option.
            </p>
          </div>
          <div className="feat-card">
            <h4 className="feat-head">Toggle Through Objects</h4>
            <p className="feat-desc">
              Simulation controls were added to allow the user to go through
              each item. We find it important for users to see the full
              picture, but also exactly where and in what order each object was
              placed in.
            </p>
          </div>
          <div className="feat-card">
            <h4 className="feat-head">Print Automated Labels</h4>
            <p className="feat-desc">
              Most people will bring out the sharpie and write the content
              description on the side of the box, which is fine. However, we
              took it a step further and added a feature for any item group
              data to be formatted and printed on a printing label sheet with
              just a click of a button.
            </p>
          </div>
          <div className="feat-card">
            <h4 className="feat-head">Place Items Inside Others</h4>
            <p className="feat-desc">
              We weren't satisfied with just being able to place solid objects.
              So we decided to allow the algorithm to recognize if an item has
              available space inside. If so, items that fit will be placed
              inside.
            </p>
          </div>
          
        </div>
      </div>

      {/* Footer */}
      <div>
        <Footer />
      </div>
    </div>
  );
}
