import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  toggleUserLoggedInAction,
  storeAllStoragesAction,
  storeSingleStorageAction,
} from "../../redux";
import { users_Collection } from "../../firebase";
import Footer from "../Footer";

export default function StorageEdit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAuthID = useSelector((state) => state.storeUserCredsReducer);

  const storage = useSelector((state) => state.storeSingleStorageReducer);
  const storages = useSelector((state) => state.storeAllStoragesReducer);

  const editStorage = () => {
    const alias = document.querySelector("#tbAlias").value;
    const width = `${document.querySelector("#tbWidth").value} FT`;
    const length = `${document.querySelector("#tbLength").value} FT`;
    const height = `${document.querySelector("#tbHeight").value} FT`;

    users_Collection
      .doc(userAuthID.id)
      .collection("Spaces")
      .doc(storage.id)
      .update({ Alias: alias, Width: width, Length: length, Height: height })
      .then(() => {
        dispatch(
          storeSingleStorageAction({
            ...storage,
            Alias: alias,
            Width: width,
            Length: length,
            Height: height,
          })
        );
        let allStor = [...storages];
        allStor.push({
          id: storage.id,
          Alias: alias,
          Width: width,
          Length: length,
          Height: height,
        });
        dispatch(storeAllStoragesAction(allStor));

        history.push("/dashboard");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!userAuthID.id) {
      dispatch(toggleUserLoggedInAction(false));
      history.push("/login");
      return;
    }
  }, []);
  return (
    <div
      className="space-main-wrap"
      style={{ fontFamily: "Heebo, sans-serif" }}
    >
      <button
        className="dash-btn"
        onClick={() => {
          history.push("/dashboard");
        }}
      >
        Dashboard
      </button>
      <h1 className="obj-view-head">
        Edit Storage / Truck<span className="text-red">.</span>
      </h1>
      <br />
      <div className="new-stor-wrap">
        <div className="new-stor-block">
          <p className="new-stor-label">Name:</p>
          <input
            className="new-stor-tb"
            type="text"
            id="tbAlias"
            defaultValue={storage.Alias}
          />
          <p className="new-stor-label">Width:</p>
          <div style={{ display: "flex" }}>
            <input
              className="new-stor-tb"
              type="text"
              id="tbWidth"
              defaultValue={storage.Width ? storage.Width.split(" ")[0] : null}
            />{" "}
            <span className="units">FT</span>
          </div>
          <p className="new-stor-label">Length:</p>
          <div style={{ display: "flex" }}>
            <input
              className="new-stor-tb"
              type="text"
              id="tbLength"
              defaultValue={
                storage.Length ? storage.Length.split(" ")[0] : null
              }
            />{" "}
            <span className="units">FT</span>
          </div>
          <p className="new-stor-label">Height:</p>
          <div style={{ display: "flex" }}>
            <input
              className="new-stor-tb"
              type="text"
              id="tbHeight"
              defaultValue={
                storage.Height ? storage.Height.split(" ")[0] : null
              }
            />{" "}
            <span className="units">FT</span>
          </div>
        </div>
        <br />
        <button onClick={editStorage} className="run-sim">
          Finish
        </button>
      </div>
    </div>
  );
}
