import React, { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { users_Collection, firebaseLooper } from "../../firebase";
import {
  storeAllStorageGroupsAction,
  storeAllStoragesAction,
  storeAllStorageUnitsAction,
  toggleUserLoggedInAction,
} from "../../redux";
import RandomString from "../RandomString";

export default function StorageCreate() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAuthID = useSelector((state) => state.storeUserCredsReducer);

  const storages = useSelector((state) => state.storeAllStoragesReducer);

  // GET
  const getAllStorageGroups = () => {
    users_Collection
      .doc(userAuthID.id)
      .collection("Spaces")
      .get()
      .then((snapshot) => {
        const storData = firebaseLooper(snapshot);
        dispatch(storeAllStoragesAction(storData));
      })
      .catch((err) => console.log(err));
  };

  //   POST
  const newStorage = () => {
    const alias = document.querySelector("#tbAlias").value;
    const width = `${document.querySelector("#tbWidth").value} FT`;
    const length = `${document.querySelector("#tbLength").value} FT`;
    const height = `${document.querySelector("#tbHeight").value} FT`;

    if (alias !== "") {
      const rand1 = RandomString();
      const rand2 = RandomString();
      const spaceID = `Space${rand1}${rand2}`;

      users_Collection
        .doc(userAuthID.id)
        .collection("Spaces")
        .get()
        .then((snapshot) => {
          const storData = firebaseLooper(snapshot);
          let found = false;
          storData.forEach((stor) => {
            if (stor.Alias === alias) {
              found = true;
              alert("Alias name is already taken.");
            }
          });
          if (!found) {
            users_Collection
              .doc(userAuthID.id)
              .collection("Spaces")
              .doc(spaceID)
              .set({
                Alias: alias,
                Width: width,
                Length: length,
                Height: height,
              })
              .catch((err) => console.log(err));

            let allStor = [...storages];
            allStor.push({
              id: spaceID,
              Alias: alias,
              Width: width,
              Length: length,
              Height: height,
            });
            dispatch(storeAllStoragesAction(allStor));
            history.push("/dashboard");
          }
        })
        .catch((err) => console.log(err));
    } else {
      alert("Please enter an Alias for your space.");
    }
  };

  useEffect(() => {
    if (!userAuthID.id) {
      dispatch(toggleUserLoggedInAction(false));
      history.push("/login");
      return;
    }

    getAllStorageGroups();
  }, []);

  return (
    <div className="space-main-wrap" style={{ fontFamily: "Heebo, sans-serif" }}>
      <button
        className="dash-btn"
        onClick={() => {
          history.push("/dashboard");
        }}
      >
        Dashboard
      </button>
      <h1 className="obj-view-head">
        New Custom Storage / Truck<span className="text-red">.</span>
      </h1>
      <br />
      <div className="new-stor-wrap">
        <div className="new-stor-block">
          <p className="new-stor-label">Name</p>
          <input
            className="new-stor-tb"
            type="text"
            id="tbAlias"
            placeholder="Unit A-3"
          />
          <p className="new-stor-label">Width</p>
          <div style={{ display: "flex" }}>
            <input
              className="new-stor-tb"
              type="text"
              id="tbWidth"
              placeholder="10 or 9.5"
            />{" "}
            <span className="units">FT</span>
          </div>
          <p className="new-stor-label">Length</p>
          <div style={{ display: "flex" }}>
            {" "}
            <input
              className="new-stor-tb"
              type="text"
              id="tbLength"
              placeholder="8 or 7.5"
            />{" "}
            <span className="units">FT</span>
          </div>
          <p className="new-stor-label">Height</p>
          <div style={{ display: "flex" }}>
            <input
              className="new-stor-tb"
              type="text"
              id="tbHeight"
              placeholder="8 or 7.5"
            />{" "}
            <span className="units">FT</span>
          </div>
        </div>
        <br />
        <button onClick={newStorage} className="run-sim">
          Finish
        </button>
      </div>
    </div>
  );
}
