/* eslint-disable no-loop-func */
// Requiring the lodash library
import _, { noop, template, uniq } from "lodash";
import { FaCheck, FaHandPointDown } from "react-icons/fa";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { users_Collection, firebaseLooper } from "../../firebase";
import {
  toggleUserLoggedInAction,
  storeAllStoragesAction,
  storeSimSpaceDimAction,
  storeSimMomDimAction,
  storeSimLoadObjAction,
  storeSimItemsArrAction,
  storeSimCurrentNumAction,
  storeSimCurrentObjAction,
  storeSimOriginalLoadAction,
  storeSimPlacedItemsAction,
} from "../../redux";

export default function SimulationPrep() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAuthID = useSelector((state) => state.storeUserCredsReducer);

  const itemGroup = useSelector((state) => state.storeObjectGroupReducer);
  const spaces = useSelector((state) => state.storeAllStoragesReducer);

  const uhaulPresets = [
    {
      id: 1,
      Alias: "4 x 8 Cargo Trailer",
      Width: "8",
      Length: "4",
      Height: "5",
      Preset: true,
      Trailer: true,
    },
    {
      id: 2,
      Alias: "5 x 8 Cargo Trailer",
      Width: "8",
      Length: "4.5",
      Height: "5",
      Preset: true,
      Trailer: true,
    },
    {
      id: 3,
      Alias: "6 x 12 Cargo Trailer",
      Width: "11.5",
      Length: "6",
      Height: "5.4",
      Preset: true,
      Trailer: true,
    },

    {
      id: 4,
      Alias: "U-Box Moving / Storing Container",
      Width: "7.8",
      Length: "4.5",
      Height: "6.8",
      Preset: true,
      Container: true,
    },
    {
      id: 5,
      Alias: "8 FT Pickup Truck",
      Width: "7.5 FT",
      Length: "5",
      Height: "3.5",
      WheelW: "2.5",
      WheelL: "1",
      WheelH: "1",
      DistanceWheel: "2",
      Preset: true,
      Truck: true,
    },
    {
      id: 6,
      Alias: "9 FT Cargo Van",
      Width: "9.5",
      Length: "5.5",
      Height: "4.5",
      WheelW: "2.5",
      WheelL: "1",
      WheelH: "1",
      DistanceWheel: "4",
      Preset: true,
      Van: true,
    },
    {
      id: 7,
      Alias: "10 FT Moving Truck",
      Width: "9.8",
      Length: "6.2",
      Height: "6",
      WheelW: "2",
      WheelL: "1",
      WheelH: "1",
      DistanceWheel: "4",
      Preset: true,
      Truck: true,
    },
    {
      id: 8,
      Alias: "15 FT Moving Truck",
      Width: "12.2",
      Length: "7.5",
      Height: "7",
      WheelW: "2",
      WheelL: "1",
      WheelH: "1",
      DistanceWheel: "5.5",
      MomW: "2.2",
      MomL: "7.5",
      MomH: "2.5",
      Mom: true,
      Preset: true,
      Truck: true,
    },
    {
      id: 9,
      Alias: "17 FT Moving Truck",
      Width: "14.2 FT",
      Length: "7.5",
      Height: "7",
      WheelW: "2",
      WheelL: "1",
      WheelH: "1",
      DistanceWheel: "6.5",
      MomW: "2.2",
      MomL: "7.5",
      MomH: "2.5",
      Mom: true,
      Preset: true,
      Truck: true,
    },
    {
      id: 10,
      Alias: "20 FT Moving Truck",
      Width: "19.2",
      Length: "7.5",
      Height: "7",
      WheelW: "2",
      WheelL: "1",
      WheelH: "1",
      DistanceWheel: "8.2",
      MomW: "2.2",
      MomL: "7.5",
      MomH: "2.5",
      Mom: true,
      Preset: true,
      Truck: true,
    },
    {
      id: 11,
      Alias: "26 FT Moving Truck",
      Width: "23.2",
      Length: "8",
      Height: "8.2",
      WheelW: "2",
      WheelL: "1",
      WheelH: "1",
      DistanceWheel: "12",
      MomW: "2.5",
      MomL: "8",
      MomH: "3",
      Mom: true,
      Preset: true,
      Truck: true,
    },
  ];

  // GET
  const getAllStorageUnits = () => {
    users_Collection
      .doc(userAuthID.id)
      .collection("Spaces")
      .orderBy("Alias", "asc")
      .get()
      .then((snapshot) => {
        const unitsData = firebaseLooper(snapshot);
        dispatch(storeAllStoragesAction(unitsData));
      })
      .catch((err) => console.log(err));
  };

  //   HANDLE
  const handleStorages = () => {
    return spaces.map((stor, i) => {
      return (
        <option key={i} value={stor.Alias}>
          {stor.Alias} - {stor.Length.split(" ")[0]} x{" "}
          {stor.Width.split(" ")[0]} x {stor.Height.split(" ")[0]} FT
        </option>
      );
    });
  };
  const handleObjectGroupData = () => {
    return itemGroup.Items.map((item, i) => {
      return (
        <div className="item-wrap" key={i}>
          <h4 className="item-name">
            {item.Name}
            <span className="text-red">.</span>
          </h4>

          <p className="item-dim">
            {item.Length} x {item.Width} x {item.Height} FT
          </p>
          <p className="item-data">
            {item.Material} - {item.Weight} LBS
          </p>

          <p className="item-check">
            {item.Fragile ? (
              <div style={{ display: "flex" }}>
                <FaCheck />
                <p style={{ marginLeft: "10px" }}>Fragile</p>
              </div>
            ) : null}
          </p>

          <br />
          <p className="item-space">
            {item.Space ? "Contains Space Inside" : null}
          </p>
          <p className="item-space">
            {item.Space
              ? `${item.InsideLength} x ${item.InsideWidth} x ${item.InsideHeight} FT`
              : null}
          </p>
          <p className="item-data">{item.Contents}</p>
        </div>
      );
    });
  };
  const handleUhaulPresets = () => {
    return uhaulPresets.map((uhaul, i) => {
      return (
        <option key={i} value={uhaul.Alias}>
          {uhaul.Alias} - {uhaul.Length.split(" ")[0]} x{" "}
          {uhaul.Width.split(" ")[0]} x {uhaul.Height.split(" ")[0]} FT
        </option>
      );
    });
  };

  // SIMULATION VERSION 2.0 ////////////////////////////////////////////////////////

  const simulate = () => {
    let load = { ...itemGroup };
    let items = [...itemGroup.Items];

    let chosenPreset = document.getElementById("selPresets");
    let preset = chosenPreset.options[chosenPreset.selectedIndex].text;
    preset = preset.split(" - ")[0];

    let chosenCustom;
    let custom;
    if (spaces.length > 0) {
      chosenCustom = document.getElementById("selSpaces");
      custom = chosenCustom.options[chosenCustom.selectedIndex].text;
      custom = custom.split(" - ")[0];
    }

    let emptyChoice1 = "Choose a Moving Vehicle";
    let emptyChoice2 = "Choose a Custom Storage/Moving Vehicle";

    // Check if no selected values
    if (spaces.length > 0) {
      if (preset === emptyChoice1 && custom === emptyChoice2) {
        alert("Please choose a moving vehicle OR a storage space.");
        return;
      }
      if (preset !== emptyChoice1 && custom !== emptyChoice2) {
        alert("Choose only one space to simulate.");
        return;
      }
    } else {
      if (preset === emptyChoice1) {
        alert("Please choose a moving vehicle.");
        return;
      }
    }
    //

    // Check the type of selected value
    let type = "";
    if (preset !== emptyChoice1) {
      type = "preset";
    }

    if (spaces.length > 0) {
      if (custom !== emptyChoice2) {
        type = "custom";
      }
    }
    //

    let spaceDim = {};
    if (type === "preset") {
      uhaulPresets.forEach((uhaul) => {
        if (uhaul.Alias === preset) {
          spaceDim = {
            ...uhaul,
            Length: Math.round(parseFloat(uhaul.Length) * 12 * 10) / 10,
            Width: Math.round(parseFloat(uhaul.Width) * 12 * 10) / 10,
            Height: Math.round(parseFloat(uhaul.Height) * 12 * 10) / 10,
          };
        }
      });
    }

    let momDim = {};
    if (spaceDim.Mom) {
      momDim = {
        Mom: true,
        MomL: Math.round(parseFloat(spaceDim.MomL) * 12 * 10) / 10,
        MomW: Math.round(parseFloat(spaceDim.MomW) * 12 * 10) / 10,
        MomH: Math.round(parseFloat(spaceDim.MomH) * 12 * 10) / 10,
        PosX:
          Math.round(
            (0 - parseFloat(spaceDim.MomW.split(" ")[0] * 12) * 0.5) * 10
          ) / 10,
        PosY:
          Math.round(
            (spaceDim.Height -
              parseFloat(spaceDim.MomH.split(" ")[0] * 12) * 0.5) *
              10
          ) / 10,
        PosZ:
          Math.round(
            (0 + parseFloat(spaceDim.MomL.split(" ")[0] * 12) * 0.5) * 10
          ) / 10,
      };
    }

    // Convert items to be simulated
    items.forEach((item, i) => {
      let tempItem = {
        ...item,
        Length: Math.round(parseFloat(item.Length) * 12 * 10) / 10,
        Width: Math.round(parseFloat(item.Width) * 12 * 10) / 10,
        Height: Math.round(parseFloat(item.Height) * 12 * 10) / 10,
        Weight: Math.round(parseFloat(item.Weight) * 10) / 10,
        Perimeter:
          2 * (Math.round(parseFloat(item.Length) * 12 * 10) / 10) +
          2 * (Math.round(parseFloat(item.Width) * 12 * 10) / 10),
        Volume:
          (Math.round(parseFloat(item.Length) * 12 * 10) / 10) *
          (Math.round(parseFloat(item.Width) * 12 * 10) / 10) *
          (Math.round(parseFloat(item.Height) * 12 * 10) / 10),
      };
      items.splice(i, 1, tempItem);
    });
    load = { ...load, Items: [...items] };

    //

    let tempNonPlaced = organizeItems([...items]);

    // Dispatch
    dispatch(storeSimSpaceDimAction(spaceDim));
    dispatch(storeSimMomDimAction(momDim));
    dispatch(storeSimLoadObjAction(load));
    dispatch(storeSimItemsArrAction(tempNonPlaced));
    dispatch(storeSimCurrentNumAction(0));
    let abort = false;
    let currItem = {};
    for (let i = 0; i < tempNonPlaced.length && !abort; i = i + 1) {
      let tempItem = tempNonPlaced[i];
      if (!tempItem.Placed) {
        tempItem = { ...tempItem, Moving: true };
        tempNonPlaced.splice(i, 1, tempItem);
        currItem = { ...tempItem };
        abort = true;
      }
    }
    if (!currItem.Name) {
      currItem = { ...tempNonPlaced[0] };
    }
    dispatch(storeSimCurrentObjAction(currItem));
    dispatch(storeSimOriginalLoadAction(load));

    //
    history.push("/simulation");
    // END METHOD
  };

  const organizeItems = (itemsArr) => {
    let tempItems = [...itemsArr];

    let placed = [];
    let nonPlaced = [];

    tempItems.forEach((it) => {
      if (it.Placed) {
        placed.push(it);
      } else {
        it = { ...it };
        nonPlaced.push(it);
      }
    });

    placed.sort((key1, key2) => (key1.Volume < key2.Volume ? 1 : -1));
    nonPlaced.sort((key1, key2) => (key1.Volume < key2.Volume ? 1 : -1));

    let combined = nonPlaced.concat(placed);
    return combined;
  };
  /////////////////

  useEffect(() => {
    if (!userAuthID.id) {
      dispatch(toggleUserLoggedInAction(false));
      history.push("/login");
      return;
    }

    getAllStorageUnits();
  }, []);
  return (
    <div style={{ fontFamily: "Heebo" }} className="sim-prep-main">
      <button
        className="dash-btn"
        onClick={() => {
          history.push("/dashboard");
        }}
      >
        Dashboard
      </button>

      <div className="blackout loading hide">
        <h1>
          Simulating.<span style={{ color: "orange" }}>.</span>
          <span className="text-red">.</span>
        </h1>
      </div>
      <div className="blackout loadingLong hide">
        <h1>
          Calculating.<span style={{ color: "orange" }}>.</span>
          <span className="text-red">.</span> May take a few minutes
          <span className="text-red">.</span>
        </h1>
      </div>
      <div>
        <h2 className="sim-prep-head-1">
          You are about to run a simulation
          <span className="text-red">.</span>
        </h2>
      </div>

      <div className="space-dd-wrap">
        <p className="obj-create-sub">
          Choose a Moving Truck / Space to run the simulation.
        </p>
        <FaHandPointDown className="hand-icon" />
        <div>
          <p className="sim-prep-sub">Moving Vehicles:</p>
          <select className="selDD" name="selPresets" id="selPresets">
            <option>Choose a Moving Vehicle</option>
            {handleUhaulPresets()}
          </select>
        </div>
        {spaces.length > 0 ? (
          <div>
            <span style={{ color: "#487DFF" }}>OR</span>
            <div>
              <p className="sim-prep-sub">Custom Storage/Moving Vehicle:</p>
              <select className="selDD" name="selSpaces" id="selSpaces">
                <option>Choose a Custom Storage/Moving Vehicle</option>
                {handleStorages()}
              </select>
            </div>
          </div>
        ) : null}
        <br />
        <button onClick={simulate} className="run-sim">
          Simulate
        </button>
      </div>

      <br />
      {/* Object Group Data */}
      <div className="sim-prep-block">
        <h2 className="sim-prep-label">
          Items<span className="text-red">.</span>
        </h2>
        <div className="obj-list-main">{handleObjectGroupData()}</div>
      </div>
      <br />
      {/* Simulate */}
      <div>
        {/* <button
          onClick={runSimulationVersion5BestMatchTimeout}
          className="run-sim-2"
        >
          Find Best Match
        </button> */}
      </div>
    </div>
  );
}
