import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  toggleUserLoggedInAction,
  storeAllStacksAction,
  storeLastObjectAction,
  storeObjectNumAction,
  storeStackedObjectsAction,
  storeStorageDimensionsAction,
  storeMomStorageDimAction,
  storeAllBestMatchesAction,
  storeFullLoadAction
} from "../../redux";
import Footer from "../Footer";
import { FaSadCry } from "react-icons/fa";

export default function StorageSimulationList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAuthID = useSelector((state) => state.storeUserCredsReducer);

  const bestMatches = useSelector((state) => state.storeAllBestMatchesReducer);
  const tempBestMatches = [...bestMatches];
  const uhaulPresets = [
    {
      id: 1,
      Alias: "4 x 8 Cargo Trailer",
      Width: "8 FT",
      Length: "4 FT",
      Height: "4 FT",
      Preset: true,
      Trailer: true,
    },
    {
      id: 2,
      Alias: "5 x 8 Cargo Trailer",
      Width: "8 FT",
      Length: "4.5 FT",
      Height: "5 FT",
      Preset: true,
      Trailer: true,
    },
    {
      id: 3,
      Alias: "6 x 12 Cargo Trailer",
      Width: "11.5 FT",
      Length: "6 FT",
      Height: "5.4 FT",
      Preset: true,
      Trailer: true,
    },
    {
      id: 4,
      Alias: "4 x 7 Utility Trailer",
      Width: "7.5 FT",
      Length: "3.2 FT",
      Height: "4.5 FT",
      Preset: true,
      Trailer: true,
    },
    {
      id: 5,
      Alias: "5 x 8 Utility Trailer",
      Width: "9 FT",
      Length: "4.2 FT",
      Height: "4.5 FT",
      Preset: true,
      Trailer: true,
    },
    {
      id: 6,
      Alias: "5 x 9 Utility Trailer with Ramp",
      Width: "9 FT",
      Length: "4.5",
      Height: "5 FT",
      Preset: true,
      Trailer: true,
    },
    {
      id: 7,
      Alias: "6 x 12 Utility Trailer",
      Width: "12 FT",
      Length: "6 FT",
      Height: "4.5 FT",
      Preset: true,
      Trailer: true,
    },
    {
      id: 8,
      Alias: "6 x 12 Utility Trailer with Ramp",
      Width: "12 FT",
      Length: "6 FT",
      Height: "5 FT",
      Preset: true,
      Trailer: true,
    },
    {
      id: 9,
      Alias: "U-Box Moving / Storing Container",
      Width: "7.8 FT",
      Length: "4.5 FT",
      Height: "6.8 FT",
      Preset: true,
      Container: true,
    },
    {
      id: 10,
      Alias: "8 FT Pickup Truck",
      Width: "7.5 FT",
      Length: "5 FT",
      Height: "3.5 FT",
      WheelW: "2.5 FT",
      WheelL: "1 FT",
      WheelH: "1 FT",
      DistanceWheel: "2 FT",
      Preset: true,
      Truck: true,
    },
    {
      id: 11,
      Alias: "9 FT Cargo Van",
      Width: "9.5 FT",
      Length: "5.5 FT",
      Height: "4.5 FT",
      WheelW: "2.5 FT",
      WheelL: "1 FT",
      WheelH: "1 FT",
      DistanceWheel: "4 FT",
      Preset: true,
      Van: true,
    },
    {
      id: 12,
      Alias: "10 FT Moving Truck",
      Width: "9.8 FT",
      Length: "6.2 FT",
      Height: "6 FT",
      WheelW: "2 FT",
      WheelL: "1 FT",
      WheelH: "1 FT",
      DistanceWheel: "4 FT",
      Preset: true,
      Truck: true,
    },
    {
      id: 13,
      Alias: "15 FT Moving Truck",
      Width: "12.2 FT",
      Length: "7.5 FT",
      Height: "7 FT",
      WheelW: "2 FT",
      WheelL: "1 FT",
      WheelH: "1 FT",
      DistanceWheel: "5.5 FT",
      MomW: "2.2 FT",
      MomL: "7.5 FT",
      MomH: "2.5 FT",
      Mom: true,
      Preset: true,
      Truck: true,
    },
    {
      id: 14,
      Alias: "17 FT Moving Truck",
      Width: "14.2 FT",
      Length: "7.5 FT",
      Height: "7 FT",
      WheelW: "2 FT",
      WheelL: "1 FT",
      WheelH: "1 FT",
      DistanceWheel: "6.5 FT",
      MomW: "2.2 FT",
      MomL: "7.5 FT",
      MomH: "2.5 FT",
      Mom: true,
      Preset: true,
      Truck: true,
    },
    {
      id: 15,
      Alias: "20 FT Moving Truck",
      Width: "19.2 FT",
      Length: "7.5 FT",
      Height: "7 FT",
      WheelW: "2 FT",
      WheelL: "1 FT",
      WheelH: "1 FT",
      DistanceWheel: "8.2 FT",
      MomW: "2.2 FT",
      MomL: "7.5 FT",
      MomH: "2.5 FT",
      Mom: true,
      Preset: true,
      Truck: true,
    },
    {
      id: 16,
      Alias: "26 FT Moving Truck",
      Width: "23.2 FT",
      Length: "8 FT",
      Height: "8.2 FT",
      WheelW: "2 FT",
      WheelL: "1 FT",
      WheelH: "1 FT",
      DistanceWheel: "12 FT",
      MomW: "2.5 FT",
      MomL: "8 FT",
      MomH: "3 FT",
      Mom: true,
      Preset: true,
      Truck: true,
    },
  ];

  //   HANDLE
  const handleBestMatches = () => {
    return bestMatches.map((match, i) => {
      return (
        <div
          className={
            i === 0 ? "best-match best-match-match" : "best-match-match"
          }
          key={i}
        >
          {i === 0 ? <h1>Best Match*</h1> : null}
          <p style={{ fontWeight: "600" }}>{match.StorGroup}</p>
          <h3>{match.StorUnit}</h3>
          <p>
            Available Space:{" "}
            <b style={{ fontSize: "16px" }}>
              {Math.round((match.Difference / (12 * 12 * 12)) * 10) / 10} cu ft
            </b>
          </p>

          <button
            id={match.Rank}
            onClick={simulateBestMatch}
            className="best-match-btn"
          >
            Simulate
          </button>
        </div>
      );
    });
  };
  const simulateBestMatch = (event) => {
    let bestChoice = parseInt(event.currentTarget.getAttribute("id"));

    let thisOne = {};
    bestMatches.forEach((be) => {
      if (be.Rank === bestChoice) {
        thisOne = be;
        dispatch(storeFullLoadAction(be));
      }
    });

    // Save to DB
    let up = false;
    uhaulPresets.forEach((uhaul) => {
      if (uhaul.Alias === thisOne.StorUnit) {
        up = true;
      }
    });

    if (!up) {
      dispatch(storeAllStacksAction(thisOne.Merged));
      dispatch(
        storeLastObjectAction(thisOne.Merged[thisOne.Merged.length - 1])
      );
      dispatch(storeObjectNumAction(thisOne.Merged.length));
      dispatch(storeStackedObjectsAction(thisOne.Merged));
      dispatch(storeStorageDimensionsAction(thisOne.StorageDim));
      history.push("/simulation-render");
    } else {
      if (thisOne.Mom) {
        dispatch(storeMomStorageDimAction(thisOne.MomsDim));
      }
      dispatch(storeAllStacksAction(thisOne.Merged));
      dispatch(
        storeLastObjectAction(thisOne.Merged[thisOne.Merged.length - 1])
      );
      dispatch(storeObjectNumAction(thisOne.Merged.length));
      dispatch(storeStackedObjectsAction(thisOne.Merged));
      dispatch(storeStorageDimensionsAction(thisOne.StorageDim));
      history.push("/simulation-render");
    }
  };

  // ONCHANGE
  const onfilterChange = (event) => {
    let spaceType = event.currentTarget.getAttribute("value");
    let allTypes = document.querySelectorAll(".filter-check");
  };

  useEffect(() => {
    if (!userAuthID.id) {
      dispatch(toggleUserLoggedInAction(false));
      history.push("/login");
      return;
    }
    document.querySelector("#Trucks").checked = true;
    document.querySelector("#Moms").checked = true;
    document.querySelector("#Trailers").checked = true;
    document.querySelector("#Vans").checked = true;
    document.querySelector("#Containers").checked = true;
    document.querySelector("#Spaces").checked = true;
    document.querySelector("#All").checked = true;

    window.scroll(0, 0);
  }, []);
  return (
    <div className="best-main">
      <button
        className="dash-btn"
        onClick={() => {
          history.push("/dashboard");
        }}
      >
        Dashboard
      </button>
      <div className="sim-main-head">
        <h1>
          Best Matches<span className="text-red">.</span>
        </h1>
      </div>

      <div className="filters hide">
        <span
          style={{
            color: "orangered",
            fontWeight: 600,
            marginRight: "15px",
            fontSize: "18px",
          }}
        >
          Filters:
        </span>
        <div style={{ display: "flex" }}>
          <input
            className="filter-check"
            type="checkbox"
            value="Trucks"
            onChange={onfilterChange}
            id="Trucks"
          />
          <label className="filter-label">Trucks</label>
        </div>
        <div style={{ display: "flex" }}>
          <input
            className="filter-check"
            type="checkbox"
            value="Mom's Attic"
            onChange={onfilterChange}
            id="Moms"
          />
          <label className="filter-label">Mom's Attic</label>
        </div>
        <div style={{ display: "flex" }}>
          <input
            className="filter-check"
            type="checkbox"
            value="Trailers"
            onChange={onfilterChange}
            id="Trailers"
          />
          <label className="filter-label">Trailers</label>
        </div>
        <div style={{ display: "flex" }}>
          <input
            className="filter-check"
            type="checkbox"
            value="Vans"
            onChange={onfilterChange}
            id="Vans"
          />
          <label className="filter-label">Vans</label>
        </div>
        <div style={{ display: "flex" }}>
          <input
            className="filter-check"
            type="checkbox"
            value="Containers"
            onChange={onfilterChange}
            id="Containers"
          />
          <label className="filter-label">Containers</label>
        </div>
        <div style={{ display: "flex" }}>
          <input
            className="filter-check"
            type="checkbox"
            value="Spaces"
            onChange={onfilterChange}
            id="Spaces"
          />
          <label className="filter-label">Spaces</label>
        </div>
        <div style={{ display: "flex" }}>
          <input
            className="filter-check"
            type="checkbox"
            value="All"
            onChange={onfilterChange}
            id="All"
          />
          <label className="filter-label">All</label>
        </div>
      </div>

      <div className="best-list-wrap">{handleBestMatches()}</div>
      <div>
        {bestMatches.length === 0 ? (
          <h1 style={{ fontFamily: "Heebo", margin: "50px 0px" }}>
            My apologies, there are no storage units that fit all your objects
            <span className="text-red">
              .
            </span> <FaSadCry color="orangered" /> <br />
            You may want to consider saving the dimensions of a larger storage
            unit<span className="text-red">.</span>
            <br />
            <br />
          </h1>
        ) : null}
      </div>
    </div>
  );
}
