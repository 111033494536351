import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toggleUserLoggedInAction } from "../../redux";
import "./MainAcct.css";

import Footer from "../Footer";

export default function SimulationObjectGroupView() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAuthID = useSelector((state) => state.storeUserCredsReducer);

  const objGroup = useSelector((state) => state.storeObjectGroupReducer);

  //   HANDLE
  const handleObjectList = () => {
    if (objGroup.Objects) {
      return objGroup.Objects.map((obj, i) => {
        return (
          <div className="obj-list-block" key={i}>
            <h3 className="obj-list-alias">
              {obj.Alias}
              <span className="text-red">.</span>
            </h3>
            <p className="obj-list-prop">
              Width: <span className="obj-data">{obj.Width}</span>
            </p>
            <p className="obj-list-prop">
              Length: <span className="obj-data">{obj.Length}</span>
            </p>
            <p className="obj-list-prop">
              Height: <span className="obj-data">{obj.Height}</span>
            </p>
            <p className="obj-list-prop">
              Weight: <span className="obj-data">{obj.Weight}</span>
            </p>
            <p className="obj-list-prop">
              Fragile:{" "}
              <span className="obj-data">{obj.Fragile ? "Yes" : "No"}</span>
            </p>
            <p className="obj-list-prop">
              Abstract:{" "}
              <span className="obj-data">{obj.Abstract ? "Yes" : "No"}</span>
            </p>
            <p className="obj-list-prop">
              Available Space:{" "}
              <span className="obj-data">{obj.Space ? "Yes" : "No"}</span>
            </p>
            <p className="obj-list-prop">
              Material: <span className="obj-data">{obj.Material}</span>
            </p>
          </div>
        );
      });
    }
  };

  //   NAV
  const navSimPrep = () => {
    history.push("/simulation-prep");
  };

  useEffect(() => {
    if (!userAuthID.id) {
      dispatch(toggleUserLoggedInAction(false));
      history.push("/login");
      return;
    }
  }, []);
  return (
    <div>
      <h1 className="obj-view-head">
        {objGroup.Name}
        <span className="text-red">.</span>
      </h1>
      <button onClick={() => history.push('/simulations')}>Back</button>
      <br />
      <br />
      <div className="obj-list-main">{handleObjectList()}</div>
      <br />
      <br />
      {/* Footer */}
      <div>
        <Footer />
      </div>
    </div>
  );
}
