import React from "react";
import "./App.css";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Link, Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import Uhaul from "./components/Uhaul";
import Contact from "./components/Contact";
import Login from "./components/Login";
import Dashboard from "./components/Account/Dashboard";
import SimulationsMain from "./components/Account/SimulationsMain";
import SimulationObjectGroupView from "./components/Account/SimulationObjectGroupView";
import SimulationPrep from "./components/Account/SimulationPrep";
import SimulationRender from "./components/Account/SimulationRender";
import ObjectGroupCreate from "./components/Account/ObjectGroupCreate";
import ObjectGroupEdit from "./components/Account/ObjectGroupEdit";
import StorageCreate from "./components/Account/StorageCreate";
import StorageEdit from "./components/Account/StorageEdit";
import StorageSimulationList from "./components/Account/StorageSimulationList";
import Register from "./components/Register";
import Penske from "./components/Penske";
import PublicStorage from "./components/PublicStorage";
import Simulation from "./components/Account/Simulation";
import Footer from "./components/Footer";
import { AiFillHome } from "react-icons/ai";

function App() {
  const toggleLoggedIn = useSelector(
    (state) => state.toggleUserLoggedInReducer
  );

  return (
    <Router>
      <div className="main">
        {/* Main Menu */}

        {toggleLoggedIn ? (
          <h1 className="main-name">
            PackMySpace<span className="text-red">.</span>
          </h1>
        ) : (
          <div className="top-panel">
            <h1 className="main-name">
              PackMySpace<span className="text-red">.</span>
            </h1>
            <ul className="nav-main-ul">
              <li>
                <Link className="link nav-main-Link" to="/">
                  <AiFillHome />
                </Link>
              </li>
              {/* <li>
                  <Link className="nav-main-Link" to="/publicstorage">
                    Public Storage
                  </Link>
                </li> */}
              {/* <li>
                  <Link className="nav-main-Link" to="/support">
                    Support
                  </Link>
                </li> */}
              {/* <li>
                  <Link className="nav-main-Link" to="/contact">
                    Contact
                  </Link>
                </li> */}
              <li>
                <Link className="link" id="nav-main-login" to="/login">
                  Log In
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>

      <Switch>
        <div>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/uhaul">
            <Uhaul />
          </Route>
          <Route path="/penske">
            <Penske />
          </Route>
          <Route path="/publicstorage">
            <PublicStorage />
          </Route>

          <Route path="/contact">
            <Contact />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <div className="push-right">
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/wizard">
              <SimulationsMain />
            </Route>
            <Route path="/object-group-view">
              <SimulationObjectGroupView />
            </Route>
            <Route path="/simulation-prep">
              <SimulationPrep />
            </Route>
            <Route path="/simulation-render">
              <SimulationRender />
            </Route>
            <Route path="/simulation">
              <Simulation />
            </Route>
            <Route path="/object-group-create">
              <ObjectGroupCreate />
            </Route>
            <Route path="/object-group-edit">
              <ObjectGroupEdit />
            </Route>
            <Route path="/space-create">
              <StorageCreate />
            </Route>
            <Route path="/space-edit">
              <StorageEdit />
            </Route>
            <Route path="/space-sim-list">
              <StorageSimulationList />
            </Route>
          </div>
        </div>
      </Switch>
    </Router>
  );
}

export default App;
