import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { firebaseLooper, users_Collection } from "../../firebase";
import {
  storeAllObjectGroupsAction,
  storeAllSimulationsAction,
  storeAllStoragesAction,
  storeCreateObjectGroupsAction,
  storeCurrentMovingLoadAction,
  storeCurrentSpaceAction,
  storeObjectGroupsAction,
  storeObjectGroupAction,
  storeSingleObjectGroupAction,
  storeStorageUnitsAction,
  toggleCurrentMovingLoadMenuAction,
  toggleCurrentSpaceMenuAction,
  toggleUserLoggedInAction,
  storeShareObjectGroupAction,
  toggleShareModalAction,
  storeSingleStorageAction,
  storeInvalidCredsMessageAction,
  storeMostRecentLoadAction,
} from "../../redux";
import "./MainAcct.css";
import ShareModal from "./ShareModal";

import { BsThreeDotsVertical } from "react-icons/bs";
import { BiExit } from "react-icons/bi";

export default function Dashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAuthID = useSelector((state) => state.storeUserCredsReducer);

  const objGroups = useSelector((state) => state.storeAllObjectGroupsReducer);
  const stors = useSelector((state) => state.storeAllStoragesReducer);
  const currLoad = useSelector((state) => state.storeCurrentMovingLoadReducer);
  const currSpace = useSelector((state) => state.storeCurrentSpaceReducer);
  const mostRecent = useSelector((state) => state.storeMostRecentLoadReducer);

  const toggleLoadMenu = useSelector(
    (state) => state.toggleCurrentMovingLoadMenuReducer
  );
  const toggleSpaceMenu = useSelector(
    (state) => state.toggleCurrentSpaceMenuReducer
  );
  const toggleShareModal = useSelector(
    (state) => state.toggleShareModalReducer
  );

  // GET
  const getObjectGroups = () => {
    users_Collection
      .doc(userAuthID.id)
      .collection("Loads")
      .orderBy("Name", "asc")
      .get()
      .then((snapshot) => {
        const objGrpsData = firebaseLooper(snapshot);
        dispatch(storeAllObjectGroupsAction(objGrpsData));
        if (mostRecent.Name) {
          dispatch(storeCurrentMovingLoadAction(mostRecent));
          document.querySelector("#ddLoads").value = mostRecent.Name;
        } else {
          dispatch(storeCurrentMovingLoadAction(objGrpsData[0]));
        }

        if (toggleLoadMenu) {
          dispatch(toggleCurrentMovingLoadMenuAction());
        }
      })
      .catch((err) => console.log(err));
  };
  const getStorages = () => {
    users_Collection
      .doc(userAuthID.id)
      .collection("Spaces")
      .orderBy("Alias", "asc")
      .get()
      .then((snapshot) => {
        const storData = firebaseLooper(snapshot);
        dispatch(storeCurrentSpaceAction(storData[0]));
        dispatch(storeAllStoragesAction(storData));
      })
      .catch((err) => console.log(err));
  };

  // HANDLE
  const handleMovingLoad = () => {
    return (
      <div className="current-wrap">
        <div className="current-div">
          <button onClick={navSimPrep} className="current-sim">
            Simulate
          </button>

          <button
            onClick={() => dispatch(toggleCurrentMovingLoadMenuAction())}
            className="current-btn"
          >
            <BsThreeDotsVertical />
          </button>
        </div>
      </div>
    );
  };

  // ONCHANGE
  const onMovingLoadChange = (event) => {
    const selectEl = event.currentTarget;
    const chosenLoad = selectEl.options[selectEl.selectedIndex].value;

    let tempObjs = [...objGroups];

    tempObjs.forEach((te) => {
      if (te.Name === chosenLoad) {
        dispatch(storeCurrentMovingLoadAction(te));
        dispatch(storeMostRecentLoadAction(te));
      }
    });
  };
  const onSpaceChange = (event) => {
    const selectEl = event.currentTarget;
    const chosenLoad = selectEl.options[selectEl.selectedIndex].value;

    let tempObjs = [...stors];

    tempObjs.forEach((te) => {
      if (te.Name === chosenLoad) {
        dispatch(storeCurrentSpaceAction(te));
      }
    });
  };

  // ONCLICK
  const onObjShare = () => {
    const objID = currLoad.id;
    // Dispatch the object group
    objGroups.forEach((og) => {
      if (og.id === objID) {
        users_Collection
          .doc(userAuthID.id)
          .collection("Loads")
          .doc(og.id)
          .collection("Items")
          .get()
          .then((snapshot) => {
            const objData = firebaseLooper(snapshot);
            og = {
              Name: og.Name,
              id: og.id,
              Items: objData,
            };
            dispatch(storeShareObjectGroupAction(og));
            dispatch(toggleShareModalAction(true));
          })
          .catch((err) => console.log(err));
      }
    });
  };
  const navStorageEdit = () => {
    const storID = currSpace.id;

    stors.forEach((stor) => {
      if (stor.id === storID) {
        dispatch(storeSingleStorageAction(stor));
        history.push("/space-edit");
      }
    });
  };

  // REMOVE
  const removeObjGroup = () => {
    const objGrpID = currLoad.id;

    // Remove Items collection first
    users_Collection
      .doc(userAuthID.id)
      .collection("Loads")
      .doc(objGrpID)
      .collection("Items")
      .get()
      .then((snapshot) => {
        const objsData = firebaseLooper(snapshot);
        objsData.forEach((obj) => {
          users_Collection
            .doc(userAuthID.id)
            .collection("Loads")
            .doc(objGrpID)
            .collection("Items")
            .doc(obj.id)
            .delete()
            .catch((err) => console.log(err));
        });

        users_Collection
          .doc(userAuthID.id)
          .collection("Loads")
          .doc(objGrpID)
          .delete()
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));

    // Dispatch
    let allObjGrps = [...objGroups];
    allObjGrps.forEach((group, i) => {
      if (group.id === objGrpID) {
        allObjGrps.splice(i, 1);
      }
    });

    getObjectGroups();

    dispatch(storeObjectGroupsAction(allObjGrps));
    if (mostRecent.Name === currLoad.Name) {
      dispatch(storeMostRecentLoadAction({}));
    }
    dispatch(toggleCurrentSpaceMenuAction());
  };
  const removeStorage = () => {
    const storageID = currSpace.id;
    users_Collection
      .doc(userAuthID.id)
      .collection("Spaces")
      .doc(storageID)
      .delete()
      .catch((err) => console.log(err));

    let tempStorUnits = [...stors];
    let filtered = tempStorUnits.filter((uni) => uni.id !== storageID);

    dispatch(storeAllStoragesAction(filtered));
    dispatch(toggleCurrentSpaceMenuAction());
    getStorages();
  };

  // NAV
  const navLoadEdit = () => {
    users_Collection
      .doc(userAuthID.id)
      .collection("Loads")
      .where("Name", "==", currLoad.Name)
      .get()
      .then((snapshot) => {
        const loadData = firebaseLooper(snapshot);
        loadData.forEach((load) => {
          users_Collection
            .doc(userAuthID.id)
            .collection("Loads")
            .doc(load.id)
            .collection("Items")
            .orderBy("Name", "asc")
            .get()
            .then((snapshot) => {
              const loadObjsData = firebaseLooper(snapshot);
              let temp = { ...load, Items: [...loadObjsData] };
              dispatch(storeCreateObjectGroupsAction(loadObjsData));
              dispatch(storeSingleObjectGroupAction(temp));
              dispatch(storeMostRecentLoadAction(temp));
              dispatch(toggleCurrentMovingLoadMenuAction());
              history.push("/object-group-edit");
            })
            .catch((err) => console.log(err));
        });
      })
      .catch((err) => console.log(err));
  };
  const navSimPrep = () => {
    users_Collection
      .doc(userAuthID.id)
      .collection("Loads")
      .where("Name", "==", currLoad.Name)
      .get()
      .then((snapshot) => {
        const loadData = firebaseLooper(snapshot);
        loadData.forEach((load) => {
          users_Collection
            .doc(userAuthID.id)
            .collection("Loads")
            .doc(load.id)
            .collection("Items")
            .orderBy("Name", "asc")
            .get()
            .then((snapshot) => {
              const loadObjsData = firebaseLooper(snapshot);
              let temp = { ...load, Items: [...loadObjsData] };
              dispatch(storeObjectGroupAction(temp));
              dispatch(storeMostRecentLoadAction(temp));
              history.push("/simulation-prep");
            })
            .catch((err) => console.log(err));
        });
      })
      .catch((err) => console.log(err));
    dispatch(toggleCurrentSpaceMenuAction());
  };

  useEffect(() => {
    if (!userAuthID) {
      dispatch(toggleUserLoggedInAction(false));
      history.push("/login");
      return;
    }
    window.scroll(0, 0);
    dispatch(storeCreateObjectGroupsAction([]));

    document
      .querySelector(".dash-main-wrapper")
      .setAttribute("style", `min-height: ${window.innerHeight - 70}px`);
    if (toggleSpaceMenu) {
      dispatch(toggleCurrentSpaceMenuAction());
    }
    getObjectGroups();
    getStorages();
  }, []);

  return (
    <div>
      {toggleShareModal ? <ShareModal /> : null}
      <button
        className="logout"
        onClick={() => {
          dispatch(storeObjectGroupsAction([]));
          dispatch(storeStorageUnitsAction([]));
          dispatch(storeAllStoragesAction([]));
          dispatch(toggleUserLoggedInAction());
          dispatch(storeCurrentMovingLoadAction({}));
          dispatch(storeCurrentSpaceAction({}));
          dispatch(storeInvalidCredsMessageAction(""));
          dispatch(storeMostRecentLoadAction({}));
          history.push("/login");
        }}
      >
        Log Out
      </button>
      <div className="dash-main-wrapper">
        <div className="dash-main">
          <h1>
            Welcome, {userAuthID.FirstName}
            <span className="text-red">.</span>
          </h1>
        </div>

        <div className="dash-stat-group">
          <div>
            <div className="dash-stat-block">
              <div className="dash-stat-info">
                <p>{objGroups.length}</p>
                <h4>Moving Loads</h4>
              </div>
              <div className="new-something-div">
                <button
                  onClick={() => history.push("/object-group-create")}
                  className="new-something"
                >
                  New Moving Load
                </button>
              </div>
            </div>
            {objGroups.length > 0 ? (
              <div className="ddWrap">
                <select
                  onChange={onMovingLoadChange}
                  className="ddDash"
                  name="loads"
                  id="ddLoads"
                >
                  {objGroups.map((obj, i) => {
                    return (
                      <option key={i} value={obj.Name}>
                        {obj.Name}
                      </option>
                    );
                  })}
                </select>
                {handleMovingLoad()}
              </div>
            ) : null}
            {toggleLoadMenu ? (
              <div className="current-menu">
                <button onClick={navLoadEdit} className="edit current-menu-btn">
                  Edit
                </button>
                {/* <button onClick={onObjShare} className="share current-menu-btn">
                  Share
                </button> */}
                <button
                  onClick={removeObjGroup}
                  className="remove current-menu-btn"
                >
                  Remove
                </button>
              </div>
            ) : null}
          </div>

          <div>
            <div className="dash-stat-block">
              <div className="dash-stat-info">
                <p>{stors.length}</p>
                <h4>Custom Storage / Truck</h4>
              </div>
              <div className="new-something-div">
                <button
                  onClick={() => history.push("/space-create")}
                  className="new-something"
                >
                  New Custom Storage / Truck
                </button>
              </div>
            </div>
            {stors.length > 0 ? (
              <div className="ddWrap">
                <div className="space-wrap">
                  {" "}
                  <select
                    onChange={onSpaceChange}
                    className="ddDash"
                    name="spaces"
                    id="ddSpaces"
                  >
                    {stors.map((stor, i) => {
                      return (
                        <option key={i} value={stor.Alias}>
                          {stor.Alias}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    onClick={() => dispatch(toggleCurrentSpaceMenuAction())}
                    className="current-btn"
                  >
                    <BsThreeDotsVertical />
                  </button>
                </div>
              </div>
            ) : null}
            {toggleSpaceMenu ? (
              <div className="current-menu">
                <button
                  onClick={navStorageEdit}
                  className="edit current-menu-btn"
                >
                  Edit
                </button>
                <button
                  onClick={removeStorage}
                  className="remove current-menu-btn"
                >
                  Remove
                </button>
              </div>
            ) : null}
          </div>
        </div>

        <div className="share-key-block">
          <h4>Share Key</h4>
          <p className="share-key-head">
            The share key is used for users to share items with this account.
          </p>
          <p className="share-key">{userAuthID.ShareKey}</p>
        </div>
      </div>
    </div>
  );
}
