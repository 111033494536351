import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import firebase, { users_Collection } from "../../firebase";
import {
  storeLastObjectAction,
  storeObjectNumAction,
  toggleUserLoggedInAction,
  storeAllStacksAction,
  storeStackedObjectsAction,
  storeStorageDimensionsAction,
  storeMissingObjectsAction,
  storeMomStorageDimAction,
  storeMomStorageObjectsAction,
  storeSearchResultsAction,
  toggleSearchResultsAction,
  storeFullLoadAction,
  toggleHideMenuAction,
  toggleItemMenuAction,
  toggleItemListAction,
  storeOriginalItemsAction,
} from "../../redux";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import floorImg from "./Textures/floor.jpg";
import wallImg from "./Textures/wall.jpg";
import metalImg from "./Textures/metal.jpeg";
import _ from "lodash";
import { BsFillUnlockFill, BsLockFill } from "react-icons/bs";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaTools, FaListUl } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";

export default function SimulationRender() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAuthID = useSelector((state) => state.storeUserCredsReducer);

  const searchResults = useSelector((state) => state.storeSearchResultsReducer);

  const stacks = useSelector((state) => state.storeStackedObjectsReducer);
  const storageDim = useSelector(
    (state) => state.storeStorageDimensionsReducer
  );
  const itemGroup = useSelector((state) => state.storeFullLoadReducer);
  const origItemGroup = useSelector(
    (state) => state.storeOriginalItemGroupReducer
  );
  const origAllItems = useSelector((state) => state.storeOriginalItemsReducer);
  const allStacks = useSelector((state) => state.storeAllStacksReducer);
  const lastObject = useSelector((state) => state.storeLastObjectReducer);
  const objNum = useSelector((state) => state.storeObjectNumReducer);
  let multiplier = 0.02;
  const wheels = useSelector((state) => state.storeWheelObjectsReducer);

  const momsAttic = useSelector((state) => state.storeMomStorageDimReducer);

  let storAlias = storageDim.Alias;
  let storWidth = Math.round((storageDim.Width / 12) * 10) / 10;
  let storLength = Math.round((storageDim.Length / 12) * 10) / 10;
  let storHeight = Math.round((storageDim.Height / 12) * 10) / 10;

  let toggleSearch = useSelector((state) => state.toggleSearchResultsReducer);
  let toggleItemMenu = useSelector((state) => state.toggleHideMenuReducer);
  let toggleItemList = useSelector((state) => state.toggleItemListReducer);

  let resimulate = useSelector((state) => state.toggleResimulateReducer);

  // GET
  const getInitialDetails = () => {
    document
      .querySelector("#canvas-sim")
      .setAttribute("style", "width: 100%; height:600px");
  };

  // HANDLE
  const handleSearchResults = () => {
    return searchResults.map((res, i) => {
      return (
        <button
          id={res.Alias}
          onClick={onSearchClick}
          className="btn-res"
          key={i}
        >
          <h4 className="btn-res-name">
            {res.Alias}
            <span className="text-blue">.</span>
          </h4>
          <p className="btn-res-contents">{res.Contents}</p>
        </button>
      );
    });
  };
  const handleItemList = () => {
    let items = allStacks;
    let tempItems = [];
    for (let i = objNum; i < items.length; i = i + 1) {
      let item = items[i];
      tempItems.push(item);
    }
    return tempItems.map((item, i) => {
      return (
        <div className="item-list-wrap" key={i}>
          <p className="item-list-num">{i + objNum + 1}</p>
          <button
            onClick={onInsertItem}
            id={item.Alias}
            className="item-list-icon"
          >
            Place
          </button>
          <p className="item-list-name">{item.Alias}</p>
        </div>
      );
    });
  };
  const handleItemNumList = () => {
    return allStacks.map((item, i) => {
      return <option key={i}>{i + 1}</option>;
    });
  };

  // SIM
  const handleScene = () => {
    const canvas = document.querySelector("#canvas-sim");
    const scene = new THREE.Scene();
    scene.background = new THREE.Color("black");
    const loader = new THREE.TextureLoader();
    const floorW = storageDim.Width * multiplier;
    const floorL = storageDim.Length * multiplier;
    const floorH = 0.1;

    //
    const camera = new THREE.PerspectiveCamera(
      75,
      canvas.clientWidth / canvas.clientHeight,
      0.1,
      100
    );
    camera.name = "camera";

    let tempObj = allStacks[objNum - 1];
    camera.position.x = Math.abs(tempObj.PosX) * multiplier + 1;
    camera.position.y = tempObj.PosY * multiplier + 0.8;
    camera.position.z = tempObj.PosZ * multiplier + 1.1;

    let missingOnes = [];
    stacks.forEach((item) => {
      if (item.Missing) {
        missingOnes.push(item);
      }
    });

    if (missingOnes.length > 0) {
      // RED LINE & TEXT
      var textLoader = new THREE.FontLoader();
      textLoader.load(
        "https://threejs.org/examples/fonts/helvetiker_regular.typeface.json",
        function (font) {
          var geometry = new THREE.TextGeometry(
            `Items taken out / do not fit.`,
            {
              font: font,
              size: 3 * multiplier,
              height: 1 * 0.002,
              curveSegments: 4,
            }
          );
          geometry.center();
          var material = new THREE.MeshBasicMaterial({ color: "white" });
          var mesh = new THREE.Mesh(geometry, material);
          mesh.rotation.y = Math.PI / 2;
          mesh.position.set(floorW + 0.5, wall1H, floorL * 0.5);
          scene.add(mesh);
        }
      );
      // MISSING
      let longestPosX = 0;
      let longestPosZ = 0;
      let longestX = 0;
      let longestZ = 0;
      let totalZ = 0 + missingOnes[0].Length * 0.5 * multiplier;
      let x = floorW + floorW * 0.5;
      for (let m = 0; m < missingOnes.length; m = m + 1) {
        if (m % 3 === 0 && m !== 0) {
          let mm = missingOnes[m - 3].Width * multiplier;
          x = x + mm;
          totalZ = 0 + missingOnes[0].Length * 0.5 * multiplier;
        }
        let mObj = missingOnes[m];
        let width = Math.round(mObj.Width * multiplier * 10) / 10;
        let length = Math.round(mObj.Length * multiplier * 10) / 10;

        let posX = 0;
        let posZ = 0;

        posX = x;
        posZ = totalZ;

        if (posX > longestPosX) {
          longestPosX = posX;
          longestX = longestX + width;
        }
        if (posZ > longestPosZ) {
          longestPosZ = posZ;
          longestZ = longestZ + length;
        }

        if (m < missingOnes.length - 1) {
          totalZ =
            totalZ +
            length * 0.5 +
            Math.round(missingOnes[m + 1].Length * 0.5 * multiplier * 10) / 10;
        }
      }

      const redGeo = new THREE.BoxGeometry(
        longestX + 0.3,
        0.5 * multiplier,
        longestZ + 0.3
      );
      // let cautionTexture = new THREE.TextureLoader().load(cautionImg);
      // cautionTexture.wrapS = THREE.RepeatWrapping;
      // cautionTexture.wrapT = THREE.RepeatWrapping;
      // cautionTexture.repeat.set(1, 1);
      const redMat = new THREE.MeshBasicMaterial({
        color: "red",
        wireframe: true,
        transparent: true,
        opacity: 0.4,
      });
      const redLine = new THREE.Mesh(redGeo, redMat);

      redLine.position.x = floorW + 1;
      redLine.position.y = 0 - 0.5 * multiplier;
      redLine.position.z = 0 + longestZ * 0.5;

      scene.add(redLine);
      //
    }
    for (let i = 0; i < objNum; i = i + 1) {
      const obj = stacks[i];

      if (!obj.Wheel && !obj.Missing) {
        var textLoader = new THREE.FontLoader();
        textLoader.load(
          "https://threejs.org/examples/fonts/helvetiker_regular.typeface.json",
          function (font) {
            var geometry = new THREE.TextGeometry(`${i + 1}`, {
              font: font,
              size:
                ((obj.Length + obj.Height + obj.Width) / 3) * 0.3 * multiplier,
              height: obj.Width * 0.001,
              curveSegments: 4,
            });
            geometry.center();
            var material = new THREE.MeshBasicMaterial({ color: "black" });
            var mesh = new THREE.Mesh(geometry, material);
            mesh.rotation.y = Math.PI / 2;
            mesh.position.set(
              (obj.PosX + obj.Width * 0.5) * multiplier,
              obj.PosY * multiplier,
              obj.PosZ * multiplier
            );
            scene.add(mesh);
          }
        );

        let width = parseFloat((obj.Width * multiplier).toFixed(2));
        let length = parseFloat((obj.Length * multiplier).toFixed(2));
        let height = parseFloat((obj.Height * multiplier).toFixed(2));

        const geometry = new THREE.BoxGeometry(width, height, length);
        const material = new THREE.MeshLambertMaterial({
          color: "#DDAB71",
        });

        let lastMaterial = new THREE.MeshLambertMaterial({
          color: "#487DFF",
        });

        let objBox;

        if (obj.Space) {
          const spaceMaterial = new THREE.MeshBasicMaterial({
            color: "black",
            transparent: true,
            opacity: 0,
          });
          objBox = new THREE.Mesh(geometry, spaceMaterial);
        } else {
          objBox = new THREE.Mesh(geometry, material);
        }
        const geo = new THREE.EdgesGeometry(objBox.geometry); // or WireframeGeometry
        const mat = new THREE.LineBasicMaterial({ color: "black" });
        const wireframe = new THREE.LineSegments(geo, mat);
        objBox.add(wireframe);
        if (i === objNum - 1) {
          if (obj.Space) {
            lastMaterial = new THREE.MeshStandardMaterial({
              color: "#5c6dd1",
              wireframe: true,
            });
          }
          objBox = new THREE.Mesh(geometry, lastMaterial);

          dispatch(storeLastObjectAction(obj));
        }

        // Set Obj Positions
        objBox.position.x = obj.PosX * multiplier;
        objBox.position.y = obj.PosY * multiplier;
        objBox.position.z = obj.PosZ * multiplier;
        objBox.receiveShadow = true;

        scene.add(objBox);
      } else if (obj.Missing) {
        let width = obj.Width * multiplier;
        let length = obj.Length * multiplier;
        let height = obj.Height * multiplier;

        let missGeo = new THREE.BoxGeometry(width, height, length);
        let missMat;
        if (!obj.Ignore) {
          missMat = new THREE.MeshStandardMaterial({
            color: "black",
            transparent: true,
            opacity: 0.3,
          });
        } else {
          missMat = new THREE.MeshStandardMaterial({
            color: "#DDAB71",
            transparent: true,
            opacity: 0.3,
          });
        }
        if (i === objNum - 1 && !obj.Ignore) {
          missMat = new THREE.MeshStandardMaterial({
            color: "red",
            transparent: true,
            opacity: 0.2,
          });
          dispatch(storeLastObjectAction(obj));
        }
        if (i === objNum - 1 && obj.Ignore) {
          missMat = new THREE.MeshStandardMaterial({
            color: "#DDAB71",
            transparent: true,
            opacity: 0.6,
          });
          dispatch(storeLastObjectAction(obj));
        }

        let missItem = new THREE.Mesh(missGeo, missMat);
        missItem = new THREE.Mesh(missGeo, missMat);
        const missgeo = new THREE.EdgesGeometry(missItem.geometry); // or WireframeGeometry
        let missmat = new THREE.LineBasicMaterial({ color: "#DCE1E8" });

        const misswireframe = new THREE.LineSegments(missgeo, missmat);
        missItem.add(misswireframe);

        missItem.position.x = obj.PosX * multiplier;
        missItem.position.y = obj.PosY * multiplier;
        missItem.position.z = obj.PosZ * multiplier;

        scene.add(missItem);

        camera.position.x = Math.abs(tempObj.PosX) * multiplier + 1.1;
        camera.position.y = tempObj.PosY * multiplier + 0.5;
        camera.position.z = tempObj.PosZ * multiplier + 1.3;
        camera.lookAt(camera.position);
      }
    }

    let floorTexture = new THREE.TextureLoader().load(floorImg);
    floorTexture.wrapS = THREE.RepeatWrapping;
    floorTexture.wrapT = THREE.RepeatWrapping;
    floorTexture.repeat.set(1, 1);
    // Moms

    if (momsAttic.Height) {
      const momFloorW = momsAttic.Width * multiplier;
      const momFloorL = momsAttic.Length * multiplier;
      const momFloorH = 0.1;

      const momFloorGeo = new THREE.BoxGeometry(
        momFloorW,
        momFloorH,
        momFloorL
      );
      const momFloorMat = new THREE.MeshLambertMaterial({ map: floorTexture });
      const momFloor = new THREE.Mesh(momFloorGeo, momFloorMat);
      momFloor.receiveShadow = true;

      momFloor.position.x = momsAttic.PosX * multiplier;
      momFloor.position.y =
        (momsAttic.PosY - momsAttic.Height * 0.5) * multiplier - 0.05;
      momFloor.position.z = momsAttic.PosZ * multiplier;

      scene.add(momFloor);

      // Wall 1

      const momWall1W = momsAttic.Width * multiplier;
      const momWall1L = 0.1;
      const momWall1H = momsAttic.Height * multiplier + 0.1;

      let wallTexture1 = new THREE.TextureLoader().load(wallImg);
      wallTexture1.wrapS = THREE.RepeatWrapping;
      wallTexture1.wrapT = THREE.RepeatWrapping;
      wallTexture1.repeat.set(1, 1);

      const momWall1Geo = new THREE.BoxGeometry(
        momWall1W,
        momWall1H,
        momWall1L
      );
      const momWallMat = new THREE.MeshLambertMaterial({ map: wallTexture1 });
      const momWall1 = new THREE.Mesh(momWall1Geo, momWallMat);

      momWall1.position.x = momsAttic.PosX * multiplier;
      momWall1.position.y =
        (momsAttic.PosY + momWall1H * 0.5) * multiplier - 0.05;
      momWall1.position.z =
        (momsAttic.PosZ - momsAttic.Length * 0.5) * multiplier - 0.05;

      scene.add(momWall1);

      //

      // Wall 2

      const momWall2W = 0.1;
      const momWall2L = momsAttic.Length * multiplier;
      const momWall2H = momsAttic.Height * multiplier + 0.1;

      const momWall2Geo = new THREE.BoxGeometry(
        momWall2W,
        momWall2H,
        momWall2L
      );

      const momWall2 = new THREE.Mesh(momWall2Geo, momWallMat);

      momWall2.position.x =
        (momsAttic.PosX - momsAttic.Width * 0.5) * multiplier - 0.05;
      momWall2.position.y = momsAttic.PosY * multiplier - 0.05;
      momWall2.position.z = momsAttic.PosZ * multiplier;

      scene.add(momWall2);

      //
    }
    // ////////////////////////////////////

    // FLOOR

    const floorGeo = new THREE.BoxGeometry(floorW, floorH, floorL);
    const floorMat = new THREE.MeshLambertMaterial({ map: floorTexture });
    const floor = new THREE.Mesh(floorGeo, floorMat);

    floor.position.x = 0.5 * floorW;
    floor.position.y = 0.5 * floorH - 0.1;
    floor.position.z = 0.5 * floorL;

    scene.add(floor);
    //

    // WALL 1
    const wall1W = storageDim.Width * multiplier;
    const wall1L = 0.1;
    let wall1H = storageDim.Height * multiplier + 0.1;

    let wallTexture1 = new THREE.TextureLoader().load(wallImg);
    wallTexture1.wrapS = THREE.RepeatWrapping;
    wallTexture1.wrapT = THREE.RepeatWrapping;
    wallTexture1.repeat.set(1, 1);
    const wall1Geo = new THREE.BoxGeometry(wall1W, wall1H, wall1L);
    const wallMat = new THREE.MeshLambertMaterial({ map: wallTexture1 });
    const wall1 = new THREE.Mesh(wall1Geo, wallMat);

    wall1.position.x = 0.5 * wall1W;
    wall1.position.y = 0.5 * wall1H - 0.1;
    wall1.position.z = 0.5 * wall1L - 0.1;

    scene.add(wall1);
    //

    // WALL 1
    const wall2W = 0.1;
    const wall2L = storageDim.Length * multiplier;
    let wall2H = storageDim.Height * multiplier + 0.1;

    if (momsAttic.Height) {
      wall2H = (storageDim.Height - momsAttic.Height) * multiplier + 0.1;
    }

    const wall2Geo = new THREE.BoxGeometry(wall2W, wall2H, wall2L);

    const wall2 = new THREE.Mesh(wall2Geo, wallMat);

    wall2.position.x = 0.5 * wall2W - 0.1;
    wall2.position.y = 0.5 * wall2H - 0.1;
    wall2.position.z = 0.5 * wall2L;
    scene.add(wall2);

    //

    // WHEELS

    if (wheels.length > 0) {
      let wheelTexture1 = new THREE.TextureLoader().load(metalImg);
      wheelTexture1.wrapS = THREE.RepeatWrapping;
      wheelTexture1.wrapT = THREE.RepeatWrapping;
      wheelTexture1.repeat.set(1, 1);

      wheels.forEach((wh) => {
        const wheelW = wh.Width * multiplier;
        const wheelL = wh.Length * multiplier;
        const wheelH = wh.Height * multiplier;

        const wheelGeo = new THREE.BoxGeometry(wheelW, wheelH, wheelL);
        const wheelMat = new THREE.MeshLambertMaterial({ map: wheelTexture1 });
        const wheel = new THREE.Mesh(wheelGeo, wheelMat);

        wheel.position.x = wh.PosX * multiplier;
        wheel.position.y = wh.PosY * multiplier;
        wheel.position.z = wh.PosZ * multiplier;

        scene.add(wheel);
      });
    }
    //
    // LIGHT
    const directLight1 = new THREE.DirectionalLight("0x404040", 1, 50);
    directLight1.position.set(floorW - 1, floorH + 2, floorL + 2);

    scene.add(directLight1);

    const directLight2 = new THREE.DirectionalLight("0x404040", 1, 50);
    directLight2.position.set(floorW + 2, floorH + 3, floorL - 3);

    scene.add(directLight2);
    scene.add(camera);

    const controls = new OrbitControls(camera, canvas);

    const renderer = new THREE.WebGLRenderer({ canvas: canvas });

    const tick = () => {
      renderer.setSize(canvas.clientWidth, canvas.clientHeight);
      renderer.render(scene, camera);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

      window.requestAnimationFrame(tick);
    };

    tick();
  };
  const runSimulationVersion5Only = (allMoveItems, spaceDim, momsDim) => {
    // Separate by item type
    let momsItems = [];
    let spaceItems = [];
    let fragileItems = [];
    let abstractItems = [];
    let normalItems = [];
    let things = [];
    let ignoreItems = [];
    let lockedItems = [];
    let insertedItem = [];

    allMoveItems.forEach((item) => {
      let alias = item.Name;
      let width = Math.round(parseFloat(item.Length) * 12 * 10) / 10;
      let length = Math.round(parseFloat(item.Width) * 12 * 10) / 10;
      let height = Math.round(parseFloat(item.Height) * 12 * 10) / 10;
      let weight = parseFloat(item.Weight);
      let material = item.Material;
      let grounded = item.Grounded;
      let momsattic = item.MomsAttic;
      let fragile = item.Fragile;
      let abstract = item.Abstract;
      let space;
      let spaceW;
      let spaceL;
      let spaceH;
      let lock = item.Lock;
      let posX = item.PosX;
      let posY = item.PosY;
      let posZ = item.PosZ;
      let placed = item.Placed;
      let inserted = item.Inserted;
      if (item.Space) {
        space = item.Space;
        spaceW = parseFloat(item.InsideLength * 12);
        spaceL = parseFloat(item.InsideWidth * 12);
        spaceH = parseFloat(item.InsideHeight * 12);
      }
      things.push({
        Alias: alias,
        Width: width,
        Length: length,
        Height: height,
        Perimeter: width + length,
        Area: width * length,
        Volume: width * length * height,
        Weight: weight,
        Contents: item.Contents,
        Material: material,
        Grounded: grounded,
        MomsAttic: momsattic,
        Fragile: fragile,
        Abstract: abstract,
        Space: space,
        Ignore: item.Ignore,
        Inserted: inserted,
        Lock: lock,
        PosX: posX,
        PosY: posY,
        PosZ: posZ,
        Placed: placed,
        InsideWidth: spaceW,
        InsideLength: spaceL,
        InsideHeight: spaceH,
      });
    });
    things.forEach((item) => {
      if (item.MomsAttic && !item.Ignore && !item.Lock) {
        momsItems.push(item);
      } else if (item.Space && !item.Ignore && !item.Lock) {
        spaceItems.push(item);
      } else if (item.Fragile && !item.Ignore && !item.Lock) {
        fragileItems.push(item);
      } else if (item.Abstract && !item.Ignore && !item.Lock) {
        abstractItems.push(item);
      } else if (item.Ignore && !item.Lock) {
        ignoreItems.push(item);
      } else if (item.Lock) {
        lockedItems.push(item);
      } else if (item.Inserted && !item.Lock) {
        insertedItem.push(item);
      } else if (
        (item.Grounded && !item.Inserted) ||
        (!item.Inserted &&
          !item.Grounded &&
          !item.MomsAttic &&
          !item.Space &&
          !item.Fragile &&
          !item.Abstract &&
          !item.Ignore &&
          !item.Lock)
      ) {
        normalItems.push(item);
      }
    });
    // Sort each by Perimeter
    momsItems.sort((key1, key2) => (key1.Perimeter < key2.Perimeter ? 1 : -1));
    spaceItems.sort((key1, key2) => (key1.Perimeter < key2.Perimeter ? 1 : -1));
    fragileItems.sort((key1, key2) =>
      key1.Perimeter < key2.Perimeter ? 1 : -1
    );
    abstractItems.sort((key1, key2) =>
      key1.Perimeter < key2.Perimeter ? 1 : -1
    );
    normalItems.sort((key1, key2) =>
      key1.Perimeter < key2.Perimeter ? 1 : -1
    );
    ignoreItems.sort((key1, key2) =>
      key1.Perimeter < key2.Perimeter ? 1 : -1
    );

    // Moms Attic Items
    if (momsDim.Width) {
      const placedMomsItems = organizeMomsItems(momsDim, momsItems);

      momsItems = [...placedMomsItems];

      for (let i = 0; i < momsItems.length; i = i + 1) {
        let temp = momsItems[i];
        if (isNaN(temp.PosX)) {
          let newTemp = {
            ...temp,
            MomsAttic: false,
            PosX: undefined,
            PosY: undefined,
            PosZ: undefined,
          };

          normalItems.push(newTemp);
          momsItems.splice(i, 1);
        }
      }
      normalItems.sort((key1, key2) => (key1.Volume < key2.Volume ? 1 : -1));

      dispatch(storeMomStorageDimAction(momsDim));
    } else {
      // In case there is no Moms Attic, they will be put with the rest of the pile
      momsItems.forEach((mom) => {
        let temp = { ...mom, MomsAttic: false };
        normalItems.push(temp);
      });
      momsItems.splice(0, momsItems.length);
    }
    spaceDim = { ...spaceDim, Mom: false };

    let groundAllItems;
    if (lockedItems.length > 0) {
      groundAllItems = [lockedItems[0]];
      lockedItems.splice(0, 1);
    } else {
      groundAllItems = [normalItems[0]];
      normalItems.splice(0, 1);
    }

    let combined = lockedItems.concat(
      insertedItem,
      spaceItems,
      normalItems,
      fragileItems,
      abstractItems
    );
    // Grounded Items; we are moving on to the cabin space

    let placedGroundedItems = organizeGroundedItems(spaceDim, groundAllItems);

    let yess = [];
    let nos = [];

    placedGroundedItems.forEach((pl) => {
      if (isNaN(pl.PosX) || !pl.Lock) {
        nos.push(pl);
      } else {
        yess.push(pl);
      }
    });
    let withMegas = groundeMegas(yess, spaceDim);

    yess = [...withMegas];
    // Sort by grounded objects
    let tempGroundedItems = [];
    yess.forEach((item) => {
      if (item.Grounded) {
        tempGroundedItems.push(item);
      }
    });
    lockedItems.forEach((lo) => {
      if (lo.Grounded) {
        tempGroundedItems.push(lo);
      }
    });
    let groundedInOrder = [];

    for (
      let x = 0;
      x <= Math.round(spaceDim.Width * 10) / 10;
      x = Math.round((x + 0.1) * 10) / 10
    ) {
      for (
        let z = 0;
        z <= Math.round(spaceDim.Length * 10) / 10;
        z = Math.round((z + 0.1) * 10) / 10
      ) {
        for (let i = 0; i < tempGroundedItems.length; i = i + 1) {
          let gObj = tempGroundedItems[i];
          let gP1X = Math.round((gObj.PosX - gObj.Width * 0.5) * 10) / 10;
          let gP1Z = Math.round((gObj.PosZ - gObj.Length * 0.5) * 10) / 10;
          let gP2X = Math.round((gObj.PosX + gObj.Width * 0.5) * 10) / 10;
          let gP2Z = Math.round((gObj.PosZ + gObj.Length * 0.5) * 10) / 10;

          if (
            gP1X <= x &&
            gP2X >= x &&
            gP1Z <= z &&
            gP2Z >= z &&
            !gObj.Inside
          ) {
            groundedInOrder.push(gObj);
            tempGroundedItems.splice(i, 1);
          }
        }
      }
    }

    nos.sort((key1, key2) => (key1.Volume < key2.Volume ? 1 : -1));

    let officialCombined = [...yess.concat(...nos, ...combined)];

    // Spaced & Normal Items
    let placedCombinedItems = organizeCombinedItems(
      spaceDim,
      officialCombined,
      groundedInOrder
    );

    let allItems = [...placedCombinedItems];
    momsItems.forEach((m) => {
      if (!isNaN(m.PosX)) {
        allItems.push(m);
      }
    });

    for (let i = 0; i < allItems.length; i = i + 1) {
      if (isNaN(allItems[i].PosX)) {
        allItems.splice(i, 1);
      }
    }

    let nonGroundedItems = [];
    let temptemptemp = [];

    allItems.forEach((ii) => {
      if (ii.Grounded) {
        temptemptemp.push(ii);
      }
    });
    groundedInOrder = [...temptemptemp];
    let newGroundedInOrder = [];
    for (
      let x = 0;
      x <= Math.round(spaceDim.Width * 10) / 10;
      x = Math.round((x + 0.1) * 10) / 10
    ) {
      for (
        let z = 0;
        z <= Math.round(spaceDim.Length * 10) / 10;
        z = Math.round((z + 0.1) * 10) / 10
      ) {
        for (let i = 0; i < groundedInOrder.length; i = i + 1) {
          let gObj = groundedInOrder[i];
          let gP1X = Math.round((gObj.PosX - gObj.Width * 0.5) * 10) / 10;
          let gP1Z = Math.round((gObj.PosZ - gObj.Length * 0.5) * 10) / 10;
          let gP2X = Math.round((gObj.PosX + gObj.Width * 0.5) * 10) / 10;
          let gP2Z = Math.round((gObj.PosZ + gObj.Length * 0.5) * 10) / 10;

          if (
            gP1X <= x &&
            gP2X >= x &&
            gP1Z <= z &&
            gP2Z >= z &&
            !gObj.Inside
          ) {
            newGroundedInOrder.push(gObj);
            groundedInOrder.splice(i, 1);
          }
        }
      }
    }
    groundedInOrder = [...newGroundedInOrder];
    allItems.forEach((it) => {
      let pass = false;
      groundedInOrder.forEach((gr) => {
        if (it.Alias === gr.Alias) {
          pass = true;
        }
      });
      if (!pass) {
        nonGroundedItems.push(it);
      }
    });
    let newOrder = [];
    groundedInOrder.forEach((gr) => {
      let gP1X = Math.round((gr.PosX - gr.Width * 0.5) * 10) / 10;
      let gP1Z = Math.round((gr.PosZ - gr.Length * 0.5) * 10) / 10;
      let gP2X = Math.round((gr.PosX + gr.Width * 0.5) * 10) / 10;
      let gP2Z = Math.round((gr.PosZ + gr.Length * 0.5) * 10) / 10;

      let stacked = [];
      let ignore = [];
      for (
        let x = Math.round(gP1X * 10) / 10;
        x <= Math.round(gP2X * 10) / 10;
        x = Math.round((x + 0.1) * 10) / 10
      ) {
        for (
          let z = Math.round(gP1Z * 10) / 10;
          z <= Math.round(gP2Z * 10) / 10;
          z = Math.round((z + 0.1) * 10) / 10
        ) {
          for (let i = 0; i < nonGroundedItems.length; i = i + 1) {
            let item = nonGroundedItems[i];
            if (
              item.PosX >= gP1X &&
              item.PosX <= gP2X &&
              item.PosZ >= gP1Z &&
              item.PosZ <= gP2Z &&
              !item.Inside
            ) {
              item = { ...item, Ignore: false };
              stacked.push(item);
              nonGroundedItems.splice(i, 1);
            } else if (
              item.PosX >= gP1X &&
              item.PosX <= gP2X &&
              item.PosZ >= gP1Z &&
              item.PosZ <= gP2Z &&
              item.Inside
            ) {
              item = { ...item, Ignore: false };
              ignore.push(item);
              nonGroundedItems.splice(i, 1);
            }
          }
        }
      }

      newOrder.push(gr);
      ignore.reverse();
      ignore.sort((key1, key2) =>
        key1.PosX === key2.PosX &&
        key1.PosZ === key2.PosZ &&
        key1.PosY > key2.PosY
          ? 1
          : -1
      );
      newOrder = newOrder.concat(ignore);
      stacked.sort((key1, key2) => (key1.PosX < key2.PosX ? 1 : -1));
      stacked.sort((key1, key2) => (key1.PosY > key2.PosY ? 1 : -1));

      stacked.forEach((st) => {
        newOrder.push(st);
      });
    });
    momsItems.sort((key1, key2) => (key1.Height > key2.Height ? 1 : -1));
    momsItems.forEach((m) => {
      newOrder.unshift(m);
    });

    for (let i = 0; i < newOrder.length; i = i + 1) {
      let iObj = newOrder[i];
      if (iObj.Mega) {
        newOrder.splice(i, 1);
      }
    }
    let nonPosItems = [];
    things.forEach((moveItem) => {
      let found = false;
      newOrder.forEach((item) => {
        if (item.Alias === moveItem.Alias) {
          found = true;
        }
      });
      if (!found) {
        moveItem = {
          ...moveItem,
          Missing: true,
          Ignore: moveItem.Ignore ? true : undefined,
        };
        nonPosItems.push(moveItem);
      }
    });
    newOrder = newOrder.concat(nonPosItems);
    const floorW = spaceDim.Width;
    const floorL = spaceDim.Length;
    const floorH = 0.1;

    let missingOnes = [];
    newOrder.forEach((item) => {
      if (item.Missing) {
        missingOnes.push(item);
      }
    });
    if (missingOnes.length > 0) {
      // MISSING
      let longestPosX = 0;
      let longestPosZ = 0;
      let longestX = 0;
      let longestZ = 0;
      let totalZ = 0 + missingOnes[0].Length * 0.5;
      let x = floorW + floorW * 0.5;
      for (let m = 0; m < missingOnes.length; m = m + 1) {
        if (m % 3 === 0 && m !== 0) {
          let mm = missingOnes[m - 3].Width;
          x = x + mm + missingOnes[m].Width * 0.5;
          totalZ = 0 + missingOnes[0].Length * 0.5;
        }
        let mObj = missingOnes[m];
        let width = mObj.Width;
        let length = mObj.Length;
        let height = mObj.Height;

        let posX = 0;
        let posY = 0;
        let posZ = 0;

        posX = x;
        posY = height * 0.5;
        posZ = totalZ;

        newOrder.forEach((st, ss) => {
          if (st.Alias === mObj.Alias) {
            let temp = { ...st, PosX: posX, PosY: posY, PosZ: posZ };
            newOrder.splice(ss, 1, temp);
          }
        });

        if (posX > longestPosX) {
          longestPosX = posX;
          longestX = longestX + width;
        }
        if (posZ > longestPosZ) {
          longestPosZ = posZ;
          longestZ = longestZ + length;
        }

        if (m < missingOnes.length - 1) {
          totalZ = totalZ + length * 0.5 + missingOnes[m + 1].Length * 0.5;
        }
      }
    }
    return newOrder;
  };
  ////////////////////////////////////// Middleware
  const organizeMomsItems = (momsDim, momsItems) => {
    let groundedItems = [];
    let groundedNext = false;

    for (let i = 0; i < momsItems.length; i = i + 1) {
      let obj = momsItems[i];
      if (i === 0) {
        let placedGroundedObj = groundSelfOrganizingAlgorithm(
          obj,
          momsDim,
          groundedItems,
          momsDim.PosY + momsDim.Height * 0.5
        );
        if (placedGroundedObj !== undefined) {
          groundedItems.push(placedGroundedObj);
          momsItems[i] = placedGroundedObj;
          obj = placedGroundedObj;
        }
      }
      if (groundedNext && isNaN(obj.PosX)) {
        //
        let placedGroundedObj = groundSelfOrganizingAlgorithm(
          obj,
          momsDim,
          groundedItems,
          momsDim.PosY + momsDim.Height * 0.5
        );

        groundedItems.push(placedGroundedObj);
        momsItems[i] = placedGroundedObj;
        obj = placedGroundedObj;
        groundedNext = false;
      }
      if (obj.PosX) {
        // Determine if others fit on top
        let fits = false;
        let heightLeft =
          Math.round(
            (momsDim.PosY +
              momsDim.Height * 0.5 -
              (obj.PosY + obj.Height * 0.5)) *
              10
          ) / 10;

        for (let a = i + 1; a < momsItems.length; a = a + 1) {
          if (!momsItems[a].PosX) {
            let otherH = momsItems[a].Height;
            if (otherH <= heightLeft) {
              fits = true;
            }
          }
        }
        if (fits) {
          // There is an object in momsItems that fits on top. Run Heuristic

          let nonPosArr = getNonPos(momsItems);

          let newSelfOrganizedItems = selfOrganizingAlgorithm(
            obj,
            nonPosArr,
            momsDim.PosY + momsDim.Height * 0.5
          );
          newSelfOrganizedItems.forEach((item) => {
            if (item.Mega) {
              item.Children.forEach((it) => {
                for (let a = 0; a < momsItems.length; a = a + 1) {
                  let iObj = momsItems[a];
                  if (iObj.Alias === item.FirstChild.Alias) {
                    momsItems[a] = { ...item };
                    let filtered = momsItems.filter(
                      (com) => com.Alias !== iObj.Alias
                    );
                    momsItems = [...filtered];
                  } else if (iObj.Alias === it.Alias) {
                    let filtered = momsItems.filter(
                      (com) => com.Alias !== iObj.Alias
                    );
                    momsItems = [...filtered];
                  }
                }
              });
              momsItems.splice(i + 1, 0, item);
            } else {
              for (let a = 0; a < momsItems.length; a = a + 1) {
                let iObj = momsItems[a];

                if (iObj.Alias === item.Alias) {
                  momsItems[a] = { ...item };
                }
              }
            }
          });
        } else {
          groundedNext = true;
        }

        if (obj.Mega) {
          let tempObj = { ...obj, Placed: true };
          momsDim[i] = { ...tempObj };
          obj = { ...tempObj };
        }
      }
    }

    // Get rid of Megas
    for (let c = 0; c < momsItems.length; c = c + 1) {
      let cObj = momsItems[c];
      if (cObj.Mega) {
        cObj.Children.forEach((child) => {
          momsItems.push(child);
        });
      }
    }

    momsItems = momsItems.filter((mom) => !mom.Mega);
    return momsItems;
  };
  const organizeGroundedItems = (spaceDim, groundedItems) => {
    let placedGrounded = [];
    groundedItems.forEach((gr, g) => {
      if (gr.Lock) {
        placedGrounded.push(gr);
        groundedItems.splice(g, 1);
      }
    });
    let abort = false;
    for (let i = 0; i < groundedItems.length && !abort; i = i + 1) {
      let obj = groundedItems[i];
      if (isNaN(obj.PosX)) {
        let placedGroundedItem = groundSelfOrganizingAlgorithm(
          obj,
          spaceDim,
          placedGrounded,
          spaceDim.Height
        );

        if (placedGroundedItem !== undefined) {
          placedGrounded.push(placedGroundedItem);
          groundedItems[i] = placedGroundedItem;
          obj = placedGroundedItem;
        }
      } else {
        abort = true;
      }
    }
    return placedGrounded;
  };
  const organizeCombinedItems = (spaceDim, combined, grounded) => {
    let gNext = false;
    let rejects = [];
    let groundedItems = [...grounded];
    let skip = false;
    //

    for (let i = 0; i < combined.length; i = i + 1) {
      let obj = combined[i];
      let currentNum = i;
      skip = false;
      if ((obj.Grounded && !obj.PosX) || (gNext && !obj.PosX)) {
        let next = {};
        let index = 0;
        let end = false;

        if (!obj.Grounded) {
          combined.forEach((com, aaa) => {
            if (!com.PosX && !end) {
              next = com;
              index = aaa;
              end = true;
            }
          });
        } else {
          next = obj;
          index = i;
        }
        let newGrounded = groundSelfOrganizingAlgorithm(
          next,
          spaceDim,
          groundedItems,
          spaceDim.Height
        );
        if (newGrounded.PosX) {
          i = -1;
          groundedItems.push(newGrounded);

          combined.splice(index, 1, newGrounded);

          let tempCombined = [...combined];
          let yesPos = [];
          let noPos = getNonPos(tempCombined);
          tempCombined.forEach((te) => {
            if (te.PosX) {
              yesPos.push(te);
            }
          });

          noPos.sort((key1, key2) =>
            key1.Perimeter < key2.Perimeter ? 1 : -1
          );
          tempCombined = yesPos.concat(noPos);

          tempCombined.forEach((te) => {
            combined.forEach((coco, co) => {
              if (coco.Alias === te.Alias) {
                combined.splice(co, 1, te);
              }
            });
          });
          // Mega Box Babyyyy

          let withMegas = groundeMegas(groundedItems, spaceDim);

          withMegas.forEach((gr, ggg) => {
            if (gr.Mega) {
              skip = true;
              gr.Children.forEach((child) => {
                combined.forEach((coco, co) => {
                  if (coco.Alias === child.Alias) {
                    combined.splice(co, 1);
                  }
                });
              });
              combined.forEach((coco, co) => {
                if (coco.Mega) {
                  if (coco.FirstChild.Alias === gr.FirstChild.Alias) {
                    combined.splice(co, 1);
                  }
                }
              });
              let found = false;
              combined.forEach((com) => {
                if (
                  com.Mega &&
                  com.FirstChild.Alias === gr.FirstChild.Alias &&
                  com.Children.length === gr.Children.length
                ) {
                  found = true;
                }
              });
              if (!found) {
                combined.unshift(gr);
              }
            } else {
              let tempCom = [...combined];
              tempCom.forEach((com, cc) => {
                if (gr.Alias === com.Alias) {
                  combined.splice(cc, 1, gr);
                }
              });
            }
          });
          withMegas.forEach((mega, me) => {
            let exists = false;
            if (mega.Mega) {
              groundedItems.forEach((gr) => {
                if (
                  gr.Mega &&
                  gr.FirstChild.Alias === mega.FirstChild.Alias &&
                  gr.Children.length === mega.Children.length
                ) {
                  exists = true;
                }
              });
            }
            if (exists) {
              let tempMega = { ...mega, Resimulate: false, Placed: true };
              withMegas.splice(me, 1, tempMega);
            }
          });

          withMegas.forEach((wmeg) => {
            combined.forEach((coco, co) => {
              if (
                wmeg.Mega &&
                coco.Mega &&
                wmeg.FirstChild.Alias === coco.FirstChild.Alias &&
                !wmeg.Resimulate
              ) {
                combined.splice(co, 1, wmeg);
              }
            });
          });

          withMegas.forEach((mega) => {
            if (mega.Mega) {
              if (mega.Resimulate) {
                let mP1X = Math.round((mega.PosX - mega.Width * 0.5) * 10) / 10;
                let mP2X = Math.round((mega.PosX + mega.Width * 0.5) * 10) / 10;
                let mP1Z =
                  Math.round((mega.PosZ - mega.Length * 0.5) * 10) / 10;
                let mP2Z =
                  Math.round((mega.PosZ + mega.Length * 0.5) * 10) / 10;

                for (let c = 0; c < combined.length; c = c + 1) {
                  let cObj = combined[c];
                  if (
                    cObj.PosY > mega.PosY &&
                    cObj.PosX >= mP1X &&
                    cObj.PosX <= mP2X &&
                    cObj.PosZ >= mP1Z &&
                    cObj.PosZ <= mP2Z
                  ) {
                    let tempCObj = {
                      ...cObj,
                      PosX: undefined,
                      PosY: undefined,
                      PosZ: undefined,
                      Inside: undefined,
                      Grounded: undefined,
                      Placed: undefined,
                    };

                    combined.splice(c, 1, tempCObj);
                  }
                }

                tempCombined = [...combined];

                yesPos = [];
                noPos = getNonPos(tempCombined);
                tempCombined.forEach((te) => {
                  if (te.PosX) {
                    yesPos.push(te);
                  }
                });
                yesPos.sort((key1, key2) => (key2.Placed ? 1 : -1));
                noPos.sort((key1, key2) =>
                  key1.Perimeter < key2.Perimeter ? 1 : -1
                );

                tempCombined = yesPos.concat(noPos);
              }
            }
          });
          groundedItems = [...withMegas];

          gNext = false;
        } else {
          rejects.push(obj);
          combined.forEach((coco, co) => {
            if (coco.Alias === obj.Alias) {
              combined.splice(co, 1);
            }
          });
          skip = true;
          i = i - 1;
        }
      }
      //

      if (obj.PosX && !obj.Ignore && !obj.Placed && !skip) {
        // Fill in space first
        if (obj.Space) {
          let nonPosArr = getNonPos(combined);
          let placedInsideItems = organizeSpaceditems(
            {
              Width: obj.InsideWidth,
              Length: obj.InsideLength,
              Height: obj.InsideHeight,
              PosX: obj.PosX,
              PosY: obj.PosY - obj.Height * 0.5 + obj.InsideHeight * 0.5,
              PosZ: obj.PosZ,
            },
            nonPosArr
          );
          let tempCombined = [...combined];
          placedInsideItems.forEach((pl) => {
            for (let t = 0; t < tempCombined.length; t = t + 1) {
              let tObj = tempCombined[t];
              if (tObj.Alias === pl.Alias) {
                if (isNaN(pl.PosX)) {
                  let newT = {
                    ...pl,
                    PosX: undefined,
                    PosY: undefined,
                    PosZ: undefined,
                  };
                  tempCombined[t] = { ...newT };
                } else {
                  let newT = { ...pl, Ignore: true, Inside: true };
                  tempCombined[t] = { ...newT };
                }
              }
            }
          });
          combined = [...tempCombined];
        }

        // Check if there is room on top for any object in the stack
        let fits = false;
        let heightLeft =
          Math.round((spaceDim.Height - (obj.PosY + obj.Height * 0.5)) * 10) /
          10;

        let noPositionArr = getNonPos([...combined]);

        for (let a = 0; a < noPositionArr.length; a = a + 1) {
          if (!noPositionArr[a].PosX) {
            let otherH = Math.round(noPositionArr[a].Height * 10) / 10;

            if (
              otherH <= heightLeft &&
              noPositionArr[a].Width <= obj.Width &&
              noPositionArr[a].Length <= obj.Length
            ) {
              fits = true;
            }
          }
        }

        // if it does fit
        if (fits) {
          let nonPosArr = getNonPos(combined);

          let newSelfOrganizedItems = selfOrganizingAlgorithm(
            obj,
            nonPosArr,
            spaceDim.Height
          );

          newSelfOrganizedItems.forEach((item) => {
            if (item.Mega) {
              item.Children.forEach((it) => {
                for (let a = 0; a < combined.length; a = a + 1) {
                  let iObj = combined[a];
                  if (iObj.Alias === item.FirstChild.Alias) {
                    combined.splice(a, 1);
                  } else if (iObj.Alias === it.Alias) {
                    combined.splice(a, 1);
                  }
                }
              });

              combined.splice(currentNum + 1, 0, item);
            } else {
              combined.forEach((it, its) => {
                if (it.Alias === item.Alias) {
                  combined.splice(its, 1, item);
                }
              });
            }
          });
        } else {
          gNext = true;
        }
        //

        let temp = { ...obj, Placed: true };
        groundedItems.forEach((groun, gg) => {
          if (groun.Mega && obj.Mega) {
            if (groun.FirstChild.Alias === obj.FirstChild.Alias) {
              groundedItems.splice(gg, 1, temp);
            }
          } else {
            if (groun.Alias === obj.Alias) {
              groundedItems.splice(gg, 1, temp);
            }
          }
        });
        combined.splice(currentNum, 1, temp);

        let tempCombined = [...combined];
        let yesPos = [];
        let noPos = getNonPos(tempCombined);

        tempCombined.forEach((te) => {
          if (te.PosX) {
            yesPos.push(te);
          }
        });
        yesPos.sort((key1, key2) => (key2.Placed ? 1 : -1));

        tempCombined = yesPos.concat(noPos);
        combined = [...tempCombined];
      }
      //
    }
    // Get rid of Megas
    for (let c = 0; c < combined.length; c = c + 1) {
      let cObj = combined[c];
      if (cObj.Mega) {
        cObj.Children.forEach((child) => {
          combined.push(child);
        });
      }
    }

    combined.forEach((coco, co) => {
      if (coco.Inserted) {
        let inserted = { ...coco, Inserted: false, Lock: true };
        combined.splice(co, 1, inserted);
      }
    });

    combined = combined.filter((com) => !com.Mega);

    return combined;
  };
  const organizeSpaceditems = (spaceDim, items) => {
    let maxH = spaceDim.PosY + spaceDim.Height * 0.5;
    let tempItems = [...items];
    let groundedNext = false;
    let groundedItems = [];

    for (let i = 0; i < tempItems.length; i = i + 1) {
      let obj = tempItems[i];

      if (i === 0) {
        let placedGroundedItem = groundSelfOrganizingAlgorithm(
          obj,
          spaceDim,
          groundedItems,
          maxH
        );
        if (isNaN(placedGroundedItem.PosX)) {
          tempItems.splice(i, 1);
          i = i - 1;
        }
      }
      if (groundedNext && !obj.PosX) {
        let placedGroundItem = groundSelfOrganizingAlgorithm(
          obj,
          spaceDim,
          groundedItems,
          maxH
        );
        if (isNaN(placedGroundItem.PosX)) {
          groundedItems.push(placedGroundItem);
          obj = placedGroundItem;
          tempItems[i] = placedGroundItem;
        }
      }
      if (obj.PosX) {
        // Item has position. Try self organize
        // Check if there is room on top for any object in the stack
        let fits = false;
        let heightLeft =
          maxH - Math.round((obj.PosY + obj.Height * 0.5) * 10) / 10;
        for (let a = i + 1; a < tempItems.length; a = a + 1) {
          if (!tempItems[a].PosX) {
            let otherH = Math.round(tempItems[a].Height * 10) / 10;
            if (otherH <= heightLeft) {
              fits = true;
            }
          }
        }

        // if it does fit
        if (fits) {
          let nonPosArr = getNonPos(tempItems);
          let newSelfOrganizedItems = selfOrganizingAlgorithm(
            obj,
            nonPosArr,
            maxH
          );

          newSelfOrganizedItems.forEach((item) => {
            for (let a = 0; a < tempItems.length; a = a + 1) {
              let iObj = tempItems[a];

              if (iObj.Alias === item.Alias) {
                tempItems[a] = { ...item };
              }
            }
          });
        } else {
          groundedNext = true;
        }
      } else {
        // No position.
        let placedGroundedItem = groundSelfOrganizingAlgorithm(
          obj,
          spaceDim,
          groundedItems,
          maxH
        );
        if (placedGroundedItem !== undefined) {
          groundedItems.push(placedGroundedItem);
          tempItems[i] = placedGroundedItem;
          obj = placedGroundedItem;

          // Check if there is room on top for any object in the stack
          let fits = false;
          let heightLeft =
            Math.round((spaceDim.Height - (obj.PosY + obj.Height * 0.5)) * 10) /
            10;
          for (let a = i + 1; a < tempItems.length; a = a + 1) {
            if (!tempItems[a].PosX) {
              let otherH = tempItems[a].Height;
              if (otherH <= heightLeft) {
                fits = true;
              }
            }
          }
          // if it does fit
          if (fits) {
            let nonPosArr = getNonPos(tempItems);
            let newSelfOrganizedItems = selfOrganizingAlgorithm(
              obj,
              nonPosArr,
              maxH
            );
            newSelfOrganizedItems.forEach((item) => {
              for (let a = 0; a < tempItems.length; a = a + 1) {
                let iObj = tempItems[a];

                if (iObj.Alias === item.Alias) {
                  tempItems[a] = { ...item };
                }
              }
            });
          } else {
            groundedNext = true;
          }
        }
      }
    }
    let newGroundedInOrder = [];
    for (
      let x = 0;
      x <= Math.round(spaceDim.Width * 10) / 10;
      x = Math.round((x + 0.1) * 10) / 10
    ) {
      for (
        let z = 0;
        z <= Math.round(spaceDim.Length * 10) / 10;
        z = Math.round((z + 0.1) * 10) / 10
      ) {
        for (let i = 0; i < groundedItems.length; i = i + 1) {
          let gObj = groundedItems[i];
          let gP1X = Math.round((gObj.PosX - gObj.Width * 0.5) * 10) / 10;
          let gP1Z = Math.round((gObj.PosZ - gObj.Length * 0.5) * 10) / 10;
          let gP2X = Math.round((gObj.PosX + gObj.Width * 0.5) * 10) / 10;
          let gP2Z = Math.round((gObj.PosZ + gObj.Length * 0.5) * 10) / 10;

          if (
            gP1X <= x &&
            gP2X >= x &&
            gP1Z <= z &&
            gP2Z >= z &&
            !gObj.Inside
          ) {
            newGroundedInOrder.push(gObj);
            groundedItems.splice(i, 1);
          }
        }
      }
    }
    groundedItems = [...newGroundedInOrder];
    let nonGroundedItems = [];
    tempItems.forEach((item) => {
      if (!item.Grounded) {
        nonGroundedItems.push(item);
      }
    });
    let newOrder = [];
    groundedItems.forEach((gr) => {
      let gP1X = Math.round((gr.PosX - gr.Width * 0.5) * 10) / 10;
      let gP1Z = Math.round((gr.PosZ - gr.Length * 0.5) * 10) / 10;
      let gP2X = Math.round((gr.PosX + gr.Width * 0.5) * 10) / 10;
      let gP2Z = Math.round((gr.PosZ + gr.Length * 0.5) * 10) / 10;

      let stacked = [];

      for (
        let x = Math.round(gP1X * 10) / 10;
        x <= Math.round(gP2X * 10) / 10;
        x = Math.round((x + 0.1) * 10) / 10
      ) {
        for (
          let z = Math.round(gP1Z * 10) / 10;
          z <= Math.round(gP2Z * 10) / 10;
          z = Math.round((z + 0.1) * 10) / 10
        ) {
          for (let i = 0; i < nonGroundedItems.length; i = i + 1) {
            let item = nonGroundedItems[i];
            if (
              item.PosX >= gP1X &&
              item.PosX <= gP2X &&
              item.PosZ >= gP1Z &&
              item.PosZ <= gP2Z
            ) {
              stacked.push(item);
              nonGroundedItems.splice(i, 1);
            }
          }
        }
      }

      newOrder.push(gr);
      stacked.sort((key1, key2) => (key1.PosX < key2.PosX ? 1 : -1));
      stacked.sort((key1, key2) => (key1.PosY > key2.PosY ? 1 : -1));

      stacked.forEach((st) => {
        newOrder.push(st);
      });
    });

    tempItems = [...newOrder];
    return tempItems;
  };
  //
  ////////////////////////////////////// Heuristic Algorithms
  const selfOrganizingAlgorithm = (bottom, items, maxH) => {
    // returns array
    let bP1X = Math.round((bottom.PosX - bottom.Width * 0.5) * 10) / 10;
    let bP1Z = Math.round((bottom.PosZ - bottom.Length * 0.5) * 10) / 10;
    let bP2X = Math.round((bottom.PosX + bottom.Width * 0.5) * 10) / 10;
    let bP2Z = Math.round((bottom.PosZ + bottom.Length * 0.5) * 10) / 10;

    let placedItems = [];
    for (let i = 0; i < items.length; i = i + 1) {
      let abort = false;
      let obj = items[i];

      let oWidth = obj.Width;
      let oLength = obj.Length;
      let flipoWidth = obj.Length;
      let flipoLength = obj.Width;

      let oP1X = 0;
      let oP1Z = 0;
      let oP2X = 0;
      let oP2Z = 0;

      for (
        let x = bP1X;
        x <= Math.round(bP2X * 10) / 10 && !abort;
        x = Math.round((x + 0.1) * 10) / 10
      ) {
        for (
          let z = bP1Z;
          z <= Math.round(bP2Z * 10) / 10 && !abort;
          z = Math.round((z + 0.1) * 10) / 10
        ) {
          oP1X = Math.round(x * 10) / 10;
          oP1Z = Math.round(z * 10) / 10;
          oP2X = Math.round((x + obj.Width) * 10) / 10;
          oP2Z = Math.round((z + obj.Length) * 10) / 10;

          let place = true;

          for (let a = 0; a < placedItems.length; a = a + 1) {
            let pObj = placedItems[a];

            let pP1X = Math.round(pObj.P1X * 10) / 10;
            let pP1Z = Math.round(pObj.P1Z * 10) / 10;
            let pP2X = Math.round(pObj.P2X * 10) / 10;
            let pP2Z = Math.round(pObj.P2Z * 10) / 10;

            if (oP2X > pP1X && oP1X < pP2X && oP2Z > pP1Z && oP1Z < pP2Z) {
              oP2X = Math.round((x + flipoWidth) * 10) / 10;
              oP2Z = Math.round((z + flipoLength) * 10) / 10;
              oWidth = Math.round(flipoWidth * 10) / 10;
              oLength = Math.round(flipoLength * 10) / 10;
              place = false;

              if (oP2X > pP1X && oP1X < pP2X && oP2Z > pP1Z && oP1Z < pP2Z) {
                oP2X = Math.round((x + obj.Width) * 10) / 10;
                oP2Z = Math.round((z + obj.Length) * 10) / 10;
                oWidth = Math.round(obj.Width * 10) / 10;
                oLength = Math.round(obj.Length * 10) / 10;
                place = false;
              }
            }
          }

          if (
            place &&
            oP2X <= bP2X &&
            oP2Z <= bP2Z &&
            oP1X >= bP1X &&
            oP1Z >= bP1Z &&
            Math.floor(bottom.PosY * 10) / 10 +
              Math.floor(bottom.Height * 0.5 * 10) / 10 +
              Math.floor(obj.Height * 10) / 10 <=
              Math.floor(maxH * 10) / 10
          ) {
            let temp = {
              Alias: obj.Alias,
              Width: oWidth,
              Length: oLength,
              P1X: oP1X,
              P1Z: oP1Z,
              P2X: oP2X,
              P2Z: oP2Z,
            };

            placedItems.push(temp);
            abort = true;
          }
        }
      }
    }

    let tempItems = [...items];
    placedItems.forEach((pl) => {
      for (let i = 0; i < tempItems.length; i = i + 1) {
        let iObj = tempItems[i];
        if (iObj.Alias === pl.Alias) {
          iObj = {
            ...iObj,
            Width: pl.Width,
            Length: pl.Length,
            PosX: pl.P1X + pl.Width * 0.5,
            PosY: bottom.PosY + bottom.Height * 0.5 + iObj.Height * 0.5,
            PosZ: pl.P1Z + pl.Length * 0.5,
          };

          iObj = { ...iObj };
          tempItems[i] = { ...iObj };
        }
      }
    });

    placedItems = [];
    tempItems.forEach((t) => {
      if (t.PosX) {
        placedItems.push(t);
      }
    });

    // let arrayOfMega = [];
    // if (placedItems.length > 1) {
    //   // From left to right
    //   let sameHeights = [];

    //   placedItems.forEach((pl) => {
    //     let tempArr = [];
    //     placedItems.forEach((pl2) => {
    //       if (pl.Height === pl2.Height) {
    //         tempArr.push(pl2);
    //       }
    //     });
    //     sameHeights.push(tempArr);
    //   });
    //   sameHeights = _.uniqWith(sameHeights, _.isEqual);

    //   // Go through each array and get the furthest X
    //   sameHeights.forEach((same) => {
    //     if (same.length > 1) {
    //       let shortestP1X;
    //       let shortestP1Z;
    //       let longestP2X;
    //       let longestP2Z;
    //       let totalW = 0;
    //       let totalL = 0;

    //       let tempArr = [];
    //       same.sort((key1, key2) => (key1.PosX > key2.PosX ? 1 : -1));

    //       same.forEach((box, i) => {
    //         let P1X = Math.round((box.PosX - box.Width * 0.5) * 10) / 10;
    //         let P1Z = Math.round((box.PosZ - box.Length * 0.5) * 10) / 10;
    //         let P2X = Math.round((box.PosX + box.Width * 0.5) * 10) / 10;
    //         let P2Z = Math.round((box.PosZ + box.Length * 0.5) * 10) / 10;
    //         totalW = totalW + box.Width;
    //         totalL = totalL + box.Length;
    //         if (i === 0) {
    //           shortestP1X = P1X;
    //           shortestP1Z = P1Z;
    //           longestP2X = P2X;
    //           longestP2Z = P2Z;
    //         }

    //         if (
    //           P2X <= longestP2X + box.Width &&
    //           P2Z <= longestP2Z + box.Length
    //         ) {
    //           if (P2X > longestP2X) {
    //             longestP2X = P2X;
    //           }
    //           if (P2Z > longestP2Z) {
    //             longestP2Z = P2Z;
    //           }
    //         } else {
    //           same.splice(i, 1);
    //         }
    //       });
    //       let totalX = longestP2X - shortestP1X;
    //       let totalZ = longestP2Z - shortestP1Z;

    //       // Check if there are any holes
    //       let finalX = 0;
    //       let finalZ = 0;
    //       let abort = false;
    //       for (
    //         let x = Math.round(shortestP1X * 10) / 10;
    //         x <= Math.round(longestP2X * 10) / 10 && !abort;
    //         x = Math.round((x + 0.1) * 10) / 10
    //       ) {
    //         for (
    //           let z = Math.round(shortestP1Z * 10) / 10;
    //           z <= Math.round(longestP2Z * 10) / 10 && !abort;
    //           z = Math.round((z + 0.1) * 10) / 10
    //         ) {
    //           let contains = false;
    //           same.forEach((box) => {
    //             let P1X = Math.round((box.PosX - box.Width * 0.5) * 10) / 10;
    //             let P1Z = Math.round((box.PosZ - box.Length * 0.5) * 10) / 10;
    //             let P2X = Math.round((box.PosX + box.Width * 0.5) * 10) / 10;
    //             let P2Z = Math.round((box.PosZ + box.Length * 0.5) * 10) / 10;

    //             if (x >= P1X && x <= P2X && z >= P1Z && z <= P2Z) {
    //               contains = true;
    //               finalX = P2X;
    //               finalZ = P2Z;
    //             }
    //           });
    //           if (!contains) {
    //             // This means that it did not find a box

    //             abort = true;
    //           }
    //         }
    //       }

    //       // These are the boxes that are indeed inside
    //       let newSame = [];
    //       same.forEach((box, i) => {
    //         let P2X = box.PosX + box.Width * 0.5;
    //         let P2Z = box.PosZ + box.Length * 0.5;
    //         if (P2X <= finalX && P2Z <= finalZ) {
    //           newSame.push(box);
    //         } else {
    //           same.splice(i, 1);
    //         }
    //       });

    //       same = [...newSame];

    //       for (let i = 0; i < same.length; i = i + 1) {
    //         let box = same[i];
    //         if (
    //           box.PosX >= shortestP1X &&
    //           box.PosX <= longestP2X &&
    //           box.PosZ >= shortestP1Z &&
    //           box.PosZ <= longestP2Z
    //         ) {
    //           tempArr.push(box);
    //         }
    //       }

    //       if (tempArr.length > 1) {
    //         let megaBox = {
    //           Alias: `Mega${RandomString()}`,
    //           Mega: true,
    //           Width: totalX,
    //           Height: Math.round(same[0].Height * 10) / 10,
    //           Length: totalZ,
    //           PosX:
    //             Math.round(
    //               (same[0].PosX - same[0].Width * 0.5 + totalX * 0.5) * 10
    //             ) / 10,
    //           PosY: bottom.PosY + bottom.Height * 0.5 + same[0].Height * 0.5,
    //           PosZ:
    //             Math.round(
    //               (same[0].PosZ - same[0].Length * 0.5 + totalZ * 0.5) * 10
    //             ) / 10,
    //           Children: [...same],
    //           FirstChild: same[0],
    //         };
    //         arrayOfMega.push(megaBox);
    //       }
    //     }
    //   });
    // }

    // arrayOfMega.forEach((mega) => {
    //   mega.Children.forEach((child) => {
    //     for (let p = 0; p < placedItems.length; p = p + 1) {
    //       let pObj = placedItems[p];
    //       if (pObj.Alias === mega.FirstChild.Alias) {
    //         placedItems[p] = mega;
    //         let filtered = placedItems.filter((pp) => pp.Alias !== pObj.Alias);
    //         placedItems = [...filtered];
    //       } else if (pObj.Alias === child.Alias) {
    //         let filtered = placedItems.filter((pp) => pp.Alias !== pObj.Alias);
    //         placedItems = [...filtered];
    //       }
    //     }
    //   });
    // });

    return placedItems;
  };
  const groundSelfOrganizingAlgorithm = (
    obj,
    spaceDim,
    groundedItems,
    maxH
  ) => {
    let tempObj = {};
    // returns object
    let sP1X = Math.round((spaceDim.PosX - spaceDim.Width * 0.5) * 10) / 10;
    let sP1Z = Math.round((spaceDim.PosZ - spaceDim.Length * 0.5) * 10) / 10;
    let sP2X = Math.round((spaceDim.PosX + spaceDim.Width * 0.5) * 10) / 10;
    let sP2Z = Math.round((spaceDim.PosZ + spaceDim.Length * 0.5) * 10) / 10;

    let oP1X = 0;
    let oP1Z = 0;
    let oP2X = 0;
    let oP2Z = 0;

    let oWidth = Math.round(obj.Width * 10) / 10;
    let oLength = Math.round(obj.Length * 10) / 10;
    const flipoWidth = Math.round(obj.Length * 10) / 10;
    const flipoLength = Math.round(obj.Width * 10) / 10;

    let abort = false;

    for (
      let x = sP1X;
      x <= Math.round(sP2X * 10) / 10 && !abort;
      x = Math.round((x + 0.1) * 10) / 10
    ) {
      for (
        let z = sP1Z;
        z <= Math.round(sP2Z * 10) / 10 && !abort;
        z = Math.round((z + 0.1) * 10) / 10
      ) {
        oP1X = Math.round(x * 10) / 10;
        oP1Z = Math.round(z * 10) / 10;
        oP2X = Math.round((x + obj.Width) * 10) / 10;
        oP2Z = Math.round((z + obj.Length) * 10) / 10;

        let place = true;

        for (let g = 0; g < groundedItems.length; g = g + 1) {
          let gObj = groundedItems[g];

          let gP1X = Math.round((gObj.PosX - gObj.Width * 0.5) * 10) / 10;
          let gP1Z = Math.round((gObj.PosZ - gObj.Length * 0.5) * 10) / 10;
          let gP2X = Math.round((gObj.PosX + gObj.Width * 0.5) * 10) / 10;
          let gP2Z = Math.round((gObj.PosZ + gObj.Length * 0.5) * 10) / 10;

          if (
            (oP2X > gP1X && oP1X < gP2X && oP2Z > gP1Z && oP1Z < gP2Z) ||
            oP2X > sP2X ||
            oP2Z > sP2Z
          ) {
            // Collision
            oP2X = Math.round((x + flipoWidth) * 10) / 10;
            oP2Z = Math.round((z + flipoLength) * 10) / 10;
            oWidth = Math.round(flipoWidth * 10) / 10;
            oLength = Math.round(flipoLength * 10) / 10;

            place = false;
            // Try Flipped
            if (
              (oP2X > gP1X && oP1X < gP2X && oP2Z > gP1Z && oP1Z < gP2Z) ||
              oP2X > sP2X ||
              oP2Z > sP2Z
            ) {
              // Collision
              oP2X = Math.round((x + obj.Width) * 10) / 10;
              oP2Z = Math.round((z + obj.Length) * 10) / 10;
              oWidth = Math.round(obj.Width * 10) / 10;
              oLength = Math.round(obj.Length * 10) / 10;

              place = false;
            }
          }
        }

        if (place && oP2X <= sP2X && oP2Z <= sP2Z) {
          if (
            (spaceDim.Mom &&
              Math.round(
                (spaceDim.PosY - spaceDim.Height * 0.5 + obj.Height) * 10
              ) /
                10 <=
                maxH) ||
            (!spaceDim.Mom && 0 + obj.Height <= maxH)
          ) {
            tempObj = {
              Alias: obj.Alias,
              Width: oWidth,
              Length: oLength,
              P1X: oP1X,
              P1Z: oP1Z,
              P2X: oP2X,
              P2Z: oP2Z,
            };

            abort = true;
          }
        }
      }
    }

    let placedItem = {
      ...obj,
      Grounded: !isNaN(tempObj.P1X) ? true : false,
      PosX: Math.round((tempObj.P1X + tempObj.Width * 0.5) * 10) / 10,
      PosY: spaceDim.Mom
        ? Math.round(
            (spaceDim.PosY - spaceDim.Height * 0.5 + obj.Height * 0.5) * 10
          ) / 10
        : Math.round((0 + obj.Height * 0.5) * 10) / 10,
      PosZ: Math.round((tempObj.P1Z + tempObj.Length * 0.5) * 10) / 10,
    };

    return placedItem;
  };
  const getNonPos = (items) => {
    let tempItems = [...items];
    let temp = [];
    let inserted = {};
    tempItems.forEach((it, i) => {
      if (it.Inserted) {
        inserted = { ...it };
      }
    });
    tempItems.forEach((i) => {
      if (!i.PosX && !i.Mega && !i.Placed && !i.Inserted) {
        temp.push(i);
      }
    });

    temp.sort((key1, key2) => (key1.Perimeter < key2.Perimeter ? 1 : -1));
    if (inserted.Inserted && !inserted.PosX) {
      temp.unshift(inserted);
    }
    return temp;
  };
  const groundeMegas = (grounded, spaceDim) => {
    // Recieve a list of items and figure out how many mega boxes can be made

    let tempGrounded = [...grounded];
    let tempMegas = [];
    tempGrounded.forEach((box, i) => {
      if (box.Mega) {
        tempMegas.push(box);
        let children = box.Children;
        tempGrounded = tempGrounded.concat(children);
      }
    });
    let filtered = tempGrounded.filter((temp) => !temp.Mega);
    filtered.sort((key1, key2) =>
      key1.PosX > key2.PosX || key1.PosZ > key2.PosZ ? 1 : -1
    );
    grounded = [...filtered];

    let sameHeights = [];
    for (let g = 0; g < grounded.length; g = g + 1) {
      let gObj = grounded[g];
      let tempArr = [];
      grounded.forEach((gr) => {
        if (gr.PosY === gObj.PosY && !gObj.Mega) {
          tempArr.push(gr);
        }
      });
      sameHeights.push(tempArr);
    }
    sameHeights = _.uniqWith(sameHeights, _.isEqual);

    // Go through all same heights arrays
    let megaArr = [];
    //
    sameHeights.forEach((sameArr, sharon) => {
      sameArr.sort((key1, key2) => (key1.PosX > key2.PosX ? 1 : -1));

      if (sameArr.length > 1) {
        let shortestX = 0;
        let shortestZ = 0;
        let longestX = [];
        let longestZ = [];
        // collect the bounds
        sameArr.forEach((same, i) => {
          let sP1X = Math.round((same.PosX - same.Width * 0.5) * 10) / 10;
          let sP1Z = Math.round((same.PosZ - same.Length * 0.5) * 10) / 10;
          let sP2X = Math.round((same.PosX + same.Width * 0.5) * 10) / 10;
          let sP2Z = Math.round((same.PosZ + same.Length * 0.5) * 10) / 10;

          if (i === 0) {
            shortestX = sP1X;
            shortestZ = sP1Z;
            longestX.push(sP2X);
            longestZ.push(sP2Z);
          } else {
            if (sP2X > longestX[longestX.length - 1]) {
              longestX.push(sP2X);
            }
            if (sP2Z > longestZ[longestZ.length - 1]) {
              longestZ.push(sP2Z);
            }
          }
        });
        // Begin process of checking for gaps on edges
        let complete = false;
        let num = 0;
        while (!complete && num < sameArr.length) {
          num = num + 1;
          let onTheRightSide = [];
          let onTheBottomSide = [];
          let xLength = longestX.length;
          let zLength = longestZ.length;
          // Gather items on right side
          sameArr.forEach((same) => {
            let P2X = Math.round((same.PosX + same.Width * 0.5) * 10) / 10;

            // Store items on the right edge using the P2X
            if (P2X === longestX[xLength - 1]) {
              onTheRightSide.push(same);
            }
          });
          // Gather items on bottom side
          sameArr.forEach((same) => {
            let P2Z = Math.round((same.PosZ + same.Length * 0.5) * 10) / 10;
            // Store items on the right edge using the P2X
            if (P2Z === longestZ[zLength - 1]) {
              onTheBottomSide.push(same);
            }
          });

          // Totals
          let totalRight = 0;
          let totalBottom = 0;

          onTheRightSide.forEach((right) => {
            totalRight = Math.round((totalRight + right.Length) * 10) / 10;
          });
          onTheBottomSide.forEach((bottom) => {
            totalBottom = Math.round((totalBottom + bottom.Width) * 10) / 10;
          });

          // At this point, the lengths and widths have been summed up. Now its time to check if they amount to the total width and length of the mega box; checking for holes.
          let totalW =
            Math.round((longestX[xLength - 1] - shortestX) * 10) / 10;
          let totalL =
            Math.round((longestZ[zLength - 1] - shortestZ) * 10) / 10;

          // This is what happens if there are gaps on the right side.
          let goodOnRight = false;
          let goodOnBottom = false;

          if (totalRight !== totalL) {
            // There is a gap
            // Remove from Longest X
            longestX.splice(xLength - 1, 1);
          } else {
            // No gap, proceed
            goodOnRight = true;
          }
          // This is what happens if there are gaps on the bottom side.
          if (totalBottom !== totalW) {
            // There is a gap
            // Remove from Longest Z
            longestZ.splice(zLength - 1, 1);
          } else {
            // No gap, proceed
            goodOnBottom = true;
          }
          // Check
          if (goodOnRight && goodOnBottom) {
            // We are good boys!
            let objsForMega = [];
            let width = longestX[xLength - 1] - shortestX;
            let length = longestZ[zLength - 1] - shortestZ;
            sameArr.forEach((box, ash) => {
              if (
                Math.round((box.PosX + box.Width * 0.5) * 10) / 10 <=
                  longestX[xLength - 1] &&
                Math.round((box.PosZ + box.Length * 0.5) * 10) / 10 <=
                  longestZ[zLength - 1]
              ) {
                // There are within the bounds
                objsForMega.push(box);
              }
            });

            if (objsForMega.length > 1) {
              let mega = {
                Children: [...objsForMega],
                FirstChild: objsForMega[0],
                Width: width,
                Length: length,
                Height: objsForMega[0].Height,
                PosX: Math.round((shortestX + width * 0.5) * 10) / 10,
                PosY: objsForMega[0].PosY,
                PosZ: Math.round((shortestZ + length * 0.5) * 10) / 10,
                Mega: true,
                Grounded: true,
                Placed: false,
                Resimulate: true,
              };
              megaArr.push(mega);
            } else {
              tempMegas.forEach((meg) => {
                if (meg.PosY === sameArr[0].PosY) {
                  megaArr.push(meg);
                }
              });
            }
            complete = true;
          }
          if (longestX.length === 0 && longestZ.length === 0) {
            complete = true;
          }
        }
      }
    });

    //
    let placedItems = [...grounded];

    // if there isnt a mega with the missing in it, then force it.
    let found = false;
    let notFound = [];
    tempMegas.forEach((tempmeg) => {
      megaArr.forEach((meg) => {
        if (tempmeg.FirstChild.Alias === meg.FirstChild.Alias) {
          found = true;
        }
      });
      if (!found) {
        notFound.push(tempmeg);
      }
      found = false;
    });
    notFound.forEach((not) => {
      megaArr.push(not);
    });
    //
    megaArr.forEach((mega) => {
      mega.Children.forEach((child) => {
        placedItems.forEach((pl, ee) => {
          if (child.Alias === pl.Alias) {
            placedItems.splice(ee, 1);
          }
        });
      });
    });
    megaArr.forEach((mega) => {
      placedItems.push(mega);
    });

    return placedItems;
  };
  // /////////

  // CHANGE
  const upStack = () => {
    let num = objNum;
    if (num === allStacks.length) {
      num = 1;
    } else {
      num = num + 1;
    }
    dispatch(storeStackedObjectsAction(stacks));
    dispatch(storeObjectNumAction(num));
  };
  const downStack = () => {
    let num = objNum;
    if (num === 1) {
      num = allStacks.length;
    } else {
      num = num - 1;
    }
    dispatch(storeStackedObjectsAction(stacks));
    dispatch(storeObjectNumAction(num));
  };
  const onSearch = () => {
    const search = document.querySelector("#tbSearch").value.toLowerCase();
    let matches = [];
    allStacks.forEach((item) => {
      let itemAlias = item.Alias.toLowerCase();
      let itemContents = item.Contents.toLowerCase();
      if (itemAlias.includes(search) || itemContents.includes(search)) {
        matches.push(item);
      }
    });
    dispatch(storeSearchResultsAction(matches));
    if (!toggleSearch) {
      dispatch(toggleSearchResultsAction(true));
    }
    if (search === "") {
      dispatch(toggleSearchResultsAction(false));
    }
  };
  const onItemChoose = () => {
    let e = document.getElementById("selChoose");
    let choice = e.options[e.selectedIndex].text;
    choice = parseInt(choice);
    dispatch(storeObjectNumAction(choice));
  };

  // CLICK
  const finishSim = () => {
    // Store the updated item group back into the DB
    itemGroup.Items.forEach((item) => {
      users_Collection
        .doc(userAuthID.id)
        .collection("Loads")
        .doc(itemGroup.id)
        .collection("Items")
        .doc(item.id)
        .update({
          Grounded: item.Grounded
            ? item.Grounded
            : firebase.firestore.FieldValue.delete(),
          Width: item.Width,
          Length: item.Length,
          Height: item.Height,
          Ignore: item.Lock
            ? false
            : item.Ignore
            ? item.Ignore
            : firebase.firestore.FieldValue.delete(),
          Placed:
            item.Lock && item.PosX
              ? item.Placed
              : firebase.firestore.FieldValue.delete(),
          Lock:
            item.Lock && item.PosX
              ? true
              : firebase.firestore.FieldValue.delete(),
          PosX:
            item.Lock && item.PosX
              ? item.PosX
              : firebase.firestore.FieldValue.delete(),
          PosY:
            item.Lock && item.PosX
              ? item.PosY
              : firebase.firestore.FieldValue.delete(),
          PosZ:
            item.Lock && item.PosX
              ? item.PosZ
              : firebase.firestore.FieldValue.delete(),
        })
        .catch((err) => console.log(err));
    });
    //
    dispatch(storeSearchResultsAction([]));
    dispatch(storeFullLoadAction({}));
    dispatch(toggleSearchResultsAction(false));
    dispatch(storeAllStacksAction([]));
    dispatch(storeLastObjectAction({}));
    dispatch(storeObjectNumAction(0));
    dispatch(storeStackedObjectsAction([]));
    dispatch(storeStorageDimensionsAction({}));
    dispatch(storeFullLoadAction({}));
    dispatch(storeAllStacksAction([]));
    dispatch(storeLastObjectAction({}));
    dispatch(storeObjectNumAction(0));
    dispatch(storeStackedObjectsAction([]));
    dispatch(storeStorageDimensionsAction({}));
    dispatch(storeMissingObjectsAction(false));
    dispatch(storeMomStorageDimAction({}));
    dispatch(storeMomStorageObjectsAction([]));
    history.push("/dashboard");
  };
  const saveSim = () => {
    itemGroup.Items.forEach((item) => {
      users_Collection
        .doc(userAuthID.id)
        .collection("Loads")
        .doc(itemGroup.id)
        .collection("Items")
        .doc(item.id)
        .update({
          Grounded: item.Grounded
            ? item.Grounded
            : firebase.firestore.FieldValue.delete(),
          Width: item.Width,
          Length: item.Length,
          Height: item.Height,
          Ignore: item.Lock
            ? false
            : item.Ignore
            ? item.Ignore
            : firebase.firestore.FieldValue.delete(),
          Placed:
            item.Lock && item.PosX
              ? item.Placed
              : firebase.firestore.FieldValue.delete(),
          Lock:
            item.Lock && item.PosX
              ? true
              : firebase.firestore.FieldValue.delete(),
          PosX:
            item.Lock && item.PosX
              ? item.PosX
              : firebase.firestore.FieldValue.delete(),
          PosY:
            item.Lock && item.PosX
              ? item.PosY
              : firebase.firestore.FieldValue.delete(),
          PosZ:
            item.Lock && item.PosX
              ? item.PosZ
              : firebase.firestore.FieldValue.delete(),
        })
        .catch((err) => console.log(err));
    });
  };
  const resetSim = () => {
    dispatch(storeStackedObjectsAction(allStacks));
    dispatch(storeObjectNumAction(allStacks.length));
  };
  const onSearchClick = (event) => {
    const itemAlias = event.currentTarget.getAttribute("id");
    let item = {};
    let num = 0;
    allStacks.forEach((it, i) => {
      if (it.Alias === itemAlias) {
        item = { ...it };
        num = i + 1;
      }
    });
    document.querySelector("#tbSearch").value = "";
    dispatch(storeLastObjectAction(item));
    dispatch(storeStackedObjectsAction(stacks));
    dispatch(storeObjectNumAction(num));
    dispatch(toggleSearchResultsAction(false));
  };
  const onStandUpLayDown = () => {
    let tempObj = { ...lastObject };
    let width = tempObj.Width;
    let length = tempObj.Length;
    let height = tempObj.Height;

    let locked = onLockItems();

    itemGroup.Items.forEach((ite) => {
      if (ite.Name === tempObj.Alias) {
        tempObj = { ...ite };
      }
    });

    if (width > length) {
      if (!tempObj.Space) {
        tempObj = {
          ...tempObj,
          Length: `${Math.round((height / 12) * 10) / 10}`,
          Height: `${Math.round((width / 12) * 10) / 10}`,
          Width: `${Math.round((length / 12) * 10) / 10}`,
          Inserted: true,
          Lock: false,
          PosX: undefined,
          PosY: undefined,
          PosZ: undefined,
        };
      } else {
        tempObj = {
          ...tempObj,
          Length: `${Math.round((height / 12) * 10) / 10}`,
          Height: `${Math.round((width / 12) * 10) / 10}`,
          Width: `${Math.round((length / 12) * 10) / 10}`,
          Inserted: true,
          Lock: false,
          PosX: undefined,
          PosY: undefined,
          PosZ: undefined,
          Space: true,
          InsideLength: `${Math.round((tempObj.InsideHeight / 12) * 10) / 10}`,
          InsideHeight: `${Math.round((tempObj.InsideWidth / 12) * 10) / 10}`,
          InsideWidth: `${Math.round((tempObj.InsideLength / 12) * 10) / 10}`,
        };
      }
    } else {
      if (!tempObj.Space) {
        tempObj = {
          ...tempObj,
          Length: `${Math.round((width / 12) * 10) / 10}`,
          Height: `${Math.round((length / 12) * 10) / 10}`,
          Width: `${Math.round((height / 12) * 10) / 10}`,
          Inserted: true,
          Lock: false,
          PosX: undefined,
          PosY: undefined,
          PosZ: undefined,
        };
      } else {
        tempObj = {
          ...tempObj,
          Length: `${Math.round((width / 12) * 10) / 10}`,
          Height: `${Math.round((length / 12) * 10) / 10}`,
          Width: `${Math.round((height / 12) * 10) / 10}`,
          Inserted: true,
          Lock: false,
          PosX: undefined,
          PosY: undefined,
          PosZ: undefined,
          Space: true,
          InsideLength: `${Math.round((tempObj.InsideWidth / 12) * 10) / 10}`,
          InsideHeight: `${Math.round((tempObj.InsideLength / 12) * 10) / 10}`,
          InsideWidth: `${Math.round((tempObj.InsideHeight / 12) * 10) / 10}`,
        };
      }
    }
    // ReSimulate and dispatch

    let tempArr = [...locked.Items];

    tempArr.forEach((item, i) => {
      if (item.Name === tempObj.Name) {
        tempArr.splice(i, 1, tempObj);
      }
    });
    let savedItemGroup = { ...locked, Items: tempArr };
    let allItems = runSimulationVersion5Only(tempArr, storageDim, momsAttic);

    if (allItems.length !== itemGroup.Items.length) {
      alert("Your changes could not be made.");
    } else {
      let last = 0;
      allItems.forEach((item, iii) => {
        if (item.Alias === tempObj.Name) {
          last = iii;
        }
      });

      dispatch(storeFullLoadAction(savedItemGroup));

      dispatch(storeAllStacksAction(allItems));
      dispatch(storeLastObjectAction(allItems[last]));
      dispatch(storeObjectNumAction(last + 1));
      dispatch(storeStackedObjectsAction(allItems));
      dispatch(storeStorageDimensionsAction(storageDim));
    }
  };
  const onFlipLengthWidth = () => {
    let tempObj = { ...lastObject };
    let width = tempObj.Width;
    let length = tempObj.Length;
    let height = tempObj.Height;
    let locked = onLockItems();
    itemGroup.Items.forEach((ite) => {
      if (ite.Name === tempObj.Alias) {
        tempObj = { ...ite };
      }
    });
    if (!tempObj.Space) {
      tempObj = {
        ...tempObj,
        Length: `${Math.round((length / 12) * 10) / 10}`,
        Height: `${Math.round((height / 12) * 10) / 10}`,
        Width: `${Math.round((width / 12) * 10) / 10}`,
        Inserted: true,
        Lock: false,
        PosX: undefined,
        PosY: undefined,
        PosZ: undefined,
      };
    } else {
      tempObj = {
        ...tempObj,
        Length: `${Math.round((length / 12) * 10) / 10}`,
        Height: `${Math.round((height / 12) * 10) / 10}`,
        Width: `${Math.round((width / 12) * 10) / 10}`,
        Inserted: true,
        Lock: false,
        PosX: undefined,
        PosY: undefined,
        PosZ: undefined,
        Space: true,
        InsideLength: `${Math.round((tempObj.InsideLength / 12) * 10) / 10}`,
        InsideHeight: `${Math.round((tempObj.InsideHeight / 12) * 10) / 10}`,
        InsideWidth: `${Math.round((tempObj.InsideWidth / 12) * 10) / 10}`,
      };
    }

    // ReSimulate and dispatch
    let tempArr = [...locked.Items];
    tempArr.forEach((item, i) => {
      if (item.Name === tempObj.Name) {
        tempArr.splice(i, 1, tempObj);
      }
    });
    let savedItemGroup = { ...locked, Items: tempArr };

    let allItems = runSimulationVersion5Only(tempArr, storageDim, momsAttic);
    if (allItems.length !== itemGroup.Items.length) {
      alert("Your changes could not be made.");
    } else {
      let last = 0;
      allItems.forEach((item, iii) => {
        if (item.Alias === tempObj.Name) {
          last = iii;
        }
      });

      dispatch(storeFullLoadAction(savedItemGroup));

      dispatch(storeAllStacksAction(allItems));
      dispatch(storeLastObjectAction(allItems[last]));
      dispatch(storeObjectNumAction(last + 1));
      dispatch(storeStackedObjectsAction(allItems));
      dispatch(storeStorageDimensionsAction(storageDim));
    }
  };
  const onPutBackTakeOut = (event) => {
    let text = event.currentTarget.getAttribute("value");
    let temp = { ...lastObject };
    let itemG = onLockItems();
    let grounded;

    itemG.Items.forEach((ite) => {
      if (ite.Name === temp.Alias) {
        temp = { ...ite };
      }
    });

    itemGroup.Items.forEach((ite) => {
      if (ite.Name === temp.Name) {
        grounded = ite.Grounded;
      }
    });

    if (text === "put") {
      temp = {
        ...temp,
        Width: `${Math.round((lastObject.Length / 12) * 10) / 10}`,
        Length: `${Math.round((lastObject.Width / 12) * 10) / 10}`,
        Height: `${Math.round((lastObject.Height / 12) * 10) / 10}`,
        PosX: undefined,
        PosY: undefined,
        PosZ: undefined,
        Ignore: false,
        Lock: false,
        Grounded: grounded,
        Placed: false,
      };
    } else {
      temp = {
        ...temp,
        Width: `${Math.round((lastObject.Length / 12) * 10) / 10}`,
        Length: `${Math.round((lastObject.Width / 12) * 10) / 10}`,
        Height: `${Math.round((lastObject.Height / 12) * 10) / 10}`,
        PosX: undefined,
        PosY: undefined,
        PosZ: undefined,
        Ignore: true,
        Lock: false,
        Placed: false,
        Grounded: grounded,
      };
    }

    let tempArr = [...itemG.Items];

    let temptempArr = [];
    allStacks.forEach((st, i) => {
      tempArr.forEach((te, t) => {
        if (te.Name === st.Alias) {
          temptempArr.push(te);
        }
      });
    });

    tempArr = [...temptempArr];
    tempArr.forEach((item, i) => {
      if (item.Name === temp.Name) {
        tempArr.splice(i, 1, temp);
      }
    });
    let savedItemGroup = { ...itemG, Items: tempArr };

    let allItems = runSimulationVersion5Only(tempArr, storageDim, momsAttic);

    if (allItems.length !== itemG.Items.length) {
      alert("Your changes could not be made.");
    }
    let last = allItems.length - 1;
    allItems.forEach((item, iii) => {
      if (item.Alias === temp.Name) {
        last = iii;
      }
    });

    dispatch(storeFullLoadAction(savedItemGroup));

    dispatch(storeAllStacksAction(allItems));
    dispatch(storeLastObjectAction(allItems[last]));
    dispatch(storeObjectNumAction(last + 1));
    dispatch(storeStackedObjectsAction(allItems));
    dispatch(storeStorageDimensionsAction(storageDim));
  };
  const onLockItems = () => {
    let tempItemGroup = { ...itemGroup };
    let tempAllItems = [...allStacks];
    let items = [...tempItemGroup.Items];
    let lastNum = objNum;
    let tempItemsArr = [];

    let temptempArr = [];
    tempAllItems.forEach((te) => {
      items.forEach((it) => {
        if (it.Name === te.Alias) {
          temptempArr.push(it);
        }
      });
    });
    items = [...temptempArr];
    for (let i = 0; i < tempAllItems.length; i = i + 1) {
      let tempItem = tempAllItems[i];

      items.forEach((item, t) => {
        if (item.Name === tempItem.Alias) {
          let temp = {
            ...item,
            Lock: false,
            Placed: true,
            Grounded: tempItem.Grounded,
            PosX: tempItem.PosX,
            PosY: tempItem.PosY,
            PosZ: tempItem.PosZ,
          };
          let temptemp = { ...tempItem, Lock: false };
          items.splice(t, 1, temp);
          tempItemsArr.push(temp);
          tempAllItems.splice(i, 1, temptemp);
        }
      });
    }
    for (let i = 0; i < lastNum; i = i + 1) {
      let tempItem = tempAllItems[i];

      items.forEach((item, t) => {
        if (item.Name === tempItem.Alias) {
          let temp = {
            ...item,
            Lock: true,
            Placed: true,
            Grounded: tempItem.Grounded,
            PosX: tempItem.PosX,
            PosY: tempItem.PosY,
            PosZ: tempItem.PosZ,
          };
          let temptemp = { ...tempItem, Lock: true };
          items.splice(t, 1, temp);
          tempItemsArr.push(temp);
          tempAllItems.splice(i, 1, temptemp);
        }
      });
    }
    for (let i = lastNum; i < tempAllItems.length; i = i + 1) {
      let tempItem = tempAllItems[i];

      items.forEach((item, t) => {
        if (item.Name === tempItem.Alias) {
          let temp = {
            ...item,
            Lock: false,
            Placed: true,
            Grounded: tempItem.Grounded,
            PosX: undefined,
            PosY: undefined,
            PosZ: undefined,
          };
          let temptemp = { ...tempItem, Lock: false };
          items.splice(t, 1, temp);
          tempItemsArr.push(temp);
          tempAllItems.splice(i, 1, temptemp);
        }
      });
    }

    // Time to check which items are on top. Remove the Placed value from them to potentially place more objects on top.
    let onlyGrounded = [];
    tempItemsArr.forEach((temp) => {
      if (temp.Grounded) {
        onlyGrounded.push(temp);
      }
    });

    let highest = [];
    onlyGrounded.forEach((gr) => {
      let P1X =
        Math.round((gr.PosX - parseFloat(gr.Length) * 12 * 0.5) * 10) / 10;
      let P1Z =
        Math.round((gr.PosZ - parseFloat(gr.Width) * 12 * 0.5) * 10) / 10;
      let P2X =
        Math.round((gr.PosX + parseFloat(gr.Length) * 12 * 0.5) * 10) / 10;
      let P2Z =
        Math.round((gr.PosZ + parseFloat(gr.Width) * 12 * 0.5) * 10) / 10;

      let highestY = 0;
      let high = [];
      tempItemsArr.forEach((temp, t) => {
        if (
          temp.PosX >= P1X &&
          temp.PosX <= P2X &&
          temp.PosZ >= P1Z &&
          temp.PosZ <= P2Z
        ) {
          high.push(temp);
        }
      });
      high.forEach((h) => {
        let p1y =
          Math.round((h.PosY - parseFloat(h.Height) * 12 * 0.5) * 10) / 10;

        if (highestY < p1y) {
          highestY = p1y;
        }
      });

      high.forEach((h) => {
        let p1y =
          Math.round((h.PosY - parseFloat(h.Height) * 12 * 0.5) * 10) / 10;
        if (p1y === highestY) {
          highest.push(h);
        }
      });
    });

    highest.forEach((h) => {
      items.forEach((item, i) => {
        if (item.Name === h.Name) {
          let it = { ...h, Placed: false };
          items.splice(i, 1, it);
        }
      });
      tempAllItems.forEach((temp, t) => {
        if (h.Name === temp.Alias) {
          let ti = { ...h, Placed: false };
          tempAllItems.splice(t, 1, ti);
        }
      });
    });
    // //////
    items.forEach((it, i) => {
      if (!it.Lock) {
        let tempIt = {
          ...it,
          Lock: false,
          Placed: false,
          Grounded: it.Grounded,
          PosX: undefined,
          PosY: undefined,
          PosZ: undefined,
        };
        items.splice(i, 1, tempIt);
      }
    });
    // Dispatch
    let newItemGroup = { ...itemGroup, Items: items };
    let newAllStacks = [...tempAllItems];
    return newItemGroup;

    //

    // NOTICE!!!!!!!
    // Now you need to tell the algorithm to ignore all the items that already have a position. And make sure that the positions are passed in!!!!!
  };
  const onUnlockItems = () => {
    let tempItemGroup = { ...itemGroup };
    let tempAllItems = [...allStacks];
    let items = [...tempItemGroup.Items];
    let lastNum = allStacks.length;
    let tempItemsArr = [];

    let temptempArr = [];
    tempAllItems.forEach((te) => {
      items.forEach((it) => {
        if (it.Name === te.Alias) {
          temptempArr.push(it);
        }
      });
    });
    items = [...temptempArr];

    for (let i = 0; i < lastNum; i = i + 1) {
      let tempItem = tempAllItems[i];

      items.forEach((item, t) => {
        if (item.Name === tempItem.Alias) {
          let temp = {
            ...item,

            Grounded: tempItem.Grounded,
          };
          delete temp.Placed;
          delete temp.Lock;
          delete temp.PosX;
          delete temp.PosZ;
          delete temp.PosY;
          let temptemp = { ...tempItem, Lock: false };
          items.splice(t, 1, temp);
          tempItemsArr.push(temp);
          tempAllItems.splice(i, 1, temptemp);
        }
      });
    }
    items.forEach((it, i) => {
      let temp = { ...it };
      delete temp.Placed;
      delete temp.Lock;
      delete temp.PosX;
      delete temp.PosZ;
      delete temp.PosY;
      items.splice(i, 1, temp);
    });
    let newItemGroup = { ...tempItemGroup, Items: items };
    let newTempItems = [...tempAllItems];

    dispatch(storeAllStacksAction(newTempItems));
    dispatch(storeFullLoadAction(newItemGroup));
    dispatch(storeStackedObjectsAction(newTempItems));
  };
  const onResimulate = () => {
    dispatch(storeFullLoadAction(origItemGroup));

    dispatch(storeAllStacksAction(origAllItems));
    dispatch(storeLastObjectAction(origAllItems[origAllItems.length - 1]));
    dispatch(storeObjectNumAction(origAllItems.length));
    dispatch(storeStackedObjectsAction(origAllItems));
    dispatch(storeStorageDimensionsAction(storageDim));
  };
  const onInsertItem = (event) => {
    let itemName = event.currentTarget.getAttribute("id");
    let items = [...itemGroup.Items];
    let locked = onLockItems();
    let grounded;

    let insertedItem = {};
    items.forEach((it) => {
      if (it.Name === itemName) {
        insertedItem = { ...it };
      }
    });
    itemGroup.Items.forEach((ite) => {
      if (ite.Name === itemName) {
        grounded = ite.Grounded;
      }
    });

    insertedItem = {
      ...insertedItem,
      Grounded: grounded,
      Inserted: true,
      Lock: false,
      Ignore: false,
    };

    locked.Items.forEach((it, i) => {
      if (it.Name === insertedItem.Name) {
        locked.Items.splice(i, 1);
      }
    });

    locked.Items.splice(objNum, 0, insertedItem);
    let newItems = runSimulationVersion5Only(
      locked.Items,
      storageDim,
      momsAttic
    );
    let last = 0;
    let lastInserted = {};
    newItems.forEach((newnew, n) => {
      if (newnew.Alias === insertedItem.Name) {
        last = n;
        lastInserted = { ...newnew, Inserted: false, Lock: true };
      }
    });
    dispatch(storeLastObjectAction(lastInserted));

    locked.Items.forEach((lo, l) => {
      if (lo.Inserted) {
        let tempLocked = { ...lo, Inserted: false, Lock: true };
        locked.Items.splice(l, 1, tempLocked);
      }
    });
    let savedItemGroup = { ...locked };

    if (newItems.length !== locked.Items.length) {
      alert("Your changes could not be made.");
    }
    dispatch(storeFullLoadAction(savedItemGroup));

    dispatch(storeAllStacksAction(newItems));
    dispatch(storeLastObjectAction(newItems[last]));
    dispatch(storeObjectNumAction(last + 1));
    dispatch(storeStackedObjectsAction(newItems));
    dispatch(storeStorageDimensionsAction(storageDim));

    //
  };

  useEffect(() => {
    if (!userAuthID.id) {
      dispatch(toggleUserLoggedInAction(false));
      history.push("/login");
      return;
    }

    window.scroll(0, 0);

    getInitialDetails();
    handleScene();
  }, [objNum, allStacks]);
  return (
    <div className="sim-sim-main">
      <div className="search">
        <input
          className="sim-search"
          type="text"
          id="tbSearch"
          placeholder="Search item by keyword..."
          onChange={onSearch}
        />
      </div>
      {toggleSearch ? (
        <div className="search-res">{handleSearchResults()}</div>
      ) : null}

      <div className="sim-con-wrap">
        <div className="sim-sim-controls-main">
          <div className="longer-grid">
            <button
              onClick={onResimulate}
              className="sim-sim-btn-toggle-full resim"
            >
              Factory Reset
            </button>
          </div>
          <div>
            <p className="sim-sim-controls-toggle">Toggle Items</p>
            <div className="sim-sim-btn-split">
              <button
                onClick={downStack}
                className="sim-sim-btn-toggle"
              >{`<`}</button>
              <button
                onClick={upStack}
                className="sim-sim-btn-toggle"
              >{`>`}</button>
            </div>
          </div>
          <div>
            <p className="sim-sim-controls-toggle">
              {" "}
              Item{" "}
              <select
                id="selChoose"
                onChange={onItemChoose}
                className="sel-choose"
              >
                {handleItemNumList()}
              </select>{" "}
              /{" "}
              <span style={{ color: "rgba(255,255,255,0.5" }}>
                {allStacks.length}
              </span>
            </p>

            {/* Reset */}
            <button onClick={resetSim} className="sim-sim-btn-toggle-full">
              Reset
            </button>
          </div>
        </div>
      </div>
      <button onClick={finishSim} className="dash-btn">
        Close Simulation
      </button>
      <button onClick={saveSim} className="save-btn dash-btn">
        Save
      </button>
      {toggleItemMenu ? (
        <div className="hide-menu-block">
          <div className="hide-menu">
            {lastObject.Width === lastObject.Length &&
            lastObject.Length === lastObject.Height &&
            lastObject.Width === lastObject.Height ? null : (
              <div className="sim-btn-flip-grp">
                {lastObject.Width === lastObject.Height &&
                lastObject.Length === lastObject.Height ? null : (
                  <button onClick={onStandUpLayDown} className="sim-btn-flip">
                    Stand Up / Lay Down
                  </button>
                )}
                {lastObject.Length === lastObject.Width ? null : (
                  <button onClick={onFlipLengthWidth} className="sim-btn-flip">
                    Flip Length / Width
                  </button>
                )}
              </div>
            )}

            {objNum > 1 ? <button
              onClick={onPutBackTakeOut}
              className="sim-btn-flip"
              value={lastObject.Missing ? "put" : "take"}
            >
              {lastObject.Missing ? "Put back in" : "Take out"}
            </button> : null}
            <button onClick={onUnlockItems} className="lock-btn-lg">
              <BsFillUnlockFill /> Items
            </button>
          </div>
          <div>
            <button
              onClick={() => dispatch(toggleHideMenuAction(false))}
              className="hide-menu-btn"
            >
              <IoIosArrowBack />
            </button>
          </div>
        </div>
      ) : (
        <button
          onClick={() => dispatch(toggleHideMenuAction(true))}
          className="btn-hide-menu"
        >
          <FaTools />
        </button>
      )}

      <div className="canvas">
        <canvas id="canvas-sim"></canvas>
      </div>

      <div className="sim-sim-obj-data">
        <div>
          {" "}
          <p className="sim-sim-obj-alias-head">
            {lastObject.Alias}
            <span className="text-white">.</span>
            {/* <button className="single-unlock">
              <BsFillUnlockFill />
            </button> */}
          </p>
          <p className="sim-sim-obj-label">
            {" "}
            {lastObject.Length} x {lastObject.Width} x {lastObject.Height} in
          </p>
          <p className="sim-sim-obj-label">
            {lastObject.Material} - {lastObject.Weight} lbs
          </p>
          <p className="sim-pref">{lastObject.Fragile ? "Fragile" : null}</p>
        </div>
        <div className="data-right">
          {lastObject.Space ? (
            <div>
              <p className="bool">Space Inside</p>
              <p className="sim-sim-obj-label">
                {lastObject.InsideWidth} x {lastObject.InsideLength} x{" "}
                {lastObject.InsideHeight} in
              </p>
            </div>
          ) : null}
          {lastObject.Inside ? <p className="bool">Inside</p> : null}
          {lastObject.Lock ? <p className="bool">Locked</p> : null}
          {lastObject.Missing && !lastObject.Ignore ? (
            <p className="bool text-red-red">Doesn't Fit</p>
          ) : null}
          {lastObject.Ignore && !lastObject.Inside ? (
            <p className="bool text-red-red">Taken Out</p>
          ) : null}
          <p className="sim-sim-obj-label">
            <b style={{ fontWeight: "900", fontSize: "14px" }}>{storAlias}</b>
          </p>
          <p className="sim-sim-obj-label">
            {storLength} x {storWidth} x {storHeight} ft
          </p>
        </div>
      </div>

      {toggleItemList ? (
        <div className="item-list">
          <div>
            <button
              onClick={() => dispatch(toggleItemListAction(false))}
              className="hide-item-list-btn"
            >
              <IoIosArrowForward />
            </button>
          </div>
          <div className="item-list-main">
            <h4>Hidden Items</h4>
            <div>{handleItemList()}</div>
          </div>
        </div>
      ) : (
        <button
          onClick={() => dispatch(toggleItemListAction(true))}
          className="item-list-btn"
        >
          <FaListUl />
        </button>
      )}
    </div>
  );
}
