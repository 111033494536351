import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  storeCheckLoadNameMessageAction,
  storeCheckNameMessageAction,
  storeCreateObjectGroupsAction,
  storeObjectGroupsAction,
  storeSimItemAction,
  storeSingleMenuItemAction,
  storeTotalCubicFeetAction,
  storeTotalMomsWeightAction,
  toggleItemMenuAction,
  toggleNewItemFormAction,
  toggleSpaceInsideAction,
  toggleUserLoggedInAction,
} from "../../redux";
import { users_Collection } from "../../firebase";
import RandomString from "../RandomString";
import { firebaseLooper } from "../../firebase";
import { FaCheck, FaTimes } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export default function ObjectGroupCreate() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAuthID = useSelector((state) => state.storeUserCredsReducer);

  const allItems = useSelector((state) => state.storeCreateObjectGroupsReducer);
  const objGroups = useSelector((state) => state.storeObjectGroupsReducer);

  const toggleNewItemForm = useSelector(
    (state) => state.toggleNewItemFormReducer
  );
  const toggleSpaceInside = useSelector(
    (state) => state.toggleSpaceInsideReducer
  );
  const toggleItemMenu = useSelector((state) => state.toggleItemMenuReducer);
  //
  const totalCubic = useSelector((state) => state.storeTotalCubicFeetReducer);
  const totalMoms = useSelector((state) => state.storeTotalMomsWeightReducer);
  const nameMessage = useSelector(
    (state) => state.storeCheckNameMessageReducer
  );
  const loadNameMessage = useSelector(
    (state) => state.storeCheckLoadNameMessageReducer
  );
  const singleItem = useSelector((state) => state.storeSingleMenuItemReducer);
  const simItem = useSelector((state) => state.storeSimItemReducer);
  const multiplier = 0.02;
  //
  const boxPresets = [
    {
      Alias: "Book Box",
      Width: "1 FT",
      Length: "1 FT",
      Height: "1 FT",
    },
    {
      Alias: "File Box",
      Width: "1.5 FT",
      Length: "1 FT",
      Height: "0.8 FT",
    },
    {
      Alias: "Legal Tote Box",
      Width: "1.8 FT",
      Length: "1.5 FT",
      Height: "1 FT",
    },
    {
      Alias: "Letter Tote Box",
      Width: "2 FT",
      Length: "1 FT",
      Height: "1 FT",
    },
    {
      Alias: "Travel / Luggage Box",
      Width: "2 FT",
      Length: "1.2 FT",
      Height: "0.8 FT",
    },
    {
      Alias: "Dishsaver Glass Pack Box",
      Width: "2.2 FT",
      Length: "1 FT",
      Height: "1 FT",
    },
    {
      Alias: "Lamp Box",
      Width: "1 FT",
      Length: "1 FT",
      Height: "3.5 FT",
    },
    {
      Alias: "Mirror & Picture Box",
      Width: "3.2 FT",
      Length: "0.5 FT",
      Height: "2.5 FT",
    },
    {
      Alias: "Large Mirror & Picture Box",
      Width: "4.2 FT",
      Length: "0.5 FT",
      Height: "2.8 FT",
    },
    {
      Alias: "Sport Utility Box",
      Width: "1.4 FT",
      Length: "1.4 FT",
      Height: "4 FT",
    },
    {
      Alias: "Laydown Wardrobe Box",
      Width: "3.2 FT",
      Length: "1.8 FT",
      Height: "1 FT",
    },
    {
      Alias: "Small Electronics Box",
      Width: "1.8 FT",
      Length: "1.8 FT",
      Height: "1 FT",
    },
    {
      Alias: "Small Moving Box",
      Width: "1.5 FT",
      Length: "1.2 FT",
      Height: "1.2 FT",
    },
    {
      Alias: "Medium Electronics Box",
      Width: "2 FT",
      Length: "2 FT",
      Height: "1.8 FT",
    },
    {
      Alias: "Large Electronics Box",
      Width: "2.1 FT",
      Length: "2.1 FT",
      Height: "2.4 FT",
    },
    {
      Alias: "Small Wardrobe Box",
      Width: "2 FT",
      Length: "2 FT",
      Height: "2.8 FT",
    },
    {
      Alias: "Wardrobe Box",
      Width: "1.5 FT",
      Length: "1.8 FT",
      Height: "3.8 FT",
    },
    {
      Alias: "Medium Wardrobe Box",
      Width: "1.5 FT",
      Length: "1.5 FT",
      Height: "3.8 FT",
    },
    {
      Alias: "Large Wardrobe Box",
      Width: "2 FT",
      Length: "2 FT",
      Height: "4 FT",
    },
    {
      Alias: "Small (Heavy Duty) Moving Box",
      Width: "1.5 FT",
      Length: "1.2 FT",
      Height: "1.2 FT",
    },
    {
      Alias: "Medium Moving Box",
      Width: "1.5 FT",
      Length: "1.5 FT",
      Height: "1.5 FT",
    },
    {
      Alias: "Medium (Heavy Duty) Moving Box",
      Width: "1.5 FT",
      Length: "1.5 FT",
      Height: "1.5 FT",
    },
    {
      Alias: "Large Moving Box",
      Width: "1.5 FT",
      Length: "1.5 FT",
      Height: "2 FT",
    },
    {
      Alias: "Large (Heavy Duty) Moving Box",
      Width: "1.5 FT",
      Length: "1.5 FT",
      Height: "2 FT",
    },
    {
      Alias: "Extra Large Moving Box",
      Width: "2 FT",
      Length: "1.5 FT",
      Height: "2 FT",
    },
    {
      Alias: "Extra Large (Heavy Duty) Moving Box",
      Width: "2 FT",
      Length: "1.5 FT",
      Height: "2 FT",
    },
    {
      Alias: "Crib Mattress",
      Width: "2.4 FT",
      Length: "4.4 FT",
      Height: "0.5 FT",
    },
    {
      Alias: "Twin Mattress",
      Width: "5.5 FT",
      Length: "3.2 FT",
      Height: "1 FT",
    },
    {
      Alias: "Twin XL Mattress",
      Width: "6.7 FT",
      Length: "3.2 FT",
      Height: "1 FT",
    },
    {
      Alias: "Full Mattress",
      Width: "6.3 FT",
      Length: "4.5 FT",
      Height: "1 FT",
    },
    {
      Alias: "Full XL Mattress",
      Width: "6.7 FT",
      Length: "4.5 FT",
      Height: "1 FT",
    },
    {
      Alias: "Queen Mattress",
      Width: "6.7 FT",
      Length: "5 FT",
      Height: "1 FT",
    },
    {
      Alias: "King Mattress",
      Width: "6.7 FT",
      Length: "6.4 FT",
      Height: "1 FT",
    },
    {
      Alias: "California King Mattress",
      Width: "7 FT",
      Length: "6 FT",
      Height: "1 FT",
    },
  ];

  // GET
  const getAllObjectGroups = () => {
    users_Collection
      .doc(userAuthID.id)
      .collection("Loads")
      .get()
      .then((snapshot) => {
        const objGroups = firebaseLooper(snapshot);
        dispatch(storeObjectGroupsAction(objGroups));
      })
      .catch((err) => console.log(err));
  };

  // HANDLE
  const handleObjectGroups = () => {
    return allItems.map((item, i) => {
      return (
        <div className="item-wrap" key={i}>
          <h4 className="item-name">
            {item.Name}
            <span className="text-red">.</span>
          </h4>
          <button
            onClick={onItemMenuClick}
            id={item.id}
            className="item-menu-btn"
            onTouchStart={onItemMenuClick}
          >
            <BsThreeDotsVertical />
          </button>

          <p className="item-dim">
            {item.Length} x {item.Width} x {item.Height} FT
          </p>
          <p className="item-data">
            {item.Material} - {item.Weight} LBS
          </p>

          <p className="item-check">
            {item.Fragile ? (
              <div style={{ display: "flex" }}>
                <FaCheck />
                <p style={{ marginLeft: "10px" }}>Fragile</p>
              </div>
            ) : null}
          </p>
          <br />
          <p className="item-space">
            {item.Space ? "Contains Space Inside" : null}
          </p>
          <p className="item-space">
            {item.Space
              ? `${item.InsideLength} x ${item.InsideWidth} x ${item.InsideHeight} FT`
              : null}
          </p>
          <p className="item-data">{item.Contents}</p>
        </div>
      );
    });
  };
  const handleBoxPresets = () => {
    return boxPresets.map((box, i) => {
      return (
        <option key={i}>
          {box.Alias}
          {" - "}
          {`${box.Width.split(" ")[0]} x ${box.Length.split(" ")[0]} x ${
            box.Height.split(" ")[0]
          } FT`}{" "}
        </option>
      );
    });
  };
  const handleddWidth = () => {
    let arr = [];
    for (let i = 1; i <= 30; i = i + 0.5) {
      arr.push(i);
    }
    return arr.map((num, i) => {
      return (
        <option key={i} value={num}>
          {num}
        </option>
      );
    });
  };
  const handleddWeight = () => {
    let arr = [];
    for (let i = 0; i <= 100; i = i + 5) {
      if (i === 0) {
        arr.push(1);
      } else {
        arr.push(i);
      }
    }
    return arr.map((num, i) => {
      return (
        <option key={i} value={num}>
          {num}
        </option>
      );
    });
  };
  const handleScene = () => {
    if (simItem.Width) {
      document
        .querySelector("#small-canvas-sim")
        .setAttribute(
          "style",
          "width: 100%; height:200px; border-radius: 5px; margin-top: 10px"
        );
      const canvas = document.querySelector("#small-canvas-sim");
      const scene = new THREE.Scene();

      const itemW = simItem.Length * multiplier;
      const itemL = simItem.Width * multiplier;
      const itemH = simItem.Height * multiplier;

      const itemPosX = itemW;
      const itemPosY = itemH * 0.5;
      const itemPosZ = itemL;

      const geometry = new THREE.BoxGeometry(itemW, itemH, itemL);
      const material = new THREE.MeshLambertMaterial({
        color: "#DDAB71",
      });
      const itemBox = new THREE.Mesh(geometry, material);
      const geo = new THREE.EdgesGeometry(itemBox.geometry); // or WireframeGeometry
      const mat = new THREE.LineBasicMaterial({ color: "black" });
      const wireframe = new THREE.LineSegments(geo, mat);
      itemBox.add(wireframe);

      itemBox.position.x = itemPosX;
      itemBox.position.y = itemPosY;
      itemBox.position.z = itemPosZ;

      scene.add(itemBox);

      let floorW;
      let floorH;
      let floorL;
      // FLOOR
      if (itemL > itemW) {
        floorW = itemL + 2 * multiplier;
        floorH = 0.008;
        floorL = itemL + 2 * multiplier;
      } else {
        floorW = itemW + 2 * multiplier;
        floorH = 0.008;
        floorL = itemW + 2 * multiplier;
      }

      const floorGeo = new THREE.BoxGeometry(floorW, floorH, floorL);
      const floorMat = new THREE.MeshLambertMaterial({ color: "white" });
      const floor = new THREE.Mesh(floorGeo, floorMat);

      floor.position.x = itemW;
      floor.position.y = -0.005;
      floor.position.z = itemL;

      let floorPosX = floor.position.x;
      let floorPosZ = floor.position.z;
      scene.add(floor);

      let wall1Geo;
      if (itemL > itemW) {
        wall1Geo = new THREE.BoxGeometry(0.003, itemH, itemL + 2 * multiplier);
      } else {
        wall1Geo = new THREE.BoxGeometry(0.003, itemH, itemW + 2 * multiplier);
      }
      const wall1Mat = new THREE.MeshStandardMaterial({ color: "gray" });
      const wall1 = new THREE.Mesh(wall1Geo, wall1Mat);

      wall1.position.x = floorPosX - floorW * 0.5;
      wall1.position.y = itemH * 0.5 - 0.001;
      wall1.position.z = itemL;

      scene.add(wall1);

      let wall2Geo;
      if (itemL > itemW) {
        wall2Geo = new THREE.BoxGeometry(itemL + 2 * multiplier, itemH, 0.003);
      } else {
        wall2Geo = new THREE.BoxGeometry(itemW + 2 * multiplier, itemH, 0.003);
      }
      const wall2Mat = new THREE.MeshStandardMaterial({ color: "gray" });
      const wall2 = new THREE.Mesh(wall2Geo, wall2Mat);

      wall2.position.x = floorPosX;
      wall2.position.y = itemH * 0.5 - 0.001;
      wall2.position.z = floorPosZ - floorL * 0.5;

      scene.add(wall2);

      const directLight1 = new THREE.DirectionalLight("white", 2.2, 50);
      directLight1.position.set(itemW, itemH, itemL);

      scene.add(directLight1);

      const camera = new THREE.PerspectiveCamera(
        75,
        canvas.clientWidth / canvas.clientHeight,
        0.005,
        100
      );
      camera.name = "camera";
      camera.position.x = `${itemL > itemW ? itemL : itemW}` * 2.2;
      camera.position.y = itemH * 1.2;
      camera.position.z = `${itemL > itemW ? itemL : itemW}` * 2.2;
      camera.lookAt(itemBox.position);
      scene.add(camera);
      const controls = new OrbitControls(camera, canvas);
      const renderer = new THREE.WebGLRenderer({ canvas: canvas });
      const tick = () => {
        renderer.setSize(canvas.clientWidth, canvas.clientHeight);
        renderer.render(scene, camera);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

        window.requestAnimationFrame(tick);
      };

      tick();
    }
  };

  // ONCLICK
  const onAddItem = () => {
    let name = document.querySelector("#tbName").value;

    allItems.forEach((item) => {
      if (item.name === name) {
        alert("Item Name already exists.");
        return;
      }
    });

    let length = document.querySelector("#tbLength").value;
    let width = document.querySelector("#tbWidth").value;
    let height = document.querySelector("#tbHeight").value;
    let weight = document.querySelector("#tbWeight").value;

    if (
      name === "" ||
      length === "" ||
      width === "" ||
      height === "" ||
      weight === ""
    ) {
      alert("You are missing some important information.");
      document
        .querySelector("#tbName")
        .setAttribute(
          "style",
          "border: 1px solid orangered; background-color: rgba(255, 83, 73, 0.1)"
        );
      document
        .querySelector("#tbLength")
        .setAttribute(
          "style",
          "border: 1px solid orangered; background-color: rgba(255, 83, 73, 0.1)"
        );
      document
        .querySelector("#tbWidth")
        .setAttribute(
          "style",
          "border: 1px solid orangered; background-color: rgba(255, 83, 73, 0.1)"
        );
      document
        .querySelector("#tbHeight")
        .setAttribute(
          "style",
          "border: 1px solid orangered; background-color: rgba(255, 83, 73, 0.1)"
        );
      document
        .querySelector("#tbWeight")
        .setAttribute(
          "style",
          "border: 1px solid orangered; background-color: rgba(255, 83, 73, 0.1)"
        );
      return;
    }
    const Contents = document.querySelector("#taDesc").value;
    const materialSelected = document.getElementById("selMaterial");
    const material =
      materialSelected.options[materialSelected.selectedIndex].value;
    let fragile = document.querySelector("#cbFragile").checked;
    let space = toggleSpaceInside;

    // Check if space was picked on purpose
    if (toggleSpaceInside) {
      let insideLength = document.querySelector("#tbInsideLength").value;
      let insideWidth = document.querySelector("#tbInsideWidth").value;
      let insideHeight = document.querySelector("#tbInsideHeight").value;

      if (insideLength === "" || insideWidth === "" || insideHeight === "") {
        space = false;
      }
    }

    let item = {
      Name: name,
      Length: length,
      Width: width,
      Height: height,
      Weight: weight,
      Contents: Contents,
      Material: material,
      Fragile: fragile,
      Space: space,
    };

    if (space) {
      let insideLength = parseFloat(
        document.querySelector("#tbInsideLength").value
      );

      let insideWidth = parseFloat(
        document.querySelector("#tbInsideWidth").value
      );
      let insideHeight = parseFloat(
        document.querySelector("#tbInsideHeight").value
      );

      item = {
        ...item,
        InsideLength: insideLength,
        InsideWidth: insideWidth,
        InsideHeight: insideHeight,
      };
    }

    let totalCu = totalCubic;
    totalCu =
      Math.round(
        (totalCu +
          parseFloat(item.Length) *
            parseFloat(item.Width) *
            parseFloat(item.Height)) *
          10
      ) / 10;

    dispatch(storeTotalCubicFeetAction(totalCu));

    let rand1 = RandomString();
    let rand2 = RandomString();
    let itemID = `Item${rand1}${rand2}`;

    item = { ...item, id: itemID };

    let items = [...allItems];
    items.push(item);
    dispatch(storeCreateObjectGroupsAction(items));

    // Hide top
    dispatch(toggleNewItemFormAction(false));
    dispatch(storeSimItemAction({}));
  };
  const onSaveItem = () => {
    let name = document.querySelector("#tbName").value;

    allItems.forEach((item) => {
      if (item.name === name) {
        alert("Item Name already exists.");
        return;
      }
    });

    let length = document.querySelector("#tbLength").value;
    let width = document.querySelector("#tbWidth").value;
    let height = document.querySelector("#tbHeight").value;
    let weight = document.querySelector("#tbWeight").value;

    if (
      name === "" ||
      length === "" ||
      width === "" ||
      height === "" ||
      weight === ""
    ) {
      alert("You are missing some important information.");
      document
        .querySelector("#tbName")
        .setAttribute(
          "style",
          "border: 1px solid orangered; background-color: rgba(255, 83, 73, 0.1)"
        );
      document
        .querySelector("#tbLength")
        .setAttribute(
          "style",
          "border: 1px solid orangered; background-color: rgba(255, 83, 73, 0.1)"
        );
      document
        .querySelector("#tbWidth")
        .setAttribute(
          "style",
          "border: 1px solid orangered; background-color: rgba(255, 83, 73, 0.1)"
        );
      document
        .querySelector("#tbHeight")
        .setAttribute(
          "style",
          "border: 1px solid orangered; background-color: rgba(255, 83, 73, 0.1)"
        );
      document
        .querySelector("#tbWeight")
        .setAttribute(
          "style",
          "border: 1px solid orangered; background-color: rgba(255, 83, 73, 0.1)"
        );
      return;
    }
    const contents = document.querySelector("#taDesc").value;
    const materialSelected = document.getElementById("selMaterial");
    const material =
      materialSelected.options[materialSelected.selectedIndex].value;
    let fragile = document.querySelector("#cbFragile").checked;
    let space = toggleSpaceInside;

    // Check if space was picked on purpose
    if (toggleSpaceInside) {
      let insideLength = document.querySelector("#tbInsideLength").value;
      let insideWidth = document.querySelector("#tbInsideWidth").value;
      let insideHeight = document.querySelector("#tbInsideHeight").value;

      if (insideLength === "" || insideWidth === "" || insideHeight === "") {
        space = false;
      }
    }

    let item = {
      id: singleItem.id,
      Name: name,
      Length: length,
      Width: width,
      Height: height,
      Weight: weight,
      Contents: contents,
      Material: material,
      Fragile: fragile,
      Space: space,
      New: singleItem.New ? true : false,
    };

    if (space) {
      let insideLength = document.querySelector("#tbInsideLength").value;
      let insideWidth = document.querySelector("#tbInsideWidth").value;
      let insideHeight = document.querySelector("#tbInsideHeight").value;

      item = {
        ...item,
        InsideLength: insideLength,
        InsideWidth: insideWidth,
        InsideHeight: insideHeight,
      };
    }


    let volume =
      parseFloat(item.Length) *
      parseFloat(item.Width) *
      parseFloat(item.Height);
    let singleVol =
      parseFloat(singleItem.Length) *
      parseFloat(singleItem.Width) *
      parseFloat(singleItem.Height);
    if (volume !== singleVol) {
      let totalCu = totalCubic;
      totalCu = totalCu - singleVol;
      totalCu = Math.round((totalCu + volume) * 10) / 10;
      dispatch(storeTotalCubicFeetAction(totalCu));
    }

    let items = [...allItems];
    for (let a = 0; a < items.length; a = a + 1) {
      let it = items[a];
      if (it.Name === singleItem.Name) {
        items[a] = item;
        dispatch(storeCreateObjectGroupsAction(items));
        dispatch(storeSingleMenuItemAction({}));
      }
    }

    // Hide top
    dispatch(toggleNewItemFormAction(false));
    dispatch(storeSimItemAction({}));
  };
  const onDupeItem = () => {
    let r = (Math.random() + 1).toString(36).substring(7);
    let rand1 = RandomString();
    let rand2 = RandomString();
    let itemID = `Item${rand1}${rand2}`;
    let tempItem = {
      ...singleItem,
      Name: `Item - ${r}`,
      id: itemID,
    };

    if (singleItem.MomsAttic) {
      let totalWeight = totalMoms;
      totalWeight =
        Math.round((totalMoms + parseFloat(singleItem.Weight)) * 10) / 10;
      dispatch(storeTotalMomsWeightAction(totalWeight));
    }

    let volume =
      parseFloat(singleItem.Length) *
      parseFloat(singleItem.Width) *
      parseFloat(singleItem.Height);

    let totalCu = totalCubic;
    totalCu = Math.round((totalCu + volume) * 10) / 10;
    dispatch(storeTotalCubicFeetAction(totalCu));

    let items = [...allItems];
    items.push(tempItem);
    dispatch(storeCreateObjectGroupsAction(items));
    dispatch(toggleItemMenuAction(false));
    dispatch(storeSingleMenuItemAction({}));
  };
  const onRemoveItem = () => {
    let items = [...allItems];
    for (let i = 0; i < items.length; i = i + 1) {
      let obj = items[i];
      if (obj.id === singleItem.id) {
        items.splice(i, 1);
      }
    }

    let totalWeight = totalMoms;
    let totalCu = totalCubic;

    if (singleItem.MomsAttic) {
      totalWeight = totalWeight - parseFloat(singleItem.Weight);
      dispatch(storeTotalMomsWeightAction(totalWeight));
    }

    totalCu =
      Math.round(
        (totalCu -
          parseFloat(singleItem.Length) *
            parseFloat(singleItem.Width) *
            parseFloat(singleItem.Height)) *
          10
      ) / 10;
    dispatch(storeTotalCubicFeetAction(totalCu));
    dispatch(storeCreateObjectGroupsAction([...items]));
    dispatch(toggleItemMenuAction(false));
    dispatch(storeSingleMenuItemAction({}));
  };
  const onItemMenuClick = (event) => {
    const itemID = event.currentTarget.getAttribute("id");
    allItems.forEach((item) => {
      if (item.id === itemID) {
        dispatch(storeSingleMenuItemAction(item));
      }
    });
    dispatch(toggleItemMenuAction(true));
    window.scroll(0, 0);
  };
  const onItemMenuEditClick = () => {
    dispatch(toggleNewItemFormAction(true));
    dispatch(toggleItemMenuAction(false));
    dispatch(
      storeSimItemAction({
        Width: singleItem.Width,
        Length: singleItem.Length,
        Height: singleItem.Height,
      })
    );

    if (singleItem.Space) {
      if (!toggleSpaceInside) {
        dispatch(toggleSpaceInsideAction());
      }
    } else {
      if (toggleSpaceInside) {
        dispatch(toggleSpaceInsideAction());
      }
    }
  };
  const flipUpDown = () => {
    let length = document.querySelector("#tbHeight").value;
    let height = document.querySelector("#tbLength").value;

    document.querySelector("#tbLength").value = length;
    document.querySelector("#tbHeight").value = height;

    dispatch(
      storeSimItemAction({
        Width: simItem.Width,
        Length: length,
        Height: height,
      })
    );
  };
  const flipSideSide = () => {
    let length = document.querySelector("#tbWidth").value;
    let width = document.querySelector("#tbLength").value;

    document.querySelector("#tbLength").value = length;
    document.querySelector("#tbWidth").value = width;

    dispatch(
      storeSimItemAction({
        Width: width,
        Length: length,
        Height: simItem.Height,
      })
    );
  };

  // ONCHANGE
  const onBoxFurniturePresetChange = () => {
    var e = document.getElementById("selBoxPresets");
    let thing = e.options[e.selectedIndex].text;
    thing = thing.split(" - ")[0];

    boxPresets.forEach((box) => {
      if (box.Alias === thing) {
        let itemW = box.Width.split(" ")[0];
        let itemL = box.Length.split(" ")[0];
        let itemH = box.Height.split(" ")[0];

        document.querySelector("#tbLength").value = itemL;
        document.querySelector("#tbWidth").value = itemW;
        document.querySelector("#tbHeight").value = itemH;

        dispatch(
          storeSimItemAction({ Width: itemL, Length: itemW, Height: itemH })
        );
      }
    });
  };
  const onDimensionChange = (event) => {
    // Get id
    const selID = event.currentTarget.getAttribute("id");

    // Get selected value
    let selArr = document.getElementById(selID);
    const selected = selArr.options[selArr.selectedIndex].value;

    if (selected === "Add Custom...") {
      let tb = document.querySelector(`#tb${selID.substr(3)}`);
      tb.focus();
      document.querySelector(`#${selID}`).value = "";
      return;
    }

    // Set in tb
    let tb = document.querySelector(`#tb${selID.substr(3)}`);
    tb.value = selected;

    document.querySelector(`#${selID}`).value = "";
  };
  const onCheckNameChange = () => {
    const name = document.querySelector("#tbName").value;
    let match = false;
    allItems.forEach((item) => {
      if (item.Name === name && item.Name !== singleItem.Name) {
        match = true;
      }
    });

    if (match) {
      dispatch(storeCheckNameMessageAction("Item Name already exists."));
    } else {
      dispatch(storeCheckNameMessageAction(""));
    }
  };
  const onCheckLoadNameChange = () => {
    const name = document.querySelector("#tbObjGrpName").value;
    let match = false;
    objGroups.forEach((load) => {
      if (load.Name === name) {
        match = true;
      }
    });

    if (match) {
      dispatch(storeCheckLoadNameMessageAction("Load Name already exists."));
    } else {
      dispatch(storeCheckLoadNameMessageAction(""));
    }
  };
  const onDimInput = () => {
    let width = document.querySelector("#tbWidth").value;
    let length = document.querySelector("#tbLength").value;
    let height = document.querySelector("#tbHeight").value;

    if (width !== "" && length !== "" && height !== "") {
      dispatch(
        storeSimItemAction({ Width: length, Length: width, Height: height })
      );
    }
  };

  const finishObjGrp = () => {
    let loadName = document.querySelector("#tbObjGrpName").value;
    if (loadName === "") {
      alert("Please enter a Load Name.");
      return;
    }
    let match = false;
    objGroups.forEach((load) => {
      if (load.Name === loadName) {
        match = true;
      }
    });
    if (match) {
      alert("Load Name already exists.");
      return;
    }

    // Proceed Here
    let rand1 = RandomString();
    let rand2 = RandomString();
    let loadID = `Load${rand1}${rand2}`;

    users_Collection
      .doc(userAuthID.id)
      .collection("Loads")
      .doc(loadID)
      .set({
        Name: loadName,
      })
      .catch((err) => console.log(err));

    for (let i = 0; i < allItems.length; i = i + 1) {
      let item = allItems[i];

      if (item.Space) {
        users_Collection
          .doc(userAuthID.id)
          .collection("Loads")
          .doc(loadID)
          .collection("Items")
          .doc(item.id)
          .set({
            Name: item.Name,
            Length: item.Length,
            Width: item.Width,
            Height: item.Height,
            Weight: item.Weight,
            Material: item.Material,
            Contents: item.Contents,
            Fragile: item.Fragile,
            Space: item.Space,
            InsideLength: item.InsideLength,
            InsideWidth: item.InsideWidth,
            InsideHeight: item.InsideHeight,
          })
          .catch((err) => console.log(err));
      } else {
        users_Collection
          .doc(userAuthID.id)
          .collection("Loads")
          .doc(loadID)
          .collection("Items")
          .doc(item.id)
          .set({
            Name: item.Name,
            Length: item.Length,
            Width: item.Width,
            Height: item.Height,
            Weight: item.Weight,
            Contents: item.Contents,
            Material: item.Material,
            Fragile: item.Fragile,
          })
          .catch((err) => console.log(err));
      }
    }

    let allLoads = [...objGroups];
    allLoads.push([...allItems]);
    dispatch(storeObjectGroupsAction(allLoads));
    dispatch(storeSingleMenuItemAction({}));
    dispatch(storeCreateObjectGroupsAction([]));
    dispatch(storeSimItemAction({}));
    history.push("/dashboard");
  };

  // CANCEL
  const cancelNewItem = () => {
    dispatch(toggleNewItemFormAction(false));
    dispatch(storeSingleMenuItemAction({}));
    dispatch(toggleSpaceInsideAction(false));
    dispatch(storeSimItemAction({}));
  };

  useEffect(() => {
    if (!userAuthID.id) {
      dispatch(toggleUserLoggedInAction(false));
      history.push("/login");
      return;
    }

    getAllObjectGroups();
    handleScene();
  }, [simItem]);
  return (
    <div style={{ fontFamily: "Heebo, sans-serif" }}>
      <button
        className="dash-btn"
        onClick={() => {
          dispatch(storeCreateObjectGroupsAction([]));
          dispatch(storeSingleMenuItemAction({}));
          dispatch(storeTotalCubicFeetAction(0));
          dispatch(storeTotalMomsWeightAction(0));
          history.push("/dashboard");
        }}
      >
        Dashboard
      </button>

      <h1 className="obj-view-head">
        New Load<span className="text-red">.</span>
      </h1>
      <p className="warning">
        For best results, load all your items before simulating.
      </p>

      <div className="create-name-wrap">
        <h4 className="obj-create-head">
          Name, Address, or Date<span className="text-red">.</span>
        </h4>
        <p className="item-sub">
          Give your load a name to group your items together.
        </p>
        <input
          className="obj-create-lg-tb"
          type="text"
          id="tbObjGrpName"
          placeholder="ex. 3212 Commercial St. or July 21 Move"
          onChange={onCheckLoadNameChange}
        />
        <p className="item-name-mess">{loadNameMessage}</p>
        <button
          onClick={() => {
            dispatch(toggleNewItemFormAction(true));
            if (toggleSpaceInside) {
              dispatch(toggleSpaceInsideAction());
            }
            window.scroll(0, 0);
          }}
          className="add-new-item-btn"
        >
          Add New Item
        </button>
      </div>

      {toggleItemMenu ? (
        <div className="blackout">
          <div className="item-menu">
            <button
              onClick={() => dispatch(toggleItemMenuAction(false))}
              className="close-btn"
            >
              <FaTimes />
            </button>
            <button onClick={onItemMenuEditClick} className="edit">
              Edit
            </button>
            <button onClick={onDupeItem} className="purple">
              Duplicate
            </button>
            <button onClick={onRemoveItem} className="remove">
              Remove
            </button>
          </div>
        </div>
      ) : null}

      <div className="total-wrap">
        <h4>Total CU FT:</h4>
        <p>{totalCubic} cu ft</p>
      </div>

      {allItems.length === 0 ? (
        <h4 className="no-item-notice">
          Please add at least one item to proceed
          <span className="text-red">.</span>
        </h4>
      ) : null}
      {toggleNewItemForm ? (
        <div className="blackout">
          <div className="new-item-main-wrap">
            <div className="new-item-wrap">
              <div className="new-form-block">
                {singleItem.Name ? (
                  <h4 className="new-item-head">
                    Edit Item<span className="text-red">.</span>
                  </h4>
                ) : (
                  <h4 className="new-item-head">
                    New Item<span className="text-red">.</span>
                  </h4>
                )}
                <label className="new-item-label">
                  Box / Furniture Presets:{" "}
                </label>
                <select
                  className="new-item-dd"
                  name="boxPresets"
                  id="selBoxPresets"
                  onChange={onBoxFurniturePresetChange}
                  defaultValue=""
                >
                  <option></option>
                  {handleBoxPresets()}
                </select>
                <label className="new-item-label">Name:</label>
                <input
                  id="tbName"
                  className="new-item-tb"
                  type="text"
                  placeholder="ie. Clothes Box 1"
                  onChange={onCheckNameChange}
                  defaultValue={singleItem.Name !== "" ? singleItem.Name : null}
                ></input>
                <p className="item-name-mess">{nameMessage}</p>
                <label className="new-item-label">Contents:</label>
                <textarea
                  id="taDesc"
                  className="ta-Desc"
                  defaultValue={
                    singleItem.Contents !== "" ? singleItem.Contents : null
                  }
                ></textarea>
              </div>
              <div className="new-form-block">
                <label className="new-item-label">Length:</label>
                <div style={{ display: "flex" }}>
                  <div className="inputSelPair">
                    <input
                      type="text"
                      id="tbLength"
                      defaultValue={
                        singleItem.Length !== "" ? singleItem.Length : null
                      }
                      onChange={onDimInput}
                    />
                    <select
                      className="new-item-dd dd "
                      id="selLength"
                      name="selLength"
                      onChange={onDimensionChange}
                    >
                      <option></option>
                      {handleddWidth()}
                      <option>Add Custom...</option>
                    </select>
                  </div>
                  <span className="units">FT</span>
                </div>

                <label className="new-item-label">Width:</label>
                <div style={{ display: "flex" }}>
                  <div className="inputSelPair">
                    <input
                      type="text"
                      id="tbWidth"
                      defaultValue={
                        singleItem.Width !== "" ? singleItem.Width : null
                      }
                      onChange={onDimInput}
                    />
                    <select
                      className="dd new-item-dd"
                      id="selWidth"
                      name="selWidth"
                      onChange={onDimensionChange}
                    >
                      <option></option>
                      {handleddWidth()}
                      <option>Add Custom...</option>
                    </select>
                  </div>
                  <span className="units">FT</span>
                </div>

                <label className="new-item-label">Height:</label>
                <div style={{ display: "flex" }}>
                  <div className="inputSelPair">
                    <input
                      type="text"
                      id="tbHeight"
                      defaultValue={
                        singleItem.Height !== "" ? singleItem.Height : null
                      }
                      onChange={onDimInput}
                    />
                    <select
                      className="dd new-item-dd"
                      id="selHeight"
                      name="selHeight"
                      onChange={onDimensionChange}
                    >
                      <option></option>
                      {handleddWidth()}
                      <option>Add Custom...</option>
                    </select>
                  </div>
                  <span className="units">FT</span>
                </div>
              </div>
              <div className="new-form-block">
                <label className="new-item-label">Weight:</label>
                <div style={{ display: "flex" }}>
                  <div className="inputSelPair">
                    <input
                      type="text"
                      id="tbWeight"
                      defaultValue={singleItem.Weight ? singleItem.Weight : "0"}
                    />
                    <select
                      className="dd new-item-dd"
                      id="selWeight"
                      name="selWeight"
                      onChange={onDimensionChange}
                    >
                      <option></option>
                      {handleddWeight()}
                      <option>Add Custom...</option>
                    </select>
                  </div>
                  <span className="units">LBS</span>
                </div>
                <label className="new-item-label">Material:</label>
                {singleItem.Material !== "" ? (
                  <div>
                    <select
                      className="new-item-dd"
                      id="selMaterial"
                      name="selMaterial"
                    >
                      <option
                        selected={
                          singleItem.Material === "Cardboard"
                            ? singleItem.Material
                            : null
                        }
                      >
                        Cardboard
                      </option>
                      <option
                        selected={
                          singleItem.Material === "Plastic"
                            ? singleItem.Material
                            : null
                        }
                      >
                        Plastic
                      </option>
                      <option
                        selected={
                          singleItem.Material === "Metal"
                            ? singleItem.Material
                            : null
                        }
                      >
                        Metal
                      </option>
                      <option
                        selected={
                          singleItem.Material === "Wood"
                            ? singleItem.Material
                            : null
                        }
                      >
                        Wood
                      </option>
                    </select>{" "}
                  </div>
                ) : (
                  <div>
                    <select
                      className="new-item-dd"
                      id="selMaterial"
                      name="selMaterial"
                    >
                      <option selected="true">Cardboard</option>
                      <option>Plastic</option>
                      <option>Metal</option>
                      <option>Wood</option>
                    </select>
                  </div>
                )}

                <button
                  onClick={() => dispatch(toggleSpaceInsideAction())}
                  className="space-inside-btn"
                >
                  Space Inside?
                </button>

                <div className="all-cbs-div">
                  <label className="new-item-label">Fragile?</label>
                  <input
                    id="cbFragile"
                    type="checkbox"
                    defaultChecked={
                      singleItem.Fragile !== null ? singleItem.Fragile : null
                    }
                  ></input>
                </div>
              </div>

              {toggleSpaceInside ? (
                <div className="new-form-block">
                  <label className="new-item-label">Inside Length:</label>
                  <div style={{ display: "flex" }}>
                    <div className="inputSelPair">
                      <input
                        type="text"
                        id="tbInsideLength"
                        defaultValue={
                          singleItem.InsideLength !== null
                            ? singleItem.InsideLength
                            : null
                        }
                      />
                      <select
                        className="dd new-item-dd"
                        id="selInsideLength"
                        name="selInsideLength"
                        onChange={onDimensionChange}
                      >
                        <option></option>
                        {handleddWidth()}
                        <option>Add Custom...</option>
                      </select>
                    </div>
                    <span className="units">FT</span>
                  </div>
                  <label className="new-item-label">Inside Width:</label>
                  <div style={{ display: "flex" }}>
                    <div className="inputSelPair">
                      <input
                        type="text"
                        id="tbInsideWidth"
                        defaultValue={
                          singleItem.InideWidth !== null
                            ? singleItem.InsideWidth
                            : null
                        }
                      />
                      <select
                        className="dd new-item-dd"
                        id="selInsideWidth"
                        name="selInsideWidth"
                        onChange={onDimensionChange}
                      >
                        <option></option>
                        {handleddWidth()}
                        <option>Add Custom...</option>
                      </select>
                    </div>
                    <span className="units">FT</span>
                  </div>
                  <label className="new-item-label">Inside Height:</label>
                  <div style={{ display: "flex" }}>
                    <div className="inputSelPair">
                      <input
                        type="text"
                        id="tbInsideHeight"
                        defaultValue={
                          singleItem.InsideHeight !== null
                            ? singleItem.InsideHeight
                            : null
                        }
                      />
                      <select
                        className="dd new-item-dd"
                        id="selInsideHeight"
                        name="selInsideHeight"
                        onChange={onDimensionChange}
                      >
                        <option></option>
                        {handleddWidth()}
                        <option>Add Custom...</option>
                      </select>
                    </div>
                    <span className="units">FT</span>
                  </div>
                </div>
              ) : null}
              {/* <div className="new-form-block all-cbs">
                <div className="all-cbs-div">
                  <label className="new-item-label">On Ground?</label>
                  <input
                    id="cbGround"
                    type="checkbox"
                    defaultChecked={
                      singleItem.Grounded !== null ? singleItem.Grounded : null
                    }
                  ></input>
                </div>
                <div className="all-cbs-div">
                  <label className="new-item-label">Fragile?</label>
                  <input
                    id="cbFragile"
                    type="checkbox"
                    defaultChecked={
                      singleItem.Fragile !== null ? singleItem.Fragile : null
                    }
                  ></input>
                </div>
                <div className="all-cbs-div">
                  <label className="new-item-label">Abstract?</label>
                  <input
                    id="cbAbstract"
                    type="checkbox"
                    defaultChecked={
                      singleItem.Abstract !== null ? singleItem.Abstract : null
                    }
                  ></input>
                </div>
                <div className="all-cbs-div">
                  <label className="new-item-label">In Mom's Attic?</label>
                  <input
                    id="cbMoms"
                    type="checkbox"
                    defaultChecked={
                      singleItem.MomsAttic !== null
                        ? singleItem.MomsAttic
                        : null
                    }
                  ></input>
                </div>
              </div> */}
              <div className="new-form-block small-canvas">
                <canvas id="small-canvas-sim"></canvas>
                {simItem.Width ? (
                  <div className="small-sim-btn-grp">
                    <button onClick={flipUpDown}>Stand Up/Lay Down</button>
                    <button onClick={flipSideSide}>Flip Length/Width</button>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="new-item-btn-grp">
              <button onClick={cancelNewItem} className="edit new-item-btn">
                Cancel
              </button>
              {singleItem.Name ? (
                <button onClick={onSaveItem} className="purple new-item-btn">
                  Save
                </button>
              ) : (
                <button onClick={onAddItem} className="purple new-item-btn">
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      ) : null}

      <div className="all-objs-block">{handleObjectGroups()}</div>

      {allItems.length > 0 ? (
        <button onClick={finishObjGrp} className="run-sim-obj">
          Finish
        </button>
      ) : null}
      <br />
      <br />
    </div>
  );
}
