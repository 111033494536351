import { combineReducers } from "redux";

// STORE

export const storeInvalidCredsMessageAction = (payload) => {
  return {
    type: "storeInvalidCredsMessageAction",
    payload: payload,
  };
};
export const storeInvalidCredsMessageReducer = (state = "", action) => {
  switch (action.type) {
    case "storeInvalidCredsMessageAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
// User Creds
export const storeUserCredsAction = (payload) => {
  return {
    type: "storeUserCredsAction",
    payload: payload,
  };
};
export const storeUserCredsReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeUserCredsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
// Object Groups
export const storeObjectGroupsAction = (payload) => {
  return {
    type: "storeObjectGroupsAction",
    payload: payload,
  };
};
export const storeObjectGroupsReducer = (state = [], action) => {
  switch (action.type) {
    case "storeObjectGroupsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
// Object Group
export const storeObjectGroupAction = (payload) => {
  return {
    type: "storeObjectGroupAction",
    payload: payload,
  };
};
export const storeObjectGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeObjectGroupAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

//////////////// SIMULATION STATES
export const storeStackedObjectsAction = (payload) => {
  return {
    type: "storeStackedObjectsAction",
    payload: payload,
  };
};
export const storeStackedObjectsReducer = (state = [], action) => {
  switch (action.type) {
    case "storeStackedObjectsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeStorageDimensionsAction = (payload) => {
  return {
    type: "storeStorageDimensionsAction",
    payload: payload,
  };
};
export const storeStorageDimensionsReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeStorageDimensionsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeLastObjectAction = (payload) => {
  return {
    type: "storeLastObjectAction",
    payload: payload,
  };
};
export const storeLastObjectReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeLastObjectAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeStorageUnitsAction = (payload) => {
  return {
    type: "storeStorageUnitsAction",
    payload: payload,
  };
};
export const storeStorageUnitsReducer = (state = [], action) => {
  switch (action.type) {
    case "storeStorageUnitsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeSimulationsAction = (payload) => {
  return {
    type: "storeSimulationsAction",
    payload: payload,
  };
};
export const storeSimulationsReducer = (state = [], action) => {
  switch (action.type) {
    case "storeSimulationsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeCurrentStackAction = (payload) => {
  return {
    type: "storeCurrentStackAction",
    payload: payload,
  };
};
export const storeCurrentStackReducer = (state = [], action) => {
  switch (action.type) {
    case "storeCurrentStackAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeStackNumAction = (payload) => {
  return {
    type: "storeStackNumAction",
    payload: payload,
  };
};
export const storeStackNumReducer = (state = 0, action) => {
  switch (action.type) {
    case "storeStackNumAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeAllStacksAction = (payload) => {
  return {
    type: "storeAllStacksAction",
    payload: payload,
  };
};
export const storeAllStacksReducer = (state = [], action) => {
  switch (action.type) {
    case "storeAllStacksAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeObjectNumAction = (payload) => {
  return {
    type: "storeObjectNumAction",
    payload: payload,
  };
};
export const storeObjectNumReducer = (state = 0, action) => {
  switch (action.type) {
    case "storeObjectNumAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

// Object Groups
export const storeCreateObjectGroupsAction = (payload) => {
  return {
    type: "storeCreateObjectGroupsAction",
    payload: payload,
  };
};
export const storeCreateObjectGroupsReducer = (state = [], action) => {
  switch (action.type) {
    case "storeCreateObjectGroupsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeSingleObjectGroupAction = (payload) => {
  return {
    type: "storeSingleObjectGroupAction",
    payload: payload,
  };
};
export const storeSingleObjectGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeSingleObjectGroupAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeAllObjectGroupsAction = (payload) => {
  return {
    type: "storeAllObjectGroupsAction",
    payload: payload,
  };
};
export const storeAllObjectGroupsReducer = (state = [], action) => {
  switch (action.type) {
    case "storeAllObjectGroupsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeAllSimulationsAction = (payload) => {
  return {
    type: "storeAllSimulationsAction",
    payload: payload,
  };
};
export const storeAllSimulationsReducer = (state = [], action) => {
  switch (action.type) {
    case "storeAllSimulationsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeAllStoragesAction = (payload) => {
  return {
    type: "storeAllStoragesAction",
    payload: payload,
  };
};
export const storeAllStoragesReducer = (state = [], action) => {
  switch (action.type) {
    case "storeAllStoragesAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeSingleStorageAction = (payload) => {
  return {
    type: "storeSingleStorageAction",
    payload: payload,
  };
};
export const storeSingleStorageReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeSingleStorageAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeAllStorageGroupsAction = (payload) => {
  return {
    type: "storeAllStorageGroupsAction",
    payload: payload,
  };
};
export const storeAllStorageGroupsReducer = (state = [], action) => {
  switch (action.type) {
    case "storeAllStorageGroupsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeAllStorageUnitsAction = (payload) => {
  return {
    type: "storeAllStorageUnitsAction",
    payload: payload,
  };
};
export const storeAllStorageUnitsReducer = (state = [], action) => {
  switch (action.type) {
    case "storeAllStorageUnitsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeAllBestMatchesAction = (payload) => {
  return {
    type: "storeAllBestMatchesAction",
    payload: payload,
  };
};
export const storeAllBestMatchesReducer = (state = [], action) => {
  switch (action.type) {
    case "storeAllBestMatchesAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeMissingObjectsAction = (payload) => {
  return {
    type: "storeMissingObjectsAction",
    payload: payload,
  };
};
export const storeMissingObjectsReducer = (state = false, action) => {
  switch (action.type) {
    case "storeMissingObjectsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeShareObjectGroupAction = (payload) => {
  return {
    type: "storeShareObjectGroupAction",
    payload: payload,
  };
};
export const storeShareObjectGroupReducer = (state = [], action) => {
  switch (action.type) {
    case "storeShareObjectGroupAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeShareModalMessageAction = (payload) => {
  return {
    type: "storeShareModalMessageAction",
    payload: payload,
  };
};
export const storeShareModalMessageReducer = (state = "Success", action) => {
  switch (action.type) {
    case "storeShareModalMessageAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeDoMatchAction = (payload) => {
  return {
    type: "storeDoMatchAction",
    payload: payload,
  };
};
export const storeDoMatchReducer = (state = true, action) => {
  switch (action.type) {
    case "storeDoMatchAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeRegMessageAction = (payload) => {
  return {
    type: "storeRegMessageAction",
    payload: payload,
  };
};
export const storeRegMessageReducer = (state = "", action) => {
  switch (action.type) {
    case "storeRegMessageAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeMomStorageDimAction = (payload) => {
  return {
    type: "storeMomStorageDimAction",
    payload: payload,
  };
};
export const storeMomStorageDimReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeMomStorageDimAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeMomStorageObjectsAction = (payload) => {
  return {
    type: "storeMomStorageObjectsAction",
    payload: payload,
  };
};
export const storeMomStorageObjectsReducer = (state = [], action) => {
  switch (action.type) {
    case "storeMomStorageObjectsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeWheelObjectsAction = (payload) => {
  return {
    type: "storeWheelObjectsAction",
    payload: payload,
  };
};
export const storeWheelObjectsReducer = (state = [], action) => {
  switch (action.type) {
    case "storeWheelObjectsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeTotalCubicFeetAction = (payload) => {
  return {
    type: "storeTotalCubicFeetAction",
    payload: payload,
  };
};
export const storeTotalCubicFeetReducer = (state = 0, action) => {
  switch (action.type) {
    case "storeTotalCubicFeetAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeTotalMomsWeightAction = (payload) => {
  return {
    type: "storeTotalMomsWeightAction",
    payload: payload,
  };
};
export const storeTotalMomsWeightReducer = (state = 0, action) => {
  switch (action.type) {
    case "storeTotalMomsWeightAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeTempWeightAction = (payload) => {
  return {
    type: "storeTempWeightAction",
    payload: payload,
  };
};
export const storeTempWeightReducer = (state = 0, action) => {
  switch (action.type) {
    case "storeTempWeightAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeCurrentMovingLoadAction = (payload) => {
  return {
    type: "storeCurrentMovingLoadAction",
    payload: payload,
  };
};
export const storeCurrentMovingLoadReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeCurrentMovingLoadAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeCurrentSpaceAction = (payload) => {
  return {
    type: "storeCurrentSpaceAction",
    payload: payload,
  };
};
export const storeCurrentSpaceReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeCurrentSpaceAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeCheckNameMessageAction = (payload) => {
  return {
    type: "storeCheckNameMessageAction",
    payload: payload,
  };
};
export const storeCheckNameMessageReducer = (state = "", action) => {
  switch (action.type) {
    case "storeCheckNameMessageAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeCheckLoadNameMessageAction = (payload) => {
  return {
    type: "storeCheckLoadNameMessageAction",
    payload: payload,
  };
};
export const storeCheckLoadNameMessageReducer = (state = "", action) => {
  switch (action.type) {
    case "storeCheckLoadNameMessageAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeSingleMenuItemAction = (payload) => {
  return {
    type: "storeSingleMenuItemAction",
    payload: payload,
  };
};
export const storeSingleMenuItemReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeSingleMenuItemAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeMostRecentLoadAction = (payload) => {
  return {
    type: "storeMostRecentLoadAction",
    payload: payload,
  };
};
export const storeMostRecentLoadReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeMostRecentLoadAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeSearchResultsAction = (payload) => {
  return {
    type: "storeSearchResultsAction",
    payload: payload,
  };
};
export const storeSearchResultsReducer = (state = [], action) => {
  switch (action.type) {
    case "storeSearchResultsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeSimItemAction = (payload) => {
  return {
    type: "storeSimItemAction",
    payload: payload,
  };
};
export const storeSimItemReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeSimItemAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeFullLoadAction = (payload) => {
  return {
    type: "storeFullLoadAction",
    payload: payload,
  };
};
export const storeFullLoadReducer = (state = [], action) => {
  switch (action.type) {
    case "storeFullLoadAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeOriginalItemGroupAction = (payload) => {
  return {
    type: "storeOriginalItemGroupAction",
    payload: payload,
  };
};
export const storeOriginalItemGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeOriginalItemGroupAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeOriginalItemsAction = (payload) => {
  return {
    type: "storeOriginalItemsAction",
    payload: payload,
  };
};
export const storeOriginalItemsReducer = (state = [], action) => {
  switch (action.type) {
    case "storeOriginalItemsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const storeSimSpaceDimAction = (payload) => {
  return {
    type: "storeSimSpaceDimAction",
    payload: payload,
  };
};
export const storeSimSpaceDimReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeSimSpaceDimAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeSimMomDimAction = (payload) => {
  return {
    type: "storeSimMomDimAction",
    payload: payload,
  };
};
export const storeSimMomDimReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeSimMomDimAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeSimLoadObjAction = (payload) => {
  return {
    type: "storeSimLoadObjAction",
    payload: payload,
  };
};
export const storeSimLoadObjReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeSimLoadObjAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeSimItemsArrAction = (payload) => {
  return {
    type: "storeSimItemsArrAction",
    payload: payload,
  };
};
export const storeSimItemsArrReducer = (state = [], action) => {
  switch (action.type) {
    case "storeSimItemsArrAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeSimCurrentNumAction = (payload) => {
  return {
    type: "storeSimCurrentNumAction",
    payload: payload,
  };
};
export const storeSimCurrentNumReducer = (state = 0, action) => {
  switch (action.type) {
    case "storeSimCurrentNumAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeSimCurrentObjAction = (payload) => {
  return {
    type: "storeSimCurrentObjAction",
    payload: payload,
  };
};
export const storeSimCurrentObjReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeSimCurrentObjAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeSimOriginalLoadAction = (payload) => {
  return {
    type: "storeSimOriginalLoadAction",
    payload: payload,
  };
};
export const storeSimOriginalLoadReducer = (state = {}, action) => {
  switch (action.type) {
    case "storeSimOriginalLoadAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeSimPlacedItemsAction = (payload) => {
  return {
    type: "storeSimPlacedItemsAction",
    payload: payload,
  };
};
export const storeSimPlacedItemsReducer = (state = [], action) => {
  switch (action.type) {
    case "storeSimPlacedItemsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeSimXAction = (payload) => {
  return {
    type: "storeSimXAction",
    payload: payload,
  };
};
export const storeSimXReducer = (state = 0, action) => {
  switch (action.type) {
    case "storeSimXAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeSimYAction = (payload) => {
  return {
    type: "storeSimYAction",
    payload: payload,
  };
};
export const storeSimYReducer = (state = 0, action) => {
  switch (action.type) {
    case "storeSimYAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const storeSimZAction = (payload) => {
  return {
    type: "storeSimZAction",
    payload: payload,
  };
};
export const storeSimZReducer = (state = 0, action) => {
  switch (action.type) {
    case "storeSimZAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeSimErrorMsgAction = (payload) => {
  return {
    type: "storeSimErrorMsgAction",
    payload: payload,
  };
};
export const storeSimErrorMsgReducer = (state = "", action) => {
  switch (action.type) {
    case "storeSimErrorMsgAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const storeSimSearchResAction = (payload) => {
  return {
    type: "storeSimSearchResAction",
    payload: payload,
  };
};
export const storeSimSearchResReducer = (state = [], action) => {
  switch (action.type) {
    case "storeSimSearchResAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

// TOGGLE

// Logged In
export const toggleUserLoggedInAction = (payload) => {
  return {
    type: "toggleUserLoggedInAction",
    payload: payload,
  };
};
export const toggleUserLoggedInReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleUserLoggedInAction":
      state = !state;
      return state;
    default:
      return state;
  }
};
// Avail Space
export const toggleAvailSpaceFormAction = (payload) => {
  return {
    type: "toggleAvailSpaceFormAction",
    payload: payload,
  };
};
export const toggleAvailSpaceFormReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleAvailSpaceFormAction":
      state = !state;
      return state;
    default:
      return state;
  }
};
export const toggleShareModalAction = (payload) => {
  return {
    type: "toggleShareModalAction",
    payload: payload,
  };
};
export const toggleShareModalReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleShareModalAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const toggleModalSuccessAction = (payload) => {
  return {
    type: "toggleModalSuccessAction",
    payload: payload,
  };
};
export const toggleModalSuccessReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleModalSuccessAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};
export const toggleDeleteModalAction = (payload) => {
  return {
    type: "toggleDeleteModalAction",
    payload: payload,
  };
};
export const toggleDeleteModalReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleDeleteModalAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const toggleObjectPresetsAction = (payload) => {
  return {
    type: "toggleObjectPresetsAction",
    payload: payload,
  };
};
export const toggleObjectPresetsReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleObjectPresetsAction":
      state = !state;
      return state;
    default:
      return state;
  }
};

export const toggleAddObjAction = (payload) => {
  return {
    type: "toggleAddObjAction",
    payload: payload,
  };
};
export const toggleAddObjectReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleAddObjAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const toggleCurrentMovingLoadMenuAction = (payload) => {
  return {
    type: "toggleCurrentMovingLoadMenuAction",
    payload: payload,
  };
};
export const toggleCurrentMovingLoadMenuReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleCurrentMovingLoadMenuAction":
      state = !state;
      return state;
    default:
      return state;
  }
};

export const toggleCurrentSpaceMenuAction = (payload) => {
  return {
    type: "toggleCurrentSpaceMenuAction",
    payload: payload,
  };
};
export const toggleCurrentSpaceMenuReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleCurrentSpaceMenuAction":
      state = !state;
      return state;
    default:
      return state;
  }
};

export const toggleNewItemFormAction = (payload) => {
  return {
    type: "toggleNewItemFormAction",
    payload: payload,
  };
};
export const toggleNewItemFormReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleNewItemFormAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const toggleSpaceInsideAction = (payload) => {
  return {
    type: "toggleSpaceInsideAction",
    payload: payload,
  };
};
export const toggleSpaceInsideReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleSpaceInsideAction":
      state = !state;
      return state;
    default:
      return state;
  }
};

export const toggleItemMenuAction = (payload) => {
  return {
    type: "toggleItemMenuAction",
    payload: payload,
  };
};
export const toggleItemMenuReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleItemMenuAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const toggleSearchResultsAction = (payload) => {
  return {
    type: "toggleSearchResultsAction",
    payload: payload,
  };
};
export const toggleSearchResultsReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleSearchResultsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const toggleSaveSimMessageAction = (payload) => {
  return {
    type: "toggleSaveSimMessageAction",
    payload: payload,
  };
};
export const toggleSaveSimMessageReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleSaveSimMessageAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const toggleHideMenuAction = (payload) => {
  return {
    type: "toggleHideMenuAction",
    payload: payload,
  };
};
export const toggleHideMenuReducer = (state = true, action) => {
  switch (action.type) {
    case "toggleHideMenuAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const toggleResimulateAction = (payload) => {
  return {
    type: "toggleResimulateAction",
    payload: payload,
  };
};
export const toggleResimulateReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleResimulateAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const toggleItemListAction = (payload) => {
  return {
    type: "toggleItemListAction",
    payload: payload,
  };
};
export const toggleItemListReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleItemListAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const toggleSimInitialAction = (payload) => {
  return {
    type: "toggleSimInitialAction",
    payload: payload,
  };
};
export const toggleSimInitialReducer = (state = true, action) => {
  switch (action.type) {
    case "toggleSimInitialAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const toggleControlsMenuAction = (payload) => {
  return {
    type: "toggleControlsMenuAction",
    payload: payload,
  };
};
export const toggleControlsMenuReducer = (state = true, action) => {
  switch (action.type) {
    case "toggleControlsMenuAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const toggleSimSearchAction = (payload) => {
  return {
    type: "toggleSimSearchAction",
    payload: payload,
  };
};
export const toggleSimSearchReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleSimSearchAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const toggleSimTipsAction = (payload) => {
  return {
    type: "toggleSimTipsAction",
    payload: payload,
  };
};
export const toggleSimTipsReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleSimTipsAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export const toggleSimListAction = (payload) => {
  return {
    type: "toggleSimListAction",
    payload: payload,
  };
};
export const toggleSimListReducer = (state = false, action) => {
  switch (action.type) {
    case "toggleSimListAction":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const allReducers = combineReducers({
  storeUserCredsReducer,
  toggleUserLoggedInReducer,
  storeObjectGroupsReducer,
  storeObjectGroupReducer,
  storeStackedObjectsReducer,
  storeStorageDimensionsReducer,
  storeLastObjectReducer,
  storeStorageUnitsReducer,
  storeSimulationsReducer,
  storeCreateObjectGroupsReducer,
  toggleAvailSpaceFormReducer,
  storeAllStacksReducer,
  storeObjectNumReducer,
  storeSingleObjectGroupReducer,
  storeAllObjectGroupsReducer,
  storeAllSimulationsReducer,
  storeAllStoragesReducer,
  storeSingleStorageReducer,
  storeAllStorageGroupsReducer,
  storeAllStorageUnitsReducer,
  storeAllBestMatchesReducer,
  storeMissingObjectsReducer,
  toggleShareModalReducer,
  storeShareObjectGroupReducer,
  toggleModalSuccessReducer,
  storeShareModalMessageReducer,
  toggleDeleteModalReducer,
  storeDoMatchReducer,
  storeRegMessageReducer,
  storeInvalidCredsMessageReducer,
  storeMomStorageDimReducer,
  storeMomStorageObjectsReducer,
  storeWheelObjectsReducer,
  toggleObjectPresetsReducer,
  storeTotalCubicFeetReducer,
  storeTotalMomsWeightReducer,
  storeTempWeightReducer,
  toggleAddObjectReducer,
  storeCurrentMovingLoadReducer,
  storeCurrentSpaceReducer,
  toggleCurrentMovingLoadMenuReducer,
  toggleCurrentSpaceMenuReducer,
  toggleNewItemFormReducer,
  toggleSpaceInsideReducer,
  storeCheckNameMessageReducer,
  storeCheckLoadNameMessageReducer,
  toggleItemMenuReducer,
  storeSingleMenuItemReducer,
  storeMostRecentLoadReducer,
  storeSearchResultsReducer,
  toggleSearchResultsReducer,
  storeSimItemReducer,
  storeFullLoadReducer,
  toggleSaveSimMessageReducer,
  toggleHideMenuReducer,
  toggleResimulateReducer,
  toggleItemListReducer,
  storeOriginalItemGroupReducer,
  storeOriginalItemsReducer,
  storeSimSpaceDimReducer,
  storeSimMomDimReducer,
  storeSimLoadObjReducer,
  storeSimItemsArrReducer,
  storeSimCurrentNumReducer,
  storeSimCurrentObjReducer,
  storeSimOriginalLoadReducer,
  storeSimPlacedItemsReducer,
  toggleSimInitialReducer,
  storeSimXReducer,
  storeSimYReducer,
  storeSimZReducer,
  toggleControlsMenuReducer,
  storeSimErrorMsgReducer,
  storeSimSearchResReducer,
  toggleSimSearchReducer,
  toggleSimTipsReducer,
  toggleSimListReducer,
});
