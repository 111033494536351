import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  storeShareModalMessageAction,
  toggleModalSuccessAction,
  toggleShareModalAction,
  toggleUserLoggedInAction,
} from "../../redux";
import { FaTimes } from "react-icons/fa";
import { users_Collection, firebaseLooper } from "../../firebase";

export default function ShareModal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAuthID = useSelector((state) => state.storeUserCredsReducer);

  const shareObjGroupState = useSelector(
    (state) => state.storeShareObjectGroupReducer
  );
  const toggleModalSuccess = useSelector(
    (state) => state.toggleModalSuccessReducer
  );
  const message = useSelector((state) => state.storeShareModalMessageReducer);

  const shareObjGroup = () => {
    const shareKey = document.querySelector("#tbShareKey").value;
    // Find the user with that share key
    users_Collection
      .where("ShareKey", "==", shareKey)
      .get()
      .then((snapshot) => {
        const userData = firebaseLooper(snapshot);
        if (userData.length > 0) {
          //   check if it already exists
          userData.forEach((user) => {
            users_Collection
              .doc(user.id)
              .collection("Loads")
              .get()
              .then((snapshot) => {
                const objGrpData = firebaseLooper(snapshot);
                let valid = true;
                objGrpData.forEach((objg) => {
                  if (
                    objg.Name ===
                    `${userAuthID.Email} -> ${shareObjGroupState.Name}`
                  ) {
                    valid = false;
                  }
                });

                if (valid) {
                  dispatch(storeShareModalMessageAction("Success!"));

                  users_Collection
                    .doc(user.id)
                    .collection("Loads")
                    .doc(shareObjGroupState.id)
                    .set({
                      Name: `${userAuthID.Email} -> ${shareObjGroupState.Name}`,
                    })
                    .catch((err) => console.log(err));
                  shareObjGroupState.Items.forEach((obj, i) => {
                    if (obj.Space) {
                      users_Collection
                        .doc(user.id)
                        .collection("Loads")
                        .doc(shareObjGroupState.id)
                        .collection("Items")
                        .doc(obj.id)
                        .set({
                          Name: obj.Name,
                          Width: obj.Width,
                          Length: obj.Length,
                          Height: obj.Height,
                          Weight: obj.Weight,
                          Material: obj.Material,
                          Space: obj.Space,
                          SpaceWidth: obj.SpaceWidth,
                          SpaceLength: obj.SpaceLength,
                          SpaceHeight: obj.SpaceHeight,
                          Fragile: obj.Fragile,
                          Abstract: obj.Abstract,
                          Grounded: obj.Grounded,
                          MomsAttic: obj.MomsAttic,
                        })
                        .catch((err) => console.log(err));
                    } else if (obj.Inside) {
                      users_Collection
                        .doc(user.id)
                        .collection("Loads")
                        .doc(shareObjGroupState.id)
                        .collection("Items")
                        .doc(obj.id)
                        .set({
                          Name: obj.Name,
                          Width: obj.Width,
                          Length: obj.Length,
                          Height: obj.Height,
                          Weight: obj.Weight,
                          Material: obj.Material,
                          Inside: obj.Inside,
                          Fragile: obj.Fragile,
                          Abstract: obj.Abstract,
                          Grounded: obj.Grounded,
                          MomsAttic: obj.MomsAttic,
                        })
                        .catch((err) => console.log(err));
                    } else {
                      users_Collection
                        .doc(user.id)
                        .collection("Loads")
                        .doc(shareObjGroupState.id)
                        .collection("Items")
                        .doc(obj.id)
                        .set({
                          Name: obj.Name,
                          Width: obj.Width,
                          Length: obj.Length,
                          Height: obj.Height,
                          Weight: obj.Weight,
                          Material: obj.Material,
                          Fragile: obj.Fragile,
                          Abstract: obj.Abstract,
                          Grounded: obj.Grounded,
                          MomsAttic: obj.MomsAttic,
                        })
                        .catch((err) => console.log(err));
                    }
                  });
                } else {
                  dispatch(storeShareModalMessageAction("Load Already Sent."));
                  dispatch(toggleModalSuccessAction(true));
                }
              })
              .catch((err) => console.log(err));
          });
        }
      })
      .catch((err) => console.log(err));

    dispatch(toggleModalSuccessAction(true));
  };

  useEffect(() => {
    if (!userAuthID) {
      dispatch(toggleUserLoggedInAction(false));
      history.push("/login");
      return;
    }
  }, []);
  return (
    <div className="modal-wrap">
      <div className="modal-block">
        <button
          className="btn-modal"
          onClick={() => {
            dispatch(toggleModalSuccessAction(false));
            dispatch(toggleShareModalAction(false));
          }}
        >
          <FaTimes />
        </button>
        {!toggleModalSuccess ? (
          <div>
            <h4 className="modal-head">Please enter receiver share key:</h4>
            <input
              className="modal-tb"
              type="text"
              id="tbShareKey"
              placeholder="8-char Share Key"
            />
            <button onClick={shareObjGroup} className="btn-modal-share">
              Share
            </button>
          </div>
        ) : (
          <h4>{message}</h4>
        )}
      </div>
    </div>
  );
}
