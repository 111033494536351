import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { users_Collection } from "../../firebase";
import {
  storeObjectGroupsAction,
  toggleUserLoggedInAction,
  storeObjectGroupAction,
  storeAllStacksAction,
  storeStackedObjectsAction,
  storeLastObjectAction,
  storeStorageDimensionsAction,
  storeCreateObjectGroupsAction,
  storeObjectNumAction,
  storeSingleObjectGroupAction,
  storeAllStoragesAction,
  storeSingleStorageAction,
  toggleShareModalAction,
  storeShareObjectGroupAction,
  storeMomStorageDimAction,
  storeMissingObjectsAction,
  storeMomStorageObjectsAction,
} from "../../redux";
import { firebaseLooper } from "../../firebase";
import "./MainAcct.css";
import { FaPlus } from "react-icons/fa";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PrintingLabelsPage from "./PrintingLabelsPage";

import Footer from "../Footer";
import ShareModal from "./ShareModal";

export default function SimulationsMain() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAuthID = useSelector((state) => state.storeUserCredsReducer);

  const uhaulPresets = [
    {
      id: 1,
      Alias: "4 x 8 Cargo Trailer",
      Width: "8 FT",
      Length: "4 FT",
      Height: "4 FT",
      Preset: true,
    },
    {
      id: 2,
      Alias: "5 x 8 Cargo Trailer",
      Width: "8 FT",
      Length: "4.5 FT",
      Height: "5 FT",
      Preset: true,
    },
    {
      id: 3,
      Alias: "6 x 12 Cargo Trailer",
      Width: "11.5 FT",
      Length: "6 FT",
      Height: "5.4 FT",
      Preset: true,
    },
    {
      id: 4,
      Alias: "5 x 7 Utility Trailer",
      Width: "7.5 FT",
      Length: "3.2 FT",
      Height: "4.5 FT",
      Preset: true,
    },
    {
      id: 5,
      Alias: "5 x 8 Utility Trailer",
      Width: "9 FT",
      Length: "4.2 FT",
      Height: "4.5 FT",
      Preset: true,
    },
    {
      id: 6,
      Alias: "5 x 9 Utility Trailer Ramp",
      Width: "9 FT",
      Length: "4.5",
      Height: "5 FT",
      Preset: true,
    },
    {
      id: 7,
      Alias: "6 x 12 Utility Trailer",
      Width: "12 FT",
      Length: "6 FT",
      Height: "4.5 FT",
      Preset: true,
    },
    {
      id: 8,
      Alias: "6 x 12 Utility Trailer Ramp",
      Width: "12 FT",
      Length: "6 FT",
      Height: "5 FT",
      Preset: true,
    },
    {
      id: 9,
      Alias: "U-Box Moving / Storing Container",
      Width: "7.8 FT",
      Length: "4.5 FT",
      Height: "6.8 FT",
      Preset: true,
    },
    {
      id: 10,
      Alias: "8 FT Pickup Truck",
      Width: "7.5 FT",
      Length: "5 FT",
      Height: "3.5 FT",
      WheelW: "2.5 FT",
      WheelL: "1 FT",
      WheelH: "1 FT",
      DistanceWheel: "2 FT",
      Preset: true,
    },
    {
      id: 11,
      Alias: "9 FT Cargo Van",
      Width: "9.5 FT",
      Length: "5.5 FT",
      Height: "4.5 FT",
      WheelW: "2.5 FT",
      WheelL: "1 FT",
      WheelH: "1 FT",
      DistanceWheel: "4 FT",
      Preset: true,
    },
    {
      id: 12,
      Alias: "10 FT Moving Truck",
      Width: "9.8 FT",
      Length: "6.2 FT",
      Height: "6 FT",
      WheelW: "2 FT",
      WheelL: "1 FT",
      WheelH: "1 FT",
      DistanceWheel: "4 FT",
      Preset: true,
    },
    {
      id: 13,
      Alias: "15 FT Moving Truck",
      Width: "12.2 FT",
      Length: "7.5 FT",
      Height: "7 FT",
      WheelW: "2 FT",
      WheelL: "1 FT",
      WheelH: "1 FT",
      DistanceWheel: "5.5 FT",
      MomW: "2.2 FT",
      MomL: "7.5 FT",
      MomH: "2.5 FT",
      Preset: true,
    },
    {
      id: 14,
      Alias: "17 FT Moving Truck",
      Width: "14.2 FT",
      Length: "7.5 FT",
      Height: "7 FT",
      WheelW: "2 FT",
      WheelL: "1 FT",
      WheelH: "1 FT",
      DistanceWheel: "6.5 FT",
      MomW: "2.2 FT",
      MomL: "7.5 FT",
      MomH: "2.5 FT",
      Preset: true,
    },
    {
      id: 15,
      Alias: "20 FT Moving Truck",
      Width: "19.2 FT",
      Length: "7.5 FT",
      Height: "7 FT",
      WheelW: "2 FT",
      WheelL: "1 FT",
      WheelH: "1 FT",
      DistanceWheel: "8.2 FT",
      MomW: "2.2 FT",
      MomL: "7.5 FT",
      MomH: "2.5 FT",
      Preset: true,
    },
    {
      id: 16,
      Alias: "26 FT Moving Truck",
      Width: "23.2 FT",
      Length: "8 FT",
      Height: "8.2 FT",
      WheelW: "2 FT",
      WheelL: "1 FT",
      WheelH: "1 FT",
      DistanceWheel: "12 FT",
      MomW: "2.5 FT",
      MomL: "8 FT",
      MomH: "3 FT",
      Preset: true,
    },
  ];

  const objGroups = useSelector((state) => state.storeObjectGroupsReducer);
  const storages = useSelector((state) => state.storeAllStoragesReducer);

  const toggleShareModal = useSelector(
    (state) => state.toggleShareModalReducer
  );

  //   GET
  const getAllObjectGroups = () => {
    users_Collection
      .doc(userAuthID.id)
      .collection("ObjectGroups")
      .get()
      .then((snapshot) => {
        const objGroups = firebaseLooper(snapshot);
        const objSize = snapshot.size;

        objGroups.forEach((objs, i) => {
          users_Collection
            .doc(userAuthID.id)
            .collection("ObjectGroups")
            .doc(objs.id)
            .collection("Objects")
            .get()
            .then((snapshot) => {
              const objData = firebaseLooper(snapshot);
              objs = {
                ...objs,
                Objects: objData,
              };
              objGroups[i] = objs;
              if (i === objSize - 1) {
                dispatch(storeObjectGroupsAction(objGroups));
              }
            })
            .catch((err) => console.log(err));
        });
      })
      .catch((err) => console.log(err));
  };
  const getStorageGroups = () => {
    users_Collection
      .doc(userAuthID.id)
      .collection("Spaces")
      .orderBy("Alias", "asc")
      .get()
      .then((snapshot) => {
        const storData = firebaseLooper(snapshot);
        dispatch(storeAllStoragesAction(storData));
      })
      .catch((err) => console.log(err));
  };

  //   HANDLE
  const handleObjectGroups = () => {
    return objGroups.map((objs, i) => {
      return (
        <div className="obj-grp-block" key={i}>
          <h3 className="obj-grp-name">{objs.Name}</h3>

          <div className="obj-btn-group">
            <button
              className="obj-btn obj-grp-white"
              onClick={navObjGroupView}
              id={objs.id}
            >
              Simulate
            </button>
            {/* {objs.Objects ? (
              <PDFDownloadLink
                className="pdf-link"
                document={
                  <PrintingLabelsPage
                    groupName={objs.Name}
                    objects={objs.Objects}
                  />
                }
                fileName={`${objs.Name}.pdf`}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    "Loading document..."
                  ) : (
                    <p className="inside">
                      Print
                      <br /> Labels
                    </p>
                  )
                }
              </PDFDownloadLink>
            ) : null} */}
            <button
              id={objs.id}
              onClick={navObjGroupEdit}
              className="obj-btn obj-grp-gray"
            >
              Edit
            </button>
            <button
              id={objs.id}
              onClick={onObjShare}
              className="obj-btn obj-grp-orange"
            >
              Share
            </button>
            <button
              id={objs.id}
              onClick={removeObjGroup}
              className="obj-btn obj-grp-red"
            >
              Remove
            </button>
          </div>
        </div>
      );
    });
  };

  const handleStorages = () => {
    if (storages) {
      return storages.map((stor, i) => {
        return (
          <div className={`obj-grp-block`} key={i}>
            <h3 className="obj-grp-name">{stor.Alias}</h3>

            <div className="obj-btn-group">
              <button
                id={stor.id}
                onClick={navStorageEdit}
                className="obj-btn obj-grp-gray"
              >
                Edit
              </button>
              <button
                id={`${stor.id}`}
                onClick={removeStorage}
                className="obj-btn obj-grp-red"
              >
                Remove
              </button>
            </div>
          </div>
        );
      });
    }
  };

  // CLICK
  const onObjShare = (event) => {
    const objID = event.currentTarget.getAttribute("id");
    // Dispatch the object group
    objGroups.forEach((og) => {
      if (og.id === objID) {
        users_Collection
          .doc(userAuthID.id)
          .collection("ObjectGroups")
          .doc(og.id)
          .collection("Objects")
          .get()
          .then((snapshot) => {
            const objData = firebaseLooper(snapshot);
            og = {
              Name: og.Name,
              id: og.id,
              Objects: objData,
            };

            dispatch(storeShareObjectGroupAction(og));
            dispatch(toggleShareModalAction(true));
          })
          .catch((err) => console.log(err));
      }
    });
  };

  // REMOVE
  const removeObjGroup = (event) => {
    const objGrpID = event.currentTarget.getAttribute("id");

    // Remove objects collection first
    users_Collection
      .doc(userAuthID.id)
      .collection("ObjectGroups")
      .doc(objGrpID)
      .collection("Objects")
      .get()
      .then((snapshot) => {
        const objsData = firebaseLooper(snapshot);
        objsData.forEach((obj) => {
          users_Collection
            .doc(userAuthID.id)
            .collection("ObjectGroups")
            .doc(objGrpID)
            .collection("Objects")
            .doc(obj.id)
            .delete()
            .catch((err) => console.log(err));
        });

        users_Collection
          .doc(userAuthID.id)
          .collection("ObjectGroups")
          .doc(objGrpID)
          .delete()
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));

    // Dispatch
    let allObjGrps = [...objGroups];
    let filtered = allObjGrps.filter((o) => o.id !== objGrpID);

    dispatch(storeObjectGroupsAction(filtered));
  };
  const removeStorage = (event) => {
    const storageID = event.currentTarget.getAttribute("id");
    users_Collection
      .doc(userAuthID.id)
      .collection("Spaces")
      .doc(storageID)
      .delete()
      .catch((err) => console.log(err));

    let tempStorUnits = [...storages];
    let filtered = tempStorUnits.filter((uni) => uni.id !== storageID);

    dispatch(storeAllStoragesAction(filtered));
  };

  //   NAV
  const navObjGroupView = (event) => {
    const objsID = event.target.getAttribute("id");

    objGroups.forEach((obj) => {
      if (obj.id === objsID) {
        users_Collection
          .doc(userAuthID.id)
          .collection("ObjectGroups")
          .doc(objsID)
          .collection("Objects")
          .orderBy("Alias", "asc")
          .get()
          .then((snapshot) => {
            const objects = firebaseLooper(snapshot);
            const newObj = {
              ...obj,
              Objects: objects,
            };
            dispatch(storeObjectGroupAction(newObj));
            history.push("/simulation-prep");
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const navObjGroupEdit = (event) => {
    const objGrpID = event.currentTarget.getAttribute("id");

    objGroups.forEach((o) => {
      if (o.id === objGrpID) {
        dispatch(storeSingleObjectGroupAction(o));

        users_Collection
          .doc(userAuthID.id)
          .collection("ObjectGroups")
          .doc(o.id)
          .collection("Objects")
          .orderBy("Alias", "asc")
          .get()
          .then((snapshot) => {
            const objData = firebaseLooper(snapshot);
            dispatch(storeCreateObjectGroupsAction(objData));

            history.push("/object-group-edit");
          })
          .catch((err) => console.log(err));
      }
    });
  };
  const navStorageEdit = (event) => {
    const storID = event.currentTarget.getAttribute("id");

    storages.forEach((stor) => {
      if (stor.id === storID) {
        dispatch(storeSingleStorageAction(stor));
        history.push("/storage-edit");
      }
    });
  };

  useEffect(() => {
    if (!userAuthID.id) {
      dispatch(toggleUserLoggedInAction(false));
      history.push("/login");
      return;
    }

    getAllObjectGroups();
    getStorageGroups();
    dispatch(storeCreateObjectGroupsAction([]));

    dispatch(storeAllStacksAction([]));
    dispatch(storeLastObjectAction({}));
    dispatch(storeObjectNumAction(0));
    dispatch(storeStackedObjectsAction([]));
    dispatch(storeStorageDimensionsAction({}));
    dispatch(storeMissingObjectsAction(false));
    dispatch(storeMomStorageDimAction({}));
    dispatch(storeMomStorageObjectsAction([]));
  }, [storages]);

  return (
    <div>
      {toggleShareModal ? <ShareModal /> : null}

      <div className="sim-main-head">
        <h1>
          Wizard<span className="text-red">.</span>
        </h1>
      </div>

      {/* NOTICE
      <div className="notice-print">
        <h3>New Printing Label Feature!</h3>
        <p>
          You can now print labels for each object group you create with just a
          click of a button! PDF is formatted to be printed on{" "}
          <b style={{ fontSize: "16px" }}>Avery 5160</b> sheets.
        </p>
      </div> */}

      <br />
      <div className="sim-main-wrap">
        <button
          onClick={() => history.push("/object-group-create")}
          className="obj-main-btn"
        >
          New Move
        </button>
        <h2 className="sim-main-sub">
          Your Stuff<span className="text-red">.</span>
        </h2>

        {handleObjectGroups()}
      </div>
      <br />
      <div className="sim-main-wrap">
        <button
          onClick={() => history.push("/storage-create")}
          className="obj-main-btn"
        >
          New Space
        </button>
        <h2 className="sim-main-sub">
          Spaces<span className="text-red">.</span>
        </h2>
        <div className="stor-list-main">{handleStorages()}</div>
      </div>
      <br />
    </div>
  );
}
