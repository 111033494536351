import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import firebase, { users_Collection } from "../../firebase";
import {
  storeSimCurrentObjAction,
  storeSimItemsArrAction,
  toggleControlsMenuAction,
  toggleSimInitialAction,
  toggleUserLoggedInAction,
  storeSimErrorMsgAction,
  storeSimMomDimAction,
  storeSimCurrentNumAction,
  storeSimSpaceDimAction,
  storeSimLoadObjAction,
  storeSimSearchResAction,
  toggleSimSearchAction,
  toggleSimTipsAction,
  toggleSimTipsReducer,
  toggleSimListAction,
} from "../../redux";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosArrowForward,
  IoIosArrowBack,
} from "react-icons/io";
import { FaTools, FaBoxOpen, FaLightbulb, FaKeyboard } from "react-icons/fa";
import { AiOutlineRotateLeft } from "react-icons/ai";
import { MdRotate90DegreesCcw } from "react-icons/md";
import { IoArrowRedo } from "react-icons/io5";
import { IoMdPricetags } from "react-icons/io";
import { BsBoxArrowInDown } from "react-icons/bs";
import { RiDragMove2Line } from "react-icons/ri";
import { TiThList } from "react-icons/ti";

import { HiSave } from "react-icons/hi";

import floorImg from "./Textures/floor.jpg";
import wallImg from "./Textures/wall.jpg";
import metalImg from "./Textures/metal.jpeg";
import { pickBy } from "lodash";
// Constants
const multiplier = 0.02;
let posX;
let posY;
let posZ;
let canvas;
let camera;

let camRotX;
let camRotY;
let camRotZ;

let camPosX;
let camPosY;
let camPosZ;

const tips = [
  {
    Tags: "Fragile",
    Tip: "Fragile items are best kept towards the front of the truck for better safety.",
  },
  {
    Tags: "Fragile, Mom's Attic",
    Tip: "Items that are fragile are recommended to be placed in the Mom's Attic for safe keeping.",
  },
  {
    Tags: "Mom's Attic",
    Tip: "Mom's Attic can only hold up to 300 lbs / 136 kg.",
  },
  {
    Tags: "Mattress",
    Tip: "Mattresses are recommended to be placed first, along either wall (long-ways).",
  },
  {
    Tags: "Dressers",
    Tip: "Dressers should be placed in the back of the truck for better stability.",
  },
  {
    Tags: "Heavy Boxes, Light Boxes",
    Tip: "Be careful to not place any heavy boxes on top of lighter boxes. Items inside may become damaged.",
  },
];

export default function Simulation() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAuthID = useSelector((state) => state.storeUserCredsReducer);

  // States
  const spaceDim = useSelector((state) => state.storeSimSpaceDimReducer);
  const momDim = useSelector((state) => state.storeSimMomDimReducer);
  const load = useSelector((state) => state.storeSimLoadObjReducer);
  const items = useSelector((state) => state.storeSimItemsArrReducer);
  const currentItem = useSelector((state) => state.storeSimCurrentObjReducer);
  const errorMsg = useSelector((state) => state.storeSimErrorMsgReducer);

  const menuState = useSelector((state) => state.toggleControlsMenuReducer);
  const searchState = useSelector((state) => state.toggleSimSearchReducer);
  const tipsState = useSelector((state) => state.toggleSimTipsReducer);
  const listState = useSelector((state) => state.toggleSimListReducer);

  const searchResults = useSelector((state) => state.storeSimSearchResReducer);

  const initial = useSelector((state) => state.toggleSimInitialReducer);

  let orbit = true;

  let renderer;
  const scene = new THREE.Scene();
  const scene2 = new THREE.Scene();
  let controls;
  let dragControls;

  // SCENE
  const initializeScene = () => {
    let browserBottom = window.top.innerHeight;
    if (window.innerWidth >= 768) {
      document
        .querySelector("#canvas-sim")
        .setAttribute("style", `height: ${browserBottom - 60}px`);
    } else {
      document
        .querySelector("#canvas-sim")
        .setAttribute("style", `height: ${browserBottom - 110}px`);
    }
    canvas = document.querySelector("#canvas-sim");
    camera = new THREE.PerspectiveCamera(
      75,
      canvas.clientWidth / canvas.clientHeight,
      0.1,
      100
    );
    camera.name = "camera";

    if (initial) {
      camPosX = spaceDim.Width * multiplier + 1.4;
      camPosY = spaceDim.Height * multiplier;
      camPosZ = spaceDim.Length * multiplier + 0.4;

      if (currentItem.Moving) {
        posX =
          spaceDim.Width * multiplier - currentItem.Width * 0.5 * multiplier;
        posY = currentItem.Height * multiplier * 0.5 * multiplier;
        posZ = spaceDim.Length - currentItem.Length * 0.5 * multiplier;
      }

      // We want to remove any items that are placed which are spilling out of the truck and remove their positions. Not permanently

      let maxW = spaceDim.Width * multiplier;
      let maxL = spaceDim.Length * multiplier;
      let tempitems = [...items];
      tempitems.forEach((item, i) => {
        let P2X = item.PosX + item.Width * 0.5 * multiplier;
        let P2Z = item.PosZ + item.Length * 0.5 * multiplier;

        if (P2X > maxW || P2Z > maxL) {
          let changed = { ...item };
          delete item.PosX;
          delete item.PosY;
          delete item.PosZ;
          delete item.Placed;

          tempitems.splice(i, 1, changed);
        }
      });
      let organized = organizeItems(tempitems);
      dispatch(storeSimItemsArrAction(organized));
      dispatch(storeSimCurrentObjAction(organized[0]));
    }

    renderer = new THREE.WebGLRenderer({ canvas: canvas });
    camera.position.x = camPosX;
    camera.position.y = camPosY;
    camera.position.z = camPosZ;

    if (camRotX !== undefined) {
      camera.rotation._x = camRotX;
      camera.rotation._y = camRotY;
      camera.rotation._z = camRotZ;
    }

    controls = new OrbitControls(camera, canvas);

    controls.rotateSpeed = 0.5;
    controls.zoomSpeed = 0.6;
  };

  const handleScene = () => {
    const canvas = document.querySelector("#canvas-sim");
    const renderer = new THREE.WebGLRenderer({ canvas: canvas });
    const loader = new THREE.TextureLoader();

    const floorW = spaceDim.Width * multiplier;
    const floorL = spaceDim.Length * multiplier;
    const floorH = 0.05;

    let floorTexture = new THREE.TextureLoader().load(floorImg);
    floorTexture.wrapS = THREE.RepeatWrapping;
    floorTexture.wrapT = THREE.RepeatWrapping;
    floorTexture.repeat.set(1, 1);
    // Moms

    if (momDim.Mom) {
      const momFloorW = momDim.MomW * multiplier;
      const momFloorL = momDim.MomL * multiplier;
      const momFloorH = 0.05;

      const momFloorGeo = new THREE.BoxGeometry(
        momFloorW,
        momFloorH,
        momFloorL
      );
      const momFloorMat = new THREE.MeshStandardMaterial({ map: floorTexture });
      const momFloor = new THREE.Mesh(momFloorGeo, momFloorMat);
      momFloor.receiveShadow = true;
      momFloor.position.x = momDim.PosX * multiplier - 0.05;
      momFloor.position.y =
        (momDim.PosY - momDim.MomH * 0.5) * multiplier - 0.02;
      momFloor.position.z = momDim.PosZ * multiplier;
      scene.add(momFloor);

      // Wall 1

      const momWall1W = momDim.MomW * multiplier;
      const momWall1L = 0.05;
      const momWall1H = momDim.MomH * multiplier + 0.02;

      let wallTexture1 = new THREE.TextureLoader().load(wallImg);
      wallTexture1.wrapS = THREE.RepeatWrapping;
      wallTexture1.wrapT = THREE.RepeatWrapping;
      wallTexture1.repeat.set(1, 1);

      const momWall1Geo = new THREE.BoxGeometry(
        momWall1W,
        momWall1H,
        momWall1L
      );
      const momWallMat = new THREE.MeshStandardMaterial({ map: wallTexture1 });
      const momWall1 = new THREE.Mesh(momWall1Geo, momWallMat);

      momWall1.position.x = momDim.PosX * multiplier - 0.05;
      momWall1.position.y = (momDim.PosY + momWall1H * 0.5) * multiplier - 0.01;
      momWall1.position.z =
        (momDim.PosZ - momDim.MomL * 0.5) * multiplier - 0.025;

      scene.add(momWall1);

      //

      // Wall 2

      const momWall2W = 0.05;
      const momWall2L = momDim.MomL * multiplier;
      const momWall2H = momDim.MomH * multiplier;

      const momWall2Geo = new THREE.BoxGeometry(
        momWall2W,
        momWall2H,
        momWall2L
      );

      const momWall2 = new THREE.Mesh(momWall2Geo, momWallMat);

      momWall2.position.x =
        (momDim.PosX - momDim.MomW * 0.5) * multiplier - 0.02;
      momWall2.position.y = momDim.PosY * multiplier;
      momWall2.position.z = momDim.PosZ * multiplier;

      scene.add(momWall2);

      //
    }
    // ////////////////////////////////////

    // CURRENT //////////////////////////////////// ////////////////////////////////////
    items.forEach((item) => {
      if (item.Name === currentItem.Name) {
        if (item.Moving) {
          let itemGeo = new THREE.BoxGeometry(
            item.Width * multiplier,
            item.Height * multiplier,
            item.Length * multiplier
          );
          let itemMat = new THREE.MeshStandardMaterial({
            color: "#B4C4E4",
            transparent: true,
            opacity: 0.7,
          });
          let itemBox = new THREE.Mesh(itemGeo, itemMat);
          itemBox.name = item.id;

          itemBox.position.x = posX;
          itemBox.position.y = posY;
          itemBox.position.z = posZ;

          scene.add(itemBox);

          // Set Drag Controls
          dragControls = new DragControls(
            [itemBox],
            camera,
            renderer.domElement
          );

          let P1X = itemBox.position.x - item.Width * 0.5 * multiplier;
          let P2X = itemBox.position.x + item.Width * 0.5 * multiplier;
          let P1Z = itemBox.position.z - item.Length * 0.5 * multiplier;
          let P2Z = itemBox.position.z + item.Length * 0.5 * multiplier;
          let P1Y = itemBox.position.y - item.Height * 0.5 * multiplier;

          if (!momDim.Mom && P1X <= 0) {
            itemBox.position.x = item.Width * 0.5 * multiplier;
            P1X = itemBox.position.x - item.Width * 0.5 * multiplier;
            P2X = itemBox.position.x + item.Width * 0.5 * multiplier;
          }
          if (momDim.Mom && P1X <= 0) {
            itemBox.position.y =
              spaceDim.Height * multiplier -
              momDim.MomH * multiplier +
              item.Height * 0.5 * multiplier;
          }
          if (momDim.Mom && P1X <= 0 - momDim.MomW * multiplier) {
            itemBox.position.x =
              0 - momDim.MomW * multiplier + item.Width * 0.5 * multiplier;
            P1X = itemBox.position.x - item.Width * 0.5 * multiplier;
            P2X = itemBox.position.x + item.Width * 0.5 * multiplier;
          }
          if (P1Z <= 0) {
            itemBox.position.z = item.Length * 0.5 * multiplier;
            P1Z = itemBox.position.z - item.Length * 0.5 * multiplier;
            P2Z = itemBox.position.z + item.Length * 0.5 * multiplier;
          }
          if (P2X >= spaceDim.Width * multiplier) {
            itemBox.position.x =
              spaceDim.Width * multiplier - item.Width * 0.5 * multiplier;
            P1X = itemBox.position.x - item.Width * 0.5 * multiplier;
            P2X = itemBox.position.x + item.Width * 0.5 * multiplier;
          }
          if (P2Z >= spaceDim.Length * multiplier) {
            itemBox.position.z =
              spaceDim.Length * multiplier - item.Length * 0.5 * multiplier;
            P1Z = itemBox.position.z - item.Length * 0.5 * multiplier;
            P2Z = itemBox.position.z + item.Length * 0.5 * multiplier;
          }
          if (P2Z > spaceDim.Length * multiplier) {
            itemBox.position.z =
              spaceDim.Length * multiplier - item.Length * 0.5 * multiplier;
            P1Z = itemBox.position.z - item.Length * 0.5 * multiplier;
            P2Z = itemBox.position.z + item.Length * 0.5 * multiplier;
          }

          // CHeck for placed items
          let tempItems = [...items];
          tempItems.sort((key1, key2) =>
            key1.PosY + key1.Height * 0.5 * multiplier >
            key2.PosY + key2.Height * 0.5 * multiplier
              ? 1
              : -1
          );
          tempItems.forEach((iii) => {
            if (iii.Placed) {
              let iP1X = iii.PosX - iii.Width * 0.5 * multiplier;
              let iP2X = iii.PosX + iii.Width * 0.5 * multiplier;
              let iP1Z = iii.PosZ - iii.Length * 0.5 * multiplier;
              let iP2Z = iii.PosZ + iii.Length * 0.5 * multiplier;
              let iP2Y = iii.PosY + iii.Height * 0.5 * multiplier;

              if (
                P1X < iP2X &&
                P2X > iP1X &&
                P1Z < iP2Z &&
                P2Z > iP1Z &&
                P1Y < iP2Y
              ) {
                itemBox.position.y = iP2Y + item.Height * 0.5 * multiplier;
              }
            }
          });

          if (P1Y < 0) {
            P1Y = 0;
            itemBox.position.y = item.Height * 0.5 * multiplier;
          }

          posX = itemBox.position.x;
          posY = itemBox.position.y;
          posZ = itemBox.position.z;
          let P2Y = posY + item.Height * 0.5 * multiplier;
          let spaceMax = spaceDim.Height * multiplier;

          if (P2Y > spaceMax) {
            currentItem.TooTall = true;
            dispatch(storeSimErrorMsgAction("Item is too tall"));
            itemBox.material.color.r = 255;
            itemBox.material.color.g = 0;
            itemBox.material.color.b = 0;
          } else {
            delete currentItem.TooTall;
            dispatch(storeSimErrorMsgAction(""));
          }

          // DRAG START
          dragControls.addEventListener("dragstart", function (ev) {
            controls.enableRotate = false;
            let P1X =
              ev.object.position.x - ev.object.geometry.parameters.width * 0.5;
            let P2X =
              ev.object.position.x + ev.object.geometry.parameters.width * 0.5;
            let P1Z =
              ev.object.position.z - ev.object.geometry.parameters.depth * 0.5;
            let P2Z =
              ev.object.position.z + ev.object.geometry.parameters.depth * 0.5;
            let P1Y =
              ev.object.position.y - ev.object.geometry.parameters.height * 0.5;

            if (!momDim.Mom && P1X <= 0) {
              ev.object.position.x = ev.object.geometry.parameters.width * 0.5;
              P1X =
                ev.object.position.x -
                ev.object.geometry.parameters.width * 0.5;
              P2X =
                ev.object.position.x +
                ev.object.geometry.parameters.width * 0.5;
            }
            if (momDim.Mom && P1X <= 0) {
              ev.object.position.y =
                spaceDim.Height * multiplier -
                momDim.MomH * multiplier +
                ev.object.geometry.parameters.height * 0.5;
            }
            if (momDim.Mom && P1X <= 0 - momDim.MomW * multiplier) {
              ev.object.position.x =
                0 -
                momDim.MomW * multiplier +
                ev.object.geometry.parameters.width * 0.5;
              P1X =
                ev.object.position.x -
                ev.object.geometry.parameters.width * 0.5;
              P2X =
                ev.object.position.x +
                ev.object.geometry.parameters.width * 0.5;
            }
            if (P1Z <= 0) {
              ev.object.position.z = ev.object.geometry.parameters.depth * 0.5;
              P1Z =
                ev.object.position.z -
                ev.object.geometry.parameters.depth * 0.5;
              P2Z =
                ev.object.position.z +
                ev.object.geometry.parameters.depth * 0.5;
            }
            if (P2X >= spaceDim.Width * multiplier) {
              ev.object.position.x =
                spaceDim.Width * multiplier -
                ev.object.geometry.parameters.width * 0.5;
              P1X =
                ev.object.position.x -
                ev.object.geometry.parameters.width * 0.5;
              P2X =
                ev.object.position.x +
                ev.object.geometry.parameters.width * 0.5;
            }
            if (P2Z >= spaceDim.Length * multiplier) {
              ev.object.position.z =
                spaceDim.Length * multiplier -
                ev.object.geometry.parameters.depth * 0.5;
              P1Z =
                ev.object.position.z -
                ev.object.geometry.parameters.depth * 0.5;
              P2Z =
                ev.object.position.z +
                ev.object.geometry.parameters.depth * 0.5;
            }

            // CHeck for placed items
            let tempItems = [...items];
            tempItems.sort((key1, key2) =>
              key1.PosY + key1.Height * 0.5 * multiplier >
              key2.PosY + key2.Height * 0.5 * multiplier
                ? 1
                : -1
            );
            tempItems.forEach((iii) => {
              if (iii.Placed) {
                let iP1X = iii.PosX - iii.Width * 0.5 * multiplier;
                let iP2X = iii.PosX + iii.Width * 0.5 * multiplier;
                let iP1Z = iii.PosZ - iii.Length * 0.5 * multiplier;
                let iP2Z = iii.PosZ + iii.Length * 0.5 * multiplier;
                let iP2Y = iii.PosY + iii.Height * 0.5 * multiplier;

                if (
                  P1X < iP2X &&
                  P2X > iP1X &&
                  P1Z < iP2Z &&
                  P2Z > iP1Z &&
                  P1Y < iP2Y
                ) {
                  ev.object.position.y =
                    iP2Y + ev.object.geometry.parameters.height * 0.5;
                }
              }
            });
          });
          // DRAG
          dragControls.addEventListener("drag", function (ev) {
            // Stick to ground
            ev.object.position.y = item.Height * 0.5 * multiplier;

            // Set bounds

            let P1X =
              ev.object.position.x - ev.object.geometry.parameters.width * 0.5;
            let P2X =
              ev.object.position.x + ev.object.geometry.parameters.width * 0.5;
            let P1Z =
              ev.object.position.z - ev.object.geometry.parameters.depth * 0.5;
            let P2Z =
              ev.object.position.z + ev.object.geometry.parameters.depth * 0.5;
            let P1Y =
              ev.object.position.y - ev.object.geometry.parameters.height * 0.5;

            if (!momDim.Mom && P1X <= 0) {
              ev.object.position.x = ev.object.geometry.parameters.width * 0.5;
              P1X =
                ev.object.position.x -
                ev.object.geometry.parameters.width * 0.5;
              P2X =
                ev.object.position.x +
                ev.object.geometry.parameters.width * 0.5;
            }
            if (momDim.Mom && P1X <= 0) {
              ev.object.position.y =
                spaceDim.Height * multiplier -
                momDim.MomH * multiplier +
                ev.object.geometry.parameters.height * 0.5;
            }
            if (momDim.Mom && P1X <= 0 - momDim.MomW * multiplier) {
              ev.object.position.x =
                0 -
                momDim.MomW * multiplier +
                ev.object.geometry.parameters.width * 0.5;
              P1X =
                ev.object.position.x -
                ev.object.geometry.parameters.width * 0.5;
              P2X =
                ev.object.position.x +
                ev.object.geometry.parameters.width * 0.5;
            }
            if (P1Z <= 0) {
              ev.object.position.z = ev.object.geometry.parameters.depth * 0.5;
              P1Z =
                ev.object.position.z -
                ev.object.geometry.parameters.depth * 0.5;
              P2Z =
                ev.object.position.z +
                ev.object.geometry.parameters.depth * 0.5;
            }
            if (P2X >= spaceDim.Width * multiplier) {
              ev.object.position.x =
                spaceDim.Width * multiplier -
                ev.object.geometry.parameters.width * 0.5;
              P1X =
                ev.object.position.x -
                ev.object.geometry.parameters.width * 0.5;
              P2X =
                ev.object.position.x +
                ev.object.geometry.parameters.width * 0.5;
            }
            if (P2Z >= spaceDim.Length * multiplier) {
              ev.object.position.z =
                spaceDim.Length * multiplier -
                ev.object.geometry.parameters.depth * 0.5;
              P1Z =
                ev.object.position.z -
                ev.object.geometry.parameters.depth * 0.5;
              P2Z =
                ev.object.position.z +
                ev.object.geometry.parameters.depth * 0.5;
            }

            // CHeck for placed items
            let tempItems = [...items];
            tempItems.sort((key1, key2) =>
              key1.PosY + key1.Height * 0.5 * multiplier >
              key2.PosY + key2.Height * 0.5 * multiplier
                ? 1
                : -1
            );
            tempItems.forEach((iii) => {
              if (iii.Placed) {
                let iP1X = iii.PosX - iii.Width * 0.5 * multiplier;
                let iP2X = iii.PosX + iii.Width * 0.5 * multiplier;
                let iP1Z = iii.PosZ - iii.Length * 0.5 * multiplier;
                let iP2Z = iii.PosZ + iii.Length * 0.5 * multiplier;
                let iP2Y = iii.PosY + iii.Height * 0.5 * multiplier;

                if (
                  P1X < iP2X &&
                  P2X > iP1X &&
                  P1Z < iP2Z &&
                  P2Z > iP1Z &&
                  P1Y < iP2Y
                ) {
                  ev.object.position.y =
                    iP2Y + ev.object.geometry.parameters.height * 0.5;
                }
              }
            });

            let P2Y =
              ev.object.position.y + ev.object.geometry.parameters.height * 0.5;
            if (P2Y > spaceDim.Height * multiplier) {
              ev.object.material.color.r = 255;
              ev.object.material.color.g = 0;
              ev.object.material.color.b = 0;
            } else {
              ev.object.material.color.b = 0.89;
              ev.object.material.color.g = 0.76;
              ev.object.material.color.r = 0.71;
            }
          });
          // DRAG END
          dragControls.addEventListener("dragend", function (ev) {
            let P1X =
              ev.object.position.x - ev.object.geometry.parameters.width * 0.5;
            let P2X =
              ev.object.position.x + ev.object.geometry.parameters.width * 0.5;
            let P1Z =
              ev.object.position.z - ev.object.geometry.parameters.depth * 0.5;
            let P2Z =
              ev.object.position.z + ev.object.geometry.parameters.depth * 0.5;
            let P1Y =
              ev.object.position.y - ev.object.geometry.parameters.height * 0.5;

            let tempItems = [...items];
            let gap = 0.08;
            let smallGap = 0.03;
            let smallThing = 0.005;
            tempItems.sort((key1, key2) => (key1.PosZ < key2.PosZ ? 1 : -1));
            tempItems.forEach((iii) => {
              if (iii.Placed) {
                let iP1Z = iii.PosZ - iii.Length * 0.5 * multiplier;
                let iP1X = iii.PosX - iii.Width * 0.5 * multiplier;
                let iP2X = iii.PosX + iii.Width * 0.5 * multiplier;

                let diffTop = iP1Z - P2Z;
                let diffBottomWall = spaceDim.Length * multiplier - P2Z;

                if (
                  diffTop >= 0 &&
                  diffTop <= gap &&
                  P2X >= iP1X &&
                  P1X <= iP2X
                ) {
                  ev.object.position.z =
                    iP1Z -
                    ev.object.geometry.parameters.depth * 0.5 -
                    smallThing;
                }

                // Wall Stick
                if (diffBottomWall >= 0 && diffBottomWall <= smallGap) {
                  ev.object.position.z =
                    spaceDim.Length * multiplier -
                    ev.object.geometry.parameters.depth * 0.5 +
                    smallThing;
                }
              }
            });
            tempItems.sort((key1, key2) => (key1.PosZ > key2.PosZ ? 1 : -1));
            tempItems.forEach((iii) => {
              if (iii.Placed) {
                let iP2Z = iii.PosZ + iii.Length * 0.5 * multiplier;
                let iP1X = iii.PosX - iii.Width * 0.5 * multiplier;
                let iP2X = iii.PosX + iii.Width * 0.5 * multiplier;

                let diffBottom = P1Z - iP2Z;
                let diffTopWall = P1Z - 0;

                if (
                  diffBottom >= 0 &&
                  diffBottom <= gap &&
                  P2X >= iP1X &&
                  P1X <= iP2X
                ) {
                  ev.object.position.z =
                    iP2Z +
                    ev.object.geometry.parameters.depth * 0.5 +
                    smallThing;
                }

                // Wall Stick

                if (diffTopWall >= 0 && diffTopWall <= smallGap) {
                  ev.object.position.z =
                    0 + ev.object.geometry.parameters.depth * 0.5 + smallThing;
                }
              }
            });

            tempItems.sort((key1, key2) => (key1.PosX > key2.PosX ? 1 : -1));
            tempItems.forEach((iii) => {
              if (iii.Placed) {
                let iP2X = iii.PosX + iii.Width * 0.5 * multiplier;
                let iP1Z = iii.PosZ - iii.Length * 0.5 * multiplier;
                let iP2Z = iii.PosZ + iii.Length * 0.5 * multiplier;

                let diffRight = P1X - iP2X;
                let diffLeftWall = P1X - 0;

                if (
                  diffRight >= 0 &&
                  diffRight <= gap &&
                  P2Z >= iP1Z &&
                  P1Z <= iP2Z
                ) {
                  ev.object.position.x =
                    iP2X +
                    ev.object.geometry.parameters.width * 0.5 +
                    smallThing;
                }

                // Wall Stick

                if (diffLeftWall >= 0 && diffLeftWall <= smallGap) {
                  ev.object.position.x =
                    0 + ev.object.geometry.parameters.width * 0.5 + smallThing;
                }
              }
            });
            tempItems.sort((key1, key2) => (key1.PosX < key2.PosX ? 1 : -1));
            tempItems.forEach((iii) => {
              if (iii.Placed) {
                let iP1X = iii.PosX - iii.Width * 0.5 * multiplier;
                let iP1Z = iii.PosZ - iii.Length * 0.5 * multiplier;
                let iP2Z = iii.PosZ + iii.Length * 0.5 * multiplier;

                let diffLeft = iP1X - P2X;
                let diffRightWall = spaceDim.Width * multiplier - P2X;

                if (
                  diffLeft >= 0 &&
                  diffLeft <= gap &&
                  P2Z >= iP1Z &&
                  P1Z <= iP2Z
                ) {
                  ev.object.position.x =
                    iP1X -
                    ev.object.geometry.parameters.width * 0.5 -
                    smallThing;
                }

                // Wall Stick

                if (diffRightWall >= 0 && diffRightWall <= smallGap) {
                  ev.object.position.x =
                    spaceDim.Width * multiplier -
                    ev.object.geometry.parameters.width * 0.5 +
                    smallThing;
                }
              }
            });

            controls.enableRotate = true;

            posX = ev.object.position.x;
            posY = ev.object.position.y;
            posZ = ev.object.position.z;

            let P2Y =
              ev.object.position.y + ev.object.geometry.parameters.height * 0.5;
            if (P2Y > spaceDim.Height * multiplier) {
              currentItem.TooTall = true;
              dispatch(storeSimErrorMsgAction("Item is too tall"));
            } else {
              delete currentItem.TooTall;
              dispatch(storeSimErrorMsgAction(""));
            }
          });

          controls.addEventListener("end", function (ev) {
            camRotX = camera.rotation._x;
            camRotY = camera.rotation._y;
            camRotZ = camera.rotation._z;

            camPosX = camera.position.x;
            camPosY = camera.position.y;
            camPosZ = camera.position.z;
          });

          //
        } else if (item.Placed) {
          let itemGeo = new THREE.BoxGeometry(
            item.Width * multiplier,
            item.Height * multiplier,
            item.Length * multiplier
          );

          let itemMat = new THREE.MeshStandardMaterial({ color: "#487DFF" });
          let itemBox = new THREE.Mesh(itemGeo, itemMat);
          itemBox.name = item.id;
          itemBox.position.x = item.PosX;
          itemBox.position.y = item.PosY;
          itemBox.position.z = item.PosZ;

          scene.add(itemBox);
        }
      } else {
        let itemGeo = new THREE.BoxGeometry(
          item.Width * multiplier,
          item.Height * multiplier,
          item.Length * multiplier
        );
        if (item.Placed) {
          let itemMat = new THREE.MeshLambertMaterial({
            color: "#CFA270",
            transparent: true,
            opacity: 0.5,
          });
          let itemBox = new THREE.Mesh(itemGeo, itemMat);
          // wireframe
          var geo = new THREE.EdgesGeometry(itemBox.geometry); // or WireframeGeometry
          var mat = new THREE.LineBasicMaterial({
            color: "black",
            transparent: true,
            opacity: 0.5,
          });
          var wireframe = new THREE.LineSegments(geo, mat);
          itemBox.add(wireframe);
          itemBox.name = item.id;

          itemBox.position.x = item.PosX;
          itemBox.position.y = item.PosY;
          itemBox.position.z = item.PosZ;

          scene.add(itemBox);
        }
      }
    });

    ////////////////////////////////////
    // FLOOR

    const floorGeo = new THREE.BoxGeometry(floorW, floorH, floorL);
    const floorMat = new THREE.MeshLambertMaterial({ map: floorTexture });
    const floor = new THREE.Mesh(floorGeo, floorMat);
    floor.name = "Floor";

    floor.position.x = 0.5 * floorW;
    floor.position.y = -0.025;
    floor.position.z = 0.5 * floorL;

    scene.add(floor);
    //

    // WALL 1
    const wall1W = spaceDim.Width * multiplier + 0.05;
    const wall1L = 0.05;
    let wall1H = spaceDim.Height * multiplier + 0.03;

    let wallTexture1 = new THREE.TextureLoader().load(wallImg);
    wallTexture1.wrapS = THREE.RepeatWrapping;
    wallTexture1.wrapT = THREE.RepeatWrapping;
    wallTexture1.repeat.set(1, 1);
    const wall1Geo = new THREE.BoxGeometry(wall1W, wall1H, wall1L);
    const wallMat = new THREE.MeshLambertMaterial({ map: wallTexture1 });
    const wall1 = new THREE.Mesh(wall1Geo, wallMat);
    wall1.name = "Wall1";

    wall1.position.x = 0.5 * wall1W - 0.05;
    wall1.position.y = 0.5 * wall1H - 0.045;
    wall1.position.z = 0.5 * wall1L - 0.05;

    scene.add(wall1);
    //

    // WALL
    const wall2W = 0.05;
    const wall2L = spaceDim.Length * multiplier;
    let wall2H = spaceDim.Height * multiplier + 0.03;

    if (momDim.Mom) {
      wall2H = (spaceDim.Height - momDim.MomH) * multiplier + 0.04;
    }

    const wall2Geo = new THREE.BoxGeometry(wall2W, wall2H, wall2L);

    const wall2 = new THREE.Mesh(wall2Geo, wallMat);
    wall2.name = "Wall2";

    wall2.position.x = 0.5 * wall2W - 0.05;
    wall2.position.y = 0.5 * wall2H - 0.045;
    wall2.position.z = 0.5 * wall2L;
    scene.add(wall2);

    const directLight1 = new THREE.DirectionalLight("white", 1, 50);
    directLight1.position.set(floorW - 1, floorH + 2, floorL + 2);

    scene.add(directLight1);

    const directLight2 = new THREE.DirectionalLight("white", 1, 50);
    directLight2.position.set(floorW + 2, floorH + 3, floorL - 3);

    scene.add(directLight2);
    scene.add(camera);

    renderer.render(scene, camera);
    const tick = () => {
      requestAnimationFrame(tick);

      renderer.setSize(canvas.clientWidth, canvas.clientHeight);
      renderer.autoClear = true;
      renderer.render(scene, camera);
      renderer.autoClear = false;
      renderer.render(scene2, camera);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    };

    tick();
    // END METHOD
  };

  //
  const onGoUp = () => {
    let tempItems = [...items];
    let curr = { ...currentItem };

    let idx = 0;
    tempItems.forEach((it, i) => {
      if (it.Name === curr.Name) {
        idx = i;
      }
    });
    let oldCurr = tempItems[idx];
    delete oldCurr.Moving;

    if (idx === 0) {
      idx = items.length - 1;
    } else {
      idx = idx - 1;
    }

    let newCurr = tempItems[idx];
    if (!newCurr.Placed) {
      newCurr = {
        ...newCurr,
        Moving: true,
        Initial: true,
        PosX: spaceDim.Width * multiplier + newCurr.Width * multiplier,
        PosY: newCurr.Height * 0.5 * multiplier,
        PosZ: spaceDim.Length * 0.5 * multiplier,
      };

      posX = spaceDim.Width * multiplier - newCurr.Width * 0.5 * multiplier;
      posY = newCurr.Height * 0.5 * multiplier;
      posZ = spaceDim.Length * multiplier - newCurr.Length * 0.5 * multiplier;
    }

    tempItems.forEach((it, i) => {
      if (it.Name === oldCurr.Name) {
        tempItems.splice(i, 1, oldCurr);
      }
      if (it.Name === newCurr.Name) {
        tempItems.splice(i, 1, newCurr);
      }
    });

    dispatch(storeSimCurrentObjAction(newCurr));
    dispatch(storeSimItemsArrAction([...tempItems]));
  };
  const onGoDown = () => {
    let tempItems = [...items];
    let curr = { ...currentItem };

    let idx = 0;
    tempItems.forEach((it, i) => {
      if (it.Name === curr.Name) {
        idx = i;
      }
    });
    let oldCurr = tempItems[idx];
    delete oldCurr.Moving;

    if (idx === items.length - 1) {
      idx = 0;
    } else {
      idx = idx + 1;
    }

    let newCurr = tempItems[idx];
    if (!newCurr.Placed) {
      newCurr = {
        ...newCurr,
        Moving: true,
        Initial: true,
        PosX: spaceDim.Width * multiplier + newCurr.Width * multiplier,
        PosY: newCurr.Height * 0.5 * multiplier,
        PosZ: spaceDim.Length * 0.5 * multiplier,
      };
      posX = spaceDim.Width * multiplier - newCurr.Width * 0.5 * multiplier;
      posY = newCurr.Height * 0.5 * multiplier;
      posZ = spaceDim.Length * multiplier - newCurr.Length * 0.5 * multiplier;
    }

    tempItems.forEach((it, i) => {
      if (it.Name === oldCurr.Name) {
        tempItems.splice(i, 1, oldCurr);
      }
      if (it.Name === newCurr.Name) {
        tempItems.splice(i, 1, newCurr);
      }
    });
    dispatch(storeSimCurrentObjAction(newCurr));
    dispatch(storeSimItemsArrAction([...tempItems]));
  };
  const onDoubleClick = (event) => {
    var raycaster = new THREE.Raycaster();
    var mouse = new THREE.Vector2();

    mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
    mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, camera);

    var intersects = raycaster.intersectObjects(scene.children);

    if (intersects.length > 0) {
      let intersectsID = intersects[0].object.name;

      items.forEach((item) => {
        if (item.id === intersectsID && item.Placed) {
          let temp = { ...item, Moving: true };
          posX = temp.PosX;
          posY = temp.PosY;
          posZ = temp.PosZ;
          // Take out any items that are on top.
          let P1X = temp.PosX - temp.Width * 0.5 * multiplier;
          let P2X = temp.PosX + temp.Width * 0.5 * multiplier;
          let P1Z = temp.PosZ - temp.Length * 0.5 * multiplier;
          let P2Z = temp.PosZ + temp.Length * 0.5 * multiplier;
          let tempItems = [...items];

          tempItems.forEach((it, i) => {
            if (it.Placed && it.Name !== temp.Name) {
              let iP1X = it.PosX - it.Width * 0.5 * multiplier;
              let iP2X = it.PosX + it.Width * 0.5 * multiplier;
              let iP1Z = it.PosZ - it.Length * 0.5 * multiplier;
              let iP2Z = it.PosZ + it.Length * 0.5 * multiplier;

              if (
                iP1X < P2X &&
                iP2X > P1X &&
                iP1Z < P2Z &&
                iP2Z > P1Z &&
                it.PosY > temp.PosY
              ) {
                let temptemp = { ...it };
                delete temptemp.PosX;
                delete temptemp.PosY;
                delete temptemp.PosZ;
                delete temptemp.Placed;

                tempItems.splice(i, 1, temptemp);
              }
            }
          });

          delete temp.Placed;
          delete temp.PosX;
          delete temp.PosY;
          delete temp.PosZ;

          tempItems.forEach((it, i) => {
            if (it.Name === temp.Name) {
              tempItems.splice(i, 1, temp);
            }
          });

          let organized = organizeItems(tempItems);

          dispatch(storeSimItemsArrAction(organized));
          dispatch(storeSimCurrentObjAction(temp));
        }
      });
    }
  };
  const onKeyClick = (event) => {
    let key = event.keyCode;

    // P
    if (key === 80 && currentItem.Moving) {
      onSimControl(null, "place");
    }
    // // M
    else if (key === 77 && currentItem.Placed) {
      onSimControl(null, "move");
    }
    // // T
    else if (key === 84 && currentItem.Placed) {
      onSimControl(null, "take");
    }
    // // S
    else if (key === 83 && currentItem.Moving) {
      onSimControl(null, "stand");
    }
    // // R
    else if (key === 82 && currentItem.Moving) {
      onSimControl(null, "rotate");
    }
  };
  const onSimControl = (event, cmd) => {
    let command = "";
    if (event !== null) {
      command = event.currentTarget.getAttribute("id");
    }

    if (command === "place" || cmd === "place") {
      let children = scene.children;
      let pos = {};
      children.forEach((child) => {
        if (child.name === currentItem.id) {
          pos = { ...child.position };
        }
      });
      if (!currentItem.TooTall) {
        let temp = {
          ...currentItem,
          Placed: true,
          PosX: pos.x ? pos.x : posX,
          PosY: pos.y ? pos.y : posY,
          PosZ: pos.z ? pos.z : posZ,
        };

        delete temp.Moving;

        let tempItems = [...items];
        tempItems.forEach((item, i) => {
          if (item.Name === currentItem.Name) {
            tempItems.splice(i, 1, temp);
          }
        });
        let organized = organizeItems(tempItems);

        dispatch(storeSimItemsArrAction(organized));
        dispatch(storeSimCurrentObjAction(temp));
      }
    } else if (command === "take" || cmd === "take") {
      let temp = { ...currentItem };
      let tempItems = [...items];

      // Take out any items that are on top.
      let P1X = temp.PosX - temp.Width * 0.5 * multiplier;
      let P2X = temp.PosX + temp.Width * 0.5 * multiplier;
      let P1Z = temp.PosZ - temp.Length * 0.5 * multiplier;
      let P2Z = temp.PosZ + temp.Length * 0.5 * multiplier;

      tempItems.forEach((it, i) => {
        if (it.Placed && it.Name !== temp.Name) {
          let iP1X = it.PosX - it.Width * 0.5 * multiplier;
          let iP2X = it.PosX + it.Width * 0.5 * multiplier;
          let iP1Z = it.PosZ - it.Length * 0.5 * multiplier;
          let iP2Z = it.PosZ + it.Length * 0.5 * multiplier;
          let iP1Y = it.PosY - it.Height * 0.5 * multiplier;

          if (
            iP1X < P2X &&
            iP2X > P1X &&
            iP1Z < P2Z &&
            iP2Z > P1Z &&
            iP1Y > temp.PosY
          ) {
            let temptemp = { ...it };
            delete temptemp.PosX;
            delete temptemp.PosY;
            delete temptemp.PosZ;
            delete temptemp.Placed;

            tempItems.splice(i, 1, temptemp);
          }
        }
      });

      delete temp.PosX;
      delete temp.PosY;
      delete temp.PosZ;
      delete temp.Placed;

      tempItems.forEach((it, i) => {
        if (it.Name === temp.Name) {
          tempItems.splice(i, 1, temp);
        }
      });

      let organized = organizeItems(tempItems);

      if (!organized[0].Placed) {
        organized[0] = { ...organized[0], Moving: true };
      }

      posX =
        spaceDim.Width * multiplier - organized[0].Width * 0.5 * multiplier;
      posY = organized[0].Height * 0.5 * multiplier;
      posZ =
        spaceDim.Length * multiplier - organized[0].Length * 0.5 * multiplier;

      dispatch(storeSimItemsArrAction(organized));
      dispatch(storeSimCurrentObjAction(organized[0]));
    } else if (command === "move" || cmd === "move") {
      let temp = { ...currentItem, Moving: true };

      // Take out any items that are on top.
      let P1X = temp.PosX - temp.Width * 0.5 * multiplier;
      let P2X = temp.PosX + temp.Width * 0.5 * multiplier;
      let P1Z = temp.PosZ - temp.Length * 0.5 * multiplier;
      let P2Z = temp.PosZ + temp.Length * 0.5 * multiplier;
      let tempItems = [...items];

      tempItems.forEach((it, i) => {
        if (it.Placed && it.Name !== temp.Name) {
          let iP1X = it.PosX - it.Width * 0.5 * multiplier;
          let iP2X = it.PosX + it.Width * 0.5 * multiplier;
          let iP1Z = it.PosZ - it.Length * 0.5 * multiplier;
          let iP2Z = it.PosZ + it.Length * 0.5 * multiplier;
          let iP1Y = it.PosY - it.Height * 0.5 * multiplier;

          if (
            iP1X < P2X &&
            iP2X > P1X &&
            iP1Z < P2Z &&
            iP2Z > P1Z &&
            iP1Y > temp.PosY
          ) {
            let temptemp = { ...it };
            delete temptemp.PosX;
            delete temptemp.PosY;
            delete temptemp.PosZ;
            delete temptemp.Placed;

            tempItems.splice(i, 1, temptemp);
          }
        }
      });

      delete temp.Placed;
      delete temp.PosX;
      delete temp.PosY;
      delete temp.PosZ;

      tempItems.forEach((it, i) => {
        if (it.Name === temp.Name) {
          tempItems.splice(i, 1, temp);
        }
      });

      let organized = organizeItems(tempItems);

      posX = currentItem.PosX;
      posY = currentItem.PosY;
      posZ = currentItem.PosZ;

      dispatch(storeSimItemsArrAction(organized));

      dispatch(storeSimCurrentObjAction(temp));
    } else if (command === "stand" || cmd === "stand") {
      let temp = { ...currentItem };
      let width = temp.Width;
      let length = temp.Length;
      let height = temp.Height;

      let P1Y = posY - height * 0.5 * multiplier;

      temp = { ...temp, Height: width, Width: height, Length: length };
      temp = { ...temp, PosY: P1Y + temp.Height * 0.5 * multiplier };

      posY = P1Y + temp.Height * 0.5 * multiplier;

      let P2Y = P1Y + temp.Height * multiplier;

      if (P2Y > spaceDim.Height * multiplier) {
        currentItem.TooTall = true;
        dispatch(storeSimErrorMsgAction("Item is too tall"));
      } else {
        delete currentItem.TooTall;
        dispatch(storeSimErrorMsgAction(""));
      }

      let tempItems = [...items];
      tempItems.forEach((it, i) => {
        if (it.Name === temp.Name) {
          tempItems.splice(i, 1, temp);
        }
      });

      dispatch(storeSimItemsArrAction(tempItems));

      dispatch(storeSimCurrentObjAction(temp));
    } else if (command === "rotate" || cmd === "rotate") {
      let temp = { ...currentItem };
      let width = temp.Width;
      let length = temp.Length;
      let height = temp.Height;

      temp = { ...temp, Height: height, Width: length, Length: width };

      let tempItems = [...items];
      tempItems.forEach((it, i) => {
        if (it.Name === temp.Name) {
          tempItems.splice(i, 1, temp);
        }
      });

      dispatch(storeSimItemsArrAction(tempItems));

      dispatch(storeSimCurrentObjAction(temp));
    }
  };

  // HANDLE
  const handleItemList = () => {
    return items.map((item, i) => {
      return (
        <button
          onClick={onItemClick}
          id={item.id}
          className="sim-itemlist-btn"
          key={i}
        >
          <p
            className={
              item.Name === currentItem.Name
                ? "text-blue"
                : item.Placed
                ? "text-gray"
                : null
            }
          >
            {item.Name}
          </p>
        </button>
      );
    });
  };
  const handleTips = () => {
    return tips.map((tip, i) => {
      return (
        <div className="tip-block" key={i}>
          <div className="tip-icon">
            <FaLightbulb />
          </div>
          <div>
            <p className="tip-tip">{tip.Tip}</p>
            <p className="tip-tag">
              <IoMdPricetags className="tag-icon" /> {tip.Tags}
            </p>
          </div>
        </div>
      );
    });
  };

  const handleSearchResults = () => {
    return searchResults.map((res, i) => {
      return (
        <button
          className="search-btn"
          onClick={onClickSearch}
          id={res.Name}
          key={i}
        >
          <div>
            <FaBoxOpen className="search-icon" />
          </div>
          <div className="search-grp">
            <h4>{res.Name}</h4>
            <p>{res.Contents}</p>
          </div>
        </button>
      );
    });
  };

  // METHODS
  const organizeItems = (itemsArr) => {
    let tempItems = [...itemsArr];

    let placed = [];
    let nonPlaced = [];

    tempItems.forEach((it) => {
      if (it.Placed) {
        placed.push(it);
      } else {
        nonPlaced.push(it);
      }
    });
    let ppp = [...placed];

    // organize the placed items
    // Get the p1y, and if they are on the ground, then save those apart
    let onGround = [];
    let tempPlaced = [...placed];
    tempPlaced.forEach((pl, p) => {
      let P1Y = pl.PosY - pl.Height * 0.5 * multiplier;
      if (P1Y === 0) {
        onGround.push(pl);
      }
    });
    onGround.forEach((gr) => {
      placed.forEach((pl, p) => {
        if (pl.Name === gr.Name) {
          placed.splice(p, 1);
        }
      });
    });

    let tempOnGround = [];
    let abort = false;
    for (
      let x = 0;
      x <= Math.round(spaceDim.Width * multiplier * 10) / 10 && !abort;
      x = x + 0.001
    ) {
      for (
        let z = 0;
        z <= Math.round(spaceDim.Length * multiplier * 10) / 10 && !abort;
        z = z + 0.001
      ) {
        onGround.forEach((gr, g) => {
          let gP1X = gr.PosX - gr.Width * 0.5 * multiplier;
          let gP2X = gr.PosX + gr.Width * 0.5 * multiplier;
          let gP1Z = gr.PosZ - gr.Length * 0.5 * multiplier;
          let gP2Z = gr.PosZ + gr.Length * 0.5 * multiplier;

          if (x >= gP1X && x <= gP2X && z >= gP1Z && z <= gP2Z) {
            tempOnGround.push(gr);
            onGround.splice(g, 1);
          }
        });
        if (onGround.length === 0) {
          abort = true;
        }
      }
    }
    let stacks = [];

    tempOnGround.forEach((gr, g) => {
      let tempArr = [];
      tempArr.push(gr);
      let gP1X = gr.PosX - gr.Width * 0.5 * multiplier;
      let gP2X = gr.PosX + gr.Width * 0.5 * multiplier;
      let gP1Z = gr.PosZ - gr.Length * 0.5 * multiplier;
      let gP2Z = gr.PosZ + gr.Length * 0.5 * multiplier;

      for (let p = 0; p < placed.length; p = p + 1) {
        let pl = placed[p];
        let pP1X = pl.PosX - pl.Width * 0.5 * multiplier;
        let pP2X = pl.PosX - pl.Width * 0.5 * multiplier;
        let pP1Z = pl.PosZ - pl.Length * 0.5 * multiplier;
        let pP2Z = pl.PosZ - pl.Length * 0.5 * multiplier;

        if (pP2X >= gP1X && pP1X <= gP2X && pP2Z >= gP1Z && pP1Z <= gP2Z) {
          tempArr.push(pl);
          placed.splice(p, 1);
        }
      }
      stacks.push(tempArr);
    });
    let nowPlaced = [];
    stacks.forEach((st) => {
      nowPlaced = nowPlaced.concat(st);
    });
    let loners = [];
    ppp.forEach((ite) => {
      let yes = false;
      nowPlaced.forEach((now) => {
        if (now.Name === ite.Name) {
          yes = true;
        }
      });
      if (!yes) {
        loners.push(ite);
      }
    });
    nowPlaced = nowPlaced.concat(loners);

    nonPlaced.sort((key1, key2) => (key1.Volume < key2.Volume ? 1 : -1));

    let combined = nonPlaced.concat(nowPlaced);
    return combined;
  };
  const searchItem = () => {
    let searchText = document.querySelector("#tbSearch").value.toLowerCase();

    let searchRes = [];
    if (searchText !== "") {
      items.forEach((item) => {
        let itemName = item.Name.toLowerCase();
        let itemContents = item.Contents.toLowerCase();

        if (
          itemName.includes(searchText) ||
          itemContents.includes(searchText)
        ) {
          searchRes.push(item);
        }
      });
    }
    dispatch(storeSimSearchResAction(searchRes));
    if (searchRes.length === 0) {
      dispatch(toggleSimSearchAction(false));
    } else {
      dispatch(toggleSimSearchAction(true));
    }
  };
  const onClickSearch = (event) => {
    let search = event.currentTarget.getAttribute("id");
    let obj = {};
    items.forEach((item) => {
      if (search === item.Name) {
        obj = { ...item };
      }
    });

    let newCurr = obj;
    if (!newCurr.Placed) {
      newCurr = {
        ...newCurr,
        Moving: true,
        PosX: spaceDim.Width * multiplier - newCurr.Width * 0.5 * multiplier,
        PosY: newCurr.Height * 0.5 * multiplier,
        PosZ: spaceDim.Length * multiplier - newCurr.Length * 0.5 * multiplier,
      };
      posX = spaceDim.Width * multiplier - newCurr.Width * 0.5 * multiplier;
      posY = newCurr.Height * 0.5 * multiplier;
      posZ = spaceDim.Length * multiplier - newCurr.Length * 0.5 * multiplier;
    }

    let tempItems = [...items];
    tempItems.forEach((it, i) => {
      if (it.Name === newCurr.Name) {
        tempItems.splice(i, 1, newCurr);
      }
    });

    dispatch(storeSimItemsArrAction([...tempItems]));

    document.querySelector("#tbSearch").value = "";
    dispatch(toggleSimSearchAction(false));
    dispatch(storeSimCurrentObjAction(newCurr));
  };
  const onItemClick = (event) => {
    let chosen = event.currentTarget.getAttribute("id");
    let obj = {};
    items.forEach((item) => {
      if (chosen === item.id) {
        obj = { ...item };
      }
    });

    let newCurr = obj;
    if (!newCurr.Placed) {
      newCurr = {
        ...newCurr,
        Moving: true,
        PosX: spaceDim.Width * multiplier - newCurr.Width * 0.5 * multiplier,
        PosY: newCurr.Height * 0.5 * multiplier,
        PosZ: spaceDim.Length * multiplier - newCurr.Length * 0.5 * multiplier,
      };
      posX = spaceDim.Width * multiplier - newCurr.Width * 0.5 * multiplier;
      posY = newCurr.Height * 0.5 * multiplier;
      posZ = spaceDim.Length * multiplier - newCurr.Length * 0.5 * multiplier;
    }

    let tempItems = [...items];
    tempItems.forEach((it, i) => {
      if (it.Name === newCurr.Name) {
        tempItems.splice(i, 1, newCurr);
      }
    });

    dispatch(storeSimItemsArrAction([...tempItems]));
    dispatch(storeSimCurrentObjAction(newCurr));
  };

  // SAVE
  const saveItems = () => {
    items.forEach((item) => {
      users_Collection
        .doc(userAuthID.id)
        .collection("Loads")
        .doc(load.id)
        .collection("Items")
        .doc(item.id)
        .update({
          Width: Math.round((item.Width / 12) * 10) / 10,
          Length: Math.round((item.Length / 12) * 10) / 10,
          Height: Math.round((item.Height / 12) * 10) / 10,
          PosX: item.PosX ? item.PosX : firebase.firestore.FieldValue.delete(),
          PosY: item.PosY ? item.PosY : firebase.firestore.FieldValue.delete(),
          PosZ: item.PosZ ? item.PosZ : firebase.firestore.FieldValue.delete(),
          Placed: item.Placed
            ? item.Placed
            : firebase.firestore.FieldValue.delete(),
        });
    });
    dispatch(storeSimErrorMsgAction("Saved"));
    setTimeout(() => {
      dispatch(storeSimErrorMsgAction(""));
    }, 3000);
  };
  const closeSim = () => {
    // items.forEach((item) => {
    //   users_Collection
    //     .doc(userAuthID.id)
    //     .collection("Loads")
    //     .doc(load.id)
    //     .collection("Items")
    //     .doc(item.id)
    //     .update({
    //       Width: Math.round((item.Width / 12) * 10) / 10,
    //       Length: Math.round((item.Length / 12) * 10) / 10,
    //       Height: Math.round((item.Height / 12) * 10) / 10,
    //       PosX: item.PosX ? item.PosX : firebase.firestore.FieldValue.delete(),
    //       PosY: item.PosY ? item.PosY : firebase.firestore.FieldValue.delete(),
    //       PosZ: item.PosZ ? item.PosZ : firebase.firestore.FieldValue.delete(),
    //       Placed: item.Placed
    //         ? item.Placed
    //         : firebase.firestore.FieldValue.delete(),
    //     });
    // });

    dispatch(storeSimCurrentObjAction({}));
    dispatch(storeSimErrorMsgAction(""));
    dispatch(storeSimMomDimAction({}));
    dispatch(storeSimItemsArrAction([]));
    dispatch(storeSimCurrentNumAction(0));
    dispatch(storeSimSpaceDimAction({}));
    dispatch(storeSimLoadObjAction({}));
    dispatch(toggleSimInitialAction(true));
    dispatch(toggleSimTipsAction(false));
    document
      .querySelector(".main-name")
      .setAttribute("style", "color: initial");
    history.push("/dashboard");
  };

  useEffect(() => {
    if (!userAuthID) {
      dispatch(toggleUserLoggedInAction(false));
      history.push("/login");
      return;
    }

    initializeScene();
    handleScene();
    dispatch(toggleSimInitialAction(false));

    canvas.ondblclick = onDoubleClick;
    document.onkeyup = onKeyClick;

    let canvasPos = document
      .querySelector("#canvas-sim")
      .getBoundingClientRect();
    canvasPos = canvasPos.bottom;
    let difference = window.innerHeight - canvasPos;
    document
      .querySelector(".sim-list")
      .setAttribute("style", `bottom: ${difference + 10}px`);

    if (window.innerWidth >= 768) {
      document
        .querySelector(".main-name")
        .setAttribute("style", "color: rgba(255,255,255,0.6)");
    }
    document
      .querySelector(".sim-bottom-btn-grp")
      .setAttribute("style", "bottom: ");

    let windowWidth = window.innerWidth;
    if (windowWidth < 1024) {
      document.querySelector(".legend-wrap").classList.add("hide");
    }
  }, [currentItem, items]);

  return (
    <div className="sim-content">
      <div className="search-wrap">
        <input
          onChange={searchItem}
          id="tbSearch"
          className="sim-tb-search"
          placeholder="Search by name or contents."
        />
      </div>
      {searchState ? (
        <div className="search-res-wrap">{handleSearchResults()}</div>
      ) : null}
      <canvas id="canvas-sim"></canvas>

      <div className="sim-list">
        {listState ? (
          <div className="sim-list-wrap">
            <div>
              <button
                className="sim-list-toggle"
                onClick={() => dispatch(toggleSimListAction(false))}
              >
                <IoIosArrowForward />
              </button>
            </div>
            <div className="sim-item-list">
              <div>
                <button onClick={onGoUp} className="sim-list-btn">
                  <IoIosArrowUp />
                </button>
              </div>

              <div className="sim-item-list-p">{handleItemList()}</div>
              <div>
                <button onClick={onGoDown} className="sim-list-btn">
                  <IoIosArrowDown />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <button
            className="sim-list-toggle"
            onClick={() => dispatch(toggleSimListAction(true))}
          >
            <TiThList />
          </button>
        )}
      </div>

      <div className="sim-main-controls">
        {menuState ? (
          <div className="sim-btns-block">
            <div>
              <button
                className="sim-toggle"
                onClick={() => dispatch(toggleControlsMenuAction(false))}
              >
                <IoIosArrowForward />
              </button>
            </div>
            <div className="sim-btns">
              {currentItem.Moving ? (
                <button
                  id="place"
                  onClick={onSimControl}
                  className="sim-btn-place"
                >
                  <BsBoxArrowInDown /> Place
                </button>
              ) : null}
              {currentItem.Placed ? (
                <button id="move" onClick={onSimControl}>
                  <RiDragMove2Line /> Move
                </button>
              ) : null}
              {currentItem.Placed ? (
                <button id="take" onClick={onSimControl}>
                  <IoArrowRedo /> Take Out
                </button>
              ) : null}
              {currentItem.Moving ? (
                <div className="sim-btns-split">
                  <button id="stand" onClick={onSimControl}>
                    <AiOutlineRotateLeft /> Stand
                  </button>
                  <button id="rotate" onClick={onSimControl}>
                    <MdRotate90DegreesCcw /> Rotate
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <button
            className="sim-toggle"
            onClick={() => dispatch(toggleControlsMenuAction(true))}
          >
            <FaTools />
          </button>
        )}
      </div>
      <div className="tips-wrap">
        {tipsState ? (
          <div className="tips-split">
            <div className="tips-block">
              <p className="tip-head">Pro Tips</p>
              {handleTips()}
            </div>
            <div>
              <button
                className="tips-btn"
                onClick={() => dispatch(toggleSimTipsAction(false))}
              >
                <IoIosArrowBack />
              </button>
            </div>
          </div>
        ) : (
          <button
            className="tips-btn"
            onClick={() => dispatch(toggleSimTipsAction(true))}
          >
            <FaLightbulb />
          </button>
        )}
      </div>
      <div className="legend-wrap">
        <div style={{ display: "flex", marginBlock: "10px" }}>
          <FaKeyboard className="legend-icon" />{" "}
          <p className="legend-head">Keyboard Options</p>
        </div>
        <div className="legend-block">
          <div className="legend-line">
            <h4>P</h4>
            <p>Place</p>
          </div>
          <div className="legend-line">
            <h4>M</h4>
            <p>Move</p>
          </div>
          <div className="legend-line">
            <h4>T</h4>
            <p>Take Out</p>
          </div>
          <div className="legend-line">
            <h4>S</h4>
            <p>Stand / Lay Down</p>
          </div>
          <div className="legend-line">
            <h4>R</h4>
            <p>Rotate</p>
          </div>
        </div>
      </div>
      <div className="error">
        <p className={errorMsg === "Saved" ? "text-blue" : null}>{errorMsg}</p>
      </div>
      {/* Buttons */}
      <div className="sim-bottom-btn-grp">
        <button onClick={saveItems} className="sim-save">
          Save
        </button>
        <button onClick={closeSim} className="sim-close">
          Close Simulation
        </button>
      </div>
    </div>
  );
}
