import React from "react";
import { users_Collection } from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  storeInvalidCredsMessageAction,
  storeUserCredsAction,
  toggleUserLoggedInAction,
} from "../redux";
import { firebaseLooper } from "../firebase";

import Footer from "./Footer";

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const message = useSelector((state) => state.storeInvalidCredsMessageReducer);

  const checkLoginCreds = () => {
    const username = document.querySelector("#tbUsername").value;
    const password = document.querySelector("#tbPassword").value;

    users_Collection
      .get()
      .then((snapshot) => {
        const userData = firebaseLooper(snapshot);
        userData.forEach((user) => {
          if (user.Username === username) {
            if (user.Password === password) {
              dispatch(storeUserCredsAction(user));
              dispatch(toggleUserLoggedInAction(true));
              dispatch(storeInvalidCredsMessageAction(""));

              if (username === "uhaul" && password === "123456!") {
                users_Collection
                  .doc("Userh6uw42ne3gj8jklz36tk")
                  .update({ Visited: true });
              } else if (username === "penske" && password === "123456!") {
                users_Collection
                  .doc("Usern5ce5jb898Y9JNUVnata")
                  .update({ Visited: true });
              }

              history.push("/dashboard");
            } else {
              dispatch(storeInvalidCredsMessageAction("Incorrect Password"));
            }
          } else {
            dispatch(storeInvalidCredsMessageAction("Invalid Username"));
          }
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="login-main">
      <div className="login-main-head-block">
        <h1 className="squish">Log In</h1>
        <p
          style={{
            fontSize: "14px",
            fontWeight: "500",
            letterSpacing: "1px",
            color: "red",
          }}
        >
          {message}
        </p>
      </div>
      <br />
      <div className="login-main-form">
        <div className="login-form-wrap">
          <label className="login-form-label">Username</label>
          <input
            className="login-form-tb"
            id="tbUsername"
            type="text"
            placeholder="ex. jdoe1234"
          />
          <br />
          <label className="login-form-label">Password</label>
          <input
            className="login-form-tb"
            id="tbPassword"
            type="password"
            placeholder="****"
          />
        </div>
        <br />
        <button className="login-form-btn" onClick={checkLoginCreds}>
          Let's Go!
        </button>
        <br />
        <button
          onClick={() => history.push("/register")}
          className="btn-create-acc"
        >
          Create New Account
        </button>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
